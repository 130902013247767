<template>
<div>
    <v-snackbar v-model="snackbar.display" :color="snackbar.color" :top="snackbar.top" :bottom="snackbar.bottom" :left="snackbar.left" :right="snackbar.right">
        {{ snackbar.message }}
    </v-snackbar>
    <div v-if="loading" style="position:absolute; top:0; right:0; bottom:0; left:0; padding-top: 20%; padding-left: 45%;background-color:rgba(255,255,255,0.31)">
        <div class="text-xs-center">
            <v-progress-circular color="success" class="text-xs-center my-5" :size="100" :width="10" indeterminate></v-progress-circular>
        </div>
    </div>
    <v-container id="user-profile" fluid tag="section" class="pa-1 full-width-div" v-else>
        <v-row justify="center">
            <v-col cols="6" md="6">
                <base-material-card title="Holiday Detail">
                    <v-card-text class="text-center">
                        <v-row class="mt-7">
                            <v-col cols="4" class="pt-0 pb-0 text-left">
                                <p class="font-weight-light grey--text">Holiday</p>
                            </v-col>
                            <v-col cols="8" class="pt-0 pb-0 text-left">
                                <p v-if="holiday.name">{{holiday.name}}</p>
                                <p v-else> - </p>
                            </v-col>
                            <v-col cols="4" class="pt-0 pb-0 text-left">
                                <p class="font-weight-light grey--text">Holiday Type</p>
                            </v-col>
                            <v-col cols="8" class="pt-0 pb-0 text-left">
                                <p v-if="holiday.type">{{holiday.type}}</p>
                                <p v-else> - </p>
                            </v-col>
                            <v-col cols="4" class="pt-0 pb-0 text-left">
                                <p class="font-weight-light grey--text">Holiday Date</p>
                            </v-col>
                            <v-col cols="8" class="pt-0 pb-0 text-left">
                                <p v-if="holiday.date">{{holiday.date}}</p>
                                <p v-else> - </p>
                            </v-col>
                            <v-col cols="12">
                                <hr>
                            </v-col>
                            <v-col cols="6" class="pt-4 pb-0 text-left">
                                <p class="font-weight-light grey--text">Holiday Locations</p>

                                <v-list dense>
                                    <v-list-item v-for="location in location" :key="location.id" class="pl-0">

                                        <v-chip class="ma-2">
                                            {{ location['name'] }}</v-chip>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </base-material-card>
            </v-col>
            <v-col cols="6">
                <base-material-card title="Holiday Location update">
                    <v-row>
                        <v-form @submit.prevent="validateBeforeSubmit('updateLocation')" data-vv-scope="updateLocation" id="holidays" ref="import_holidays" enctype="multipart/form-data">
                            <v-layout row wrap class="field_wrapper">
                                <v-flex>
                                    <v-col cols="12">
                                        <v-col cols="12" class="pt-9 pb-0 text-left">
                                            <p class="font-weight-light grey--text">Holiday Location list</p>
                                        </v-col>
                                        <v-autocomplete v-model="location_values" :items="location_list" dense chips small-chips label="Select Location" item-text="name" item-value="id" multiple solo></v-autocomplete>
                                    </v-col>
                                </v-flex>
                            </v-layout>
                            <v-card-actions style="flex-direction: row-reverse;">
                                <v-btn small @click="import_dialog_box = false">Cancel</v-btn>
                                <v-btn small color="primary" type="submit" :loading="import_loading" :disabled="import_loading">Update</v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-row>
                </base-material-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="6" md="6">
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import moment from "moment";
export default {

    data() {
        return {

            loading: false,
            holiday: [],
            location: '',

            import_loading: false,

            location_list: [],
            location_values: [],
            snackbar: {
                message: "",
                display: false,
                color: "",
                top: true,
                right: true,
                bottom: false,
                left: false
            },
        }
    },
    methods: {
        getData() {
            this.loading = true;
            this.$axios
                .get(`${process.env.VUE_APP_API_URL}/api/holidays/` + this.$route.params.id + `/detail`)
                .then(response => {
                    this.loading = false;
                    if (response.data.status === 1) {
                        this.holiday = response.data.holiday;
                        this.location = response.data.Location;
                        this.location_values=[];
                        this.location.forEach(element => {
                            this.location_values.push(element.id);
                        });
                    }
                    if (response.data.status === 0) {
                        this.snackbar.message = response.data.message;
                        this.snackbar.display = true;
                    }
                })
                .catch(error => {
                    this.snackbar.message = "Error Occured";
                    this.snackbar.display = true;
                    this.loading = false;
                });
        },
        getLocationList() {

            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/locations/locationListForGroups`).then((response) => {
                if (response.data.status == 1) {
                    this.location_list = response.data.location_list;

                }
            }).catch((error) => {
                console.log(error)
            })
        },
        updateLocation() {
            this.import_loading = true;
            let data = {
                'location': this.location_values,
                'holiday': this.holiday.id,
            }
            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/holidays/updateLocation`, data)
                .then((response) => {
                    this.import_loading = false;
                    if (response.data.status == 1) {
                        this.snackbar.message = response.data.message;
                        this.snackbar.display = true;
                        this.getData();
                        // this.$router.push({ path: this.$router.currentRoute.fullPath })

                    }

                }).catch((error) => {
                    console.log(error)
                    this.import_loading = false;
                })
        },
        validateBeforeSubmit: function (scope) {
            this.$validator.validateAll(scope).then(result => {
                if (result) {
                    this[scope]();
                }
            });
        }
    },
    mounted() {

        if (this.$route.params.id) {
            this.getData();
            this.getLocationList();
        } else {
            this.$router.push({ name: 'Holidays' })
        }
    }
}
</script>
