<template>
<v-container id="regular-tables" fluid tag="section" class="ma-0 pa-0">
    <v-row>
        <v-col cols="4" class="ma-6">
            <v-list dense style="background-color: #eeeeee;">
                <v-list-item-group color="primary">
                    <v-list-item v-for="(item, i) in report_list" :key="i" :to="item.to" style="background-color: #e6e4e4;padding: 2% 1% 2% 5%;margin-bottom: 4%;">
                        <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list><br>

        </v-col>
    </v-row>
</v-container>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import { mapActions } from 'vuex'
export default {
    data() {
        return {
            report_list: [{
                    icon: 'mdi-file-chart',
                    text: 'Muster Report',
                    to: '/musterReport',
                },
                {
                    icon: 'mdi-file-chart',
                    text: 'Employee Work Hour Report',
                    to: '/workhourReport',

                },
                {
                    icon: 'mdi-file-chart',
                    text: 'Employee Manual Attandance Report',
                    to: '/ManulattendanceReport',
                },
                {
                    icon: 'mdi-file-chart',
                    text: 'Employee Leave Report',
                    to: '/LeaveReport',
                }
            ],

        }

    },
    methods: {}
};
</script>
