<template>
<div v-if="detail_loader " style="position:absolute; top:0; right:0; bottom:0; left:0; padding-top: 25%;">
    <div class="text-center">
        <v-progress-circular indeterminate color="primary" class="text-center my-5" large></v-progress-circular>
    </div>
</div>
<v-container id="user-profile" fluid tag="section" v-else class="pa-1 full-width-div">
    <v-row justify="center">
        <v-col cols="12" md="8">
            <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                        Edit Location
                    </div>
                </template>
                <v-form id="updateLocation" @submit.prevent="validateBeforeSubmit('updateLocation')" data-vv-scope="updateLocation" v-model="valid">
                    <v-container class="py-0">
                        <v-row>
                            <v-col>
                                <label>Name <span style="color:red">*</span></label>
                                <v-text-field v-model="location.name" class="purple-input" name="Name" v-validate="'required'" :error-messages="errors.collect('updateLocation.Name')" />
                                <label>Radius <span style="color:red">*</span></label>
                                <v-text-field v-model="location.radius" class="purple-input" name="Radius" 
                                 type="number"
                                v-validate="'required'" :error-messages="errors.collect('updateLocation.Radius')" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <gmap-autocomplete id="styled-input" @place_changed="setPlace" />

                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <gmap-map :center="center" :zoom="15" style="width:100%;  height: 400px;">
                                    <gmap-circle :center="center" 
                                    :radius=" parseInt(location.radius)" :visible="true" 
                                    :clickable="true" :options="{fillColor:'red',fillOpacity:0.22}" />

                                    <gmap-marker ref="myMarker" :position="marker.position" :draggable="true" @click="center=marker.position" @drag="updateCoordinates" />
                                </gmap-map>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <label>Address <span style="color:red">*</span></label>
                                <v-text-field v-model="location.address" readonly class="purple-input" name="Address" v-validate="'required'" :error-messages="errors.collect('updateLocation.Address')" />
                                <span class="red--text" v-text="formErrors.address" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <input v-model="location.lat" type="hidden">
                                <span class="red--text" v-text="formErrors.lat" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <input v-model="location.long" type="hidden" class="purple-input">
                                <span class="red--text" v-text="formErrors.long" />
                            </v-col>
                            <v-col cols="12" class="text-right">
                                <v-btn to="/locations" color="#FB8C00">
                                    Back
                                </v-btn>
                                <v-btn color="primary" :disabled="!valid" type="submit">
                                    Update
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </base-material-card>
        </v-col>
    </v-row>
</v-container>
</template>

<style>
#styled-input {
    height: 40px;
    width: 850px;
    font-size: 15pt;
}
</style>

<script>
/*global google*/

import { mapActions } from 'vuex'
import { mapState } from 'vuex';

export default {
    name: 'GoogleMap',
    watch: {
        location: {
            handler: function (val, oldVal) {
                this.showmap();
            },
            deep: true
        }
    },
    data() {
        return {
            location: {
                name: '',
                lat: '',
                long: '',
                address: '',
                radius:0,
            },
            center: { lat: 45.508, lng: -73.587 },
            marker: {},
            places: [],
            currentPlace: null,
            formErrors: {},
            markedRadius: null,
            valid: false,
            detail_loader: false,
        }
    },

    methods: {
        ...mapActions(['userAuth']),
        // receives a place object via the autocomplete component
        setPlace(place) {
            const marker = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            }
            this.marker.position = marker
            this.center = marker
            this.mapZoom = 15;
            this.$emit('input', this.getAddressComponents(place))
        },
        getAddressComponents(place) {
            let postal_code = place.address_components.find(addressComponent => addressComponent.types.includes('postal_code'))
            let city = place.address_components.find(addressComponent => addressComponent.types.includes('locality'))
            let state = place.address_components.find(addressComponent => addressComponent.types.includes('administrative_area_level_1'))
            let country = place.address_components.find(addressComponent => addressComponent.types.includes('country'))
            let address = place.formatted_address

            this.location.address = address ? address : null;
            this.location.lat = place.geometry.location.lat();
            this.location.long = place.geometry.location.lng();
        },
        updateCoordinates(location) {
            const marker = {
                lat: location.latLng.lat(),
                lng: location.latLng.lng(),
            }
            this.marker.position = marker

            const geocoder = new google.maps.Geocoder()
            geocoder.geocode({ 'latLng': location.latLng }, (result, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    this.$emit('input', this.getAddressComponents(result[0]))
                }
            })

        },
        geolocate: function () {

            navigator.geolocation.getCurrentPosition(position => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                }
            })
        },
        validateBeforeSubmit: function (scope) {

            this.$validator.validateAll(scope).then(result => {
                if (result) {
                    this[scope]();
                }
            });
        },
        showmap(){
      
        },
        getDetails() {
            this.detail_loader = true;
            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/locations/` +
                    this.$route.params.id + `/edit`
                )
                .then(response => {
                    // console.log(response.data.employee)
                    if (response.data.status == 1) {
                        this.location = response.data.location;

                        const current_marker = {
                            lat: this.location.lat,
                            lng: this.location.long,

                        }
                        this.marker.position = current_marker;
                        this.center = current_marker;
                        this.detail_loader = false;

                    }

                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {});
        },
        updateLocation() {
            this.userAuth()

            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/locations/` + this.$route.params.id + `/update`, this.location).then((response) => {

                if (response.data.status === 1) {
                    // this.location = response.data.location
                    this.$router.push({ name: 'Locations' })
                }
            }).catch((error) => {

            })
        },
    },
    mounted() {
        this.geolocate();
        this.getDetails();
    }
};
</script>
