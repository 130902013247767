<template>
<v-container id="regular-tables" fluid tag="section" class="pa-1 full-width-div">
    <!-- <base-v-component
      heading="Simple Tables"
      link="components/simple-tables"
    /> -->
    <v-row>
        <v-flex xs12 class="buttons">
            <v-btn color="success" class="mx-1" @click="exportData()" :loading="export_loader">
                Export
            </v-btn>
            <!-- <v-btn color="success" class="mx-1" to="addActivity" v-if="this.checkPermission('create-activities')">
                Add Activity
            </v-btn> -->
            <v-btn class="ml-5" color="success" @click="filter_dialog_box = !filter_dialog_box">
                Filters
            </v-btn>
        </v-flex>
    </v-row>
    <base-material-card icon="mdi-clipboard-text" title="Activities" class="px-5 py-3">
        <v-data-table :headers="headers" :items="activities" item-key="id" :sort-by="['datetime']" :sort-desc="[true]" :options.sync="options" :server-items-length="total_items" :loading="is_loading" no-results-text="Loading... Please wait">
            <template v-slot:progress>
                <v-progress-linear color="success" indeterminate></v-progress-linear>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" :key="item.name">
                        <td v-if="item.image">
                            <v-avatar size="36">
                                <img :src="storage_path() + item.image.image" @error="setAltImg" @click="fullWidthImage(item.image.image)" />
                            </v-avatar>
                        </td>
                        <td v-else>-</td>

                        <td>
                            <router-link v-if="item.id" class="ml-1" :to="{ name: 'Activity Detail', params: { id: item.id } }">
                                {{ toTitleCase(item.type).replace(/_/g, " ") }}
                            </router-link>
                        </td>
                        <td v-if="item.user">
                            <router-link :to="{
                    name: 'Employee Detail',
                    params: { id: item.user.id }
                  }">{{ item.user.name }}</router-link>
                        </td>
                        <td v-else>-</td>
                        <td class="pa-2">
                            <v-chip text-color="green" color="green" v-if="item.location_status == 'Authorized'">
                                {{ item.location_status }}
                            </v-chip>
                            <v-chip text-color="red" color="red" v-else>
                                {{ item.location_status }} </v-chip><br />
                            <v-chip text-color="red" color="red" v-if="item.is_late" class="mt-1">
                                Late
                            </v-chip>
                        </td>
                        <td v-if="item.location" class="address-width">
                            {{ item.location.name }} <br />{{ item.address }}
                        </td>
                        <td v-else class="address-width" text-color="red">
                            Unauthorized Location <br />{{ item.address }}
                        </td>
                        <td v-if="item.datetime">{{ formatDateTime(item.datetime) }}</td>
                        <td v-else>-</td>

                        <td>
                            <v-tooltip left><template v-slot:activator="{ on, attrs }">
                                    <v-btn :disabled="disableCheck(item)" v-bind="attrs" v-on="on" text icon small color="success lighten-2" class="mr-1" @click="face(item, 'approve')">
                                        <v-icon large>mdi-check</v-icon>
                                    </v-btn>
                                </template><span>Approve User Face</span></v-tooltip>

                            <v-tooltip left><template v-slot:activator="{ on, attrs }">
                                    <v-btn :disabled="disableCheck(item)" v-bind="attrs" v-on="on" text icon small color="red lighten-2" @click="face(item, 'reject')">
                                        <v-icon large>mdi-close</v-icon>
                                    </v-btn>
                                </template><span>Reject User Face</span></v-tooltip>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:no-data-text>
                <v-layout row wrap justify-center class="text-xs-center" v-if="is_loading">
                    Loading... Please wait
                </v-layout>
                <v-layout row wrap justify-center class="text-xs-center" v-else>
                    No data available
                </v-layout>
            </template>
        </v-data-table>
    </base-material-card>

    <v-dialog v-model="image_dialog_box" max-width="290" max-height="290" hide-overlay color="white">
        <v-card elevation="0">
            <v-img :src="storage_path() + image_path"></v-img>
        </v-card>
    </v-dialog>

    <!-- filter dialog box -->
    <v-dialog v-model="filter_dialog_box" scrollable :overlay="false" max-width="450px" transition="dialog-transition" hide-overlay>
        <v-card style="height:100%">
            <v-form id="filterList" @submit.prevent="validateBeforeSubmit('filterList')" data-vv-scope="filterList" v-model="valid">
                <v-card-text>
                    <v-card-title primary-title>
                        <span class="headline">Filters</span>
                    </v-card-title>
                    <v-container fluid grid-list-md style="padding: 0 16px 16px 16px">
                        <v-layout row wrap class="field_wrapper">
                            <v-flex xs12 md12>
                                <label>Employee Name</label>
                                <br />
                                <v-text-field name="username" id="username" type="text" v-model="filters.username" placeholder="Enter Employee Name"></v-text-field>
                            </v-flex>
                            <v-flex xs12 md12>
                                <label>Activity Type</label>
                                <br />
                                <v-select :items="type_list" item-text="text" item-value="value" v-model="filters.type" placeholder="Select Activity Type"></v-select>
                            </v-flex>

                            <v-flex xs12 md12>
                                <label>Location Status</label>
                                <br />
                                <v-select :items="status_list" item-text="value" item-value="value" v-model="filters.location_status" placeholder="Select Location Status"></v-select>
                            </v-flex>
                            <v-flex xs12 md12>
                                <label>Activity Date</label>
                                <br />
                               <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field readonly v-bind="attrs" v-on="on" :value="filters.activity_date" placeholder="Enter Activity Date"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.activity_date" @input="menu = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="filter_dialog_box = !filter_dialog_box">Cancel</v-btn>
                    <v-btn small color="error" @click="resetFilters">Reset</v-btn>
                    <v-btn small color="primary" :disabled="!valid" type="submit">Apply</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <!-- Delete Activity Dialog Box -->
    <v-dialog v-model="delete_activity_box" scrollable :overlay="false" max-width="450px" transition="dialog-transition" hide-overlay>
        <v-card>
            <v-form>
                <v-card-title primary-title>
                    <span>Are you sure you wish to delete this Activity ?</span>
                </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="delete_activity_box = false">Cancel</v-btn>
                    <v-btn small color="primary" @click="deleteActivity">OK</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
    <!-- Delete Activity Dialog Box -->
    <v-dialog v-model="face_approval_box" scrollable :overlay="false" max-width="450px" transition="dialog-transition" hide-overlay>
        <v-card>
            <v-form>
                <v-card-title primary-title>
                    <span>Are you sure you wish to {{ attendance_status.status }} this face
                        ?</span>
                </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="face_approval_box = false">Cancel</v-btn>
                    <v-btn small color="primary" @click="faceApprove">Confirm</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.display" :color="snackbar.color" :top="snackbar.top" :bottom="snackbar.bottom" :left="snackbar.left" :right="snackbar.right">
        {{ snackbar.message }}
    </v-snackbar>
</v-container>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import moment from "moment";
export default {
    watch: {
        options: {
            handler() {
                this.getList();
            }
        },

    },
    data() {
        return {
            activities: [],
            showModal: false,
            image_dialog_box: false,
            image_path: "",
            total_items: 10,
            page: 0,
            options: {},
            filters: {
                type: "",
                username: "",
                location_status: "",
                activity_date: "",
                late:false,
                unauthorized:false,
            },
            is_filtered: false,
            filter_dialog_box: false,
            filter_chips: {
                type: "",
                username: "",
                location_status: "",
                activity_date: ""
            },
            status_list: [
                { key: "Authorized", value: "Authorized" },
                { key: "Unauthorized", value: "Unauthorized" }
            ],
            type_list: [
                { text: "Check In", value: "check_in" },
                { text: "Check Out", value: "check_out" }
            ],
            valid: false,
            headers: [
                { text: "Image", value: "image", align: "left", sortable: false },
                {
                    text: "Activity",
                    value: "type",
                    align: "left",
                    width: "10%",
                    sortable: false
                },
                {
                    text: "Employee Name",
                    value: "name",
                    align: "left",
                    sortable: false
                },
                {
                    text: "Location Status",
                    value: "location_status",
                    align: "left",
                    sortable: false
                },
                {
                    text: "Location ",
                    value: "location",
                    align: "left",
                    sortable: false,
                    width: "30%"
                },
                { text: "Time", value: "time", width: "13%", sortable: false },
                {
                    text: "Face Approval",
                    value: "",
                    align: "left",
                    sortable: false,
                    width: "10%"
                }
            ],
            employee_list: [],
            delete_activity_box: false,
            face_approval_box: false,
            selected_activity_id: "",
            is_loading: false,
            auth_user_role: "",
            auth_user: {},

            attendance_status: {
                id: "",
                status: ""
            },
            is_changing: false,
            snackbar: {
                message: "",
                display: false,
                color: "",
                top: true,
                right: true,
                bottom: false,
                left: false
            },

            export_loader: false,
            menu:'',
        };
    },
    // created() {
    //   this.userAuth()
    //   this.getActivities()
    // },
    template: "#modal-template",
    methods: {
        ...mapActions(["userAuth"]),

        disableCheck(item) {
            if (this.checkPermission("update-activities")) {
                if (item.is_identified == 0) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        },
        face(item, result) {
            this.attendance_status.id = item.id;
            this.attendance_status.status = result;
            this.face_approval_box = true;
        },

        faceApprove() {
            this.is_changing = true;
            axios
                .post(
                    `${process.env.VUE_APP_API_URL}/api/activities/update-status`,
                    this.attendance_status
                )
                .then(response => {
                    this.is_changing = false;
                    this.snackbar.message = response.data.message;
                    this.snackbar.display = true;
                    this.face_approval_box = false;
                    this.getList();
                });
            this.is_changing = false;
            this.face_approval_box = false;
            this.getList();
        },

        getList() {
            this.is_loading = true;

            this.options.filters = Object.assign({}, this.filters);
            axios
                .get(`${process.env.VUE_APP_API_URL}/api/activities`, {
                    params: this.options
                })
                .then(response => {
                    // console.log(response.data.activities.data)
                    if (response.data.status == 1) {
                        this.activities = response.data.activities.data;
                        this.page = response.data.activities.page;
                        this.total_items = response.data.activities.total;
                        this.is_loading = false;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getEmployeeList() {
            this.$axios
                .get(
                    `${process.env.VUE_APP_API_URL}/api/employees/registeredEmployeeList`
                )
                .then(response => {
                    if (response.data.status === 1) {
                        this.employee_list = response.data.registered_employee_list;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        deleteActivity: function () {
            this.$axios
                .delete(
                    `${process.env.VUE_APP_API_URL}/api/activities/` +
                    this.selected_activity_id +
                    `/delete`
                )
                .then(response => {
                    this.response_message = response.data.message;

                    if (response.data.status == 1) {
                        this.delete_activity_box = false;
                        this.getList();
                    } else if (response.data.status == 2) {
                        this.delete_activity_box = false;
                        this.getList();
                    }
                    // this.snackBar.message = response.data.message;
                    // this.snackBar.show = true;
                })
                .catch(error => {});
        },
        filterList() {
            let filter_values = Object.values(this.filters);
            if (filter_values.join("").length > 0) {
                this.$router.replace({
                    name: "Activities",
                    query: {
                        filters: true,
                        type: this.filters.type,
                        username: this.filters.username,
                        location_status: this.filters.location_status,
                        activity_date: this.filters.activity_date,
                        late:this.filters.late,
                        unauthorized:this.filters.unauthorized
                    }
                });
                this.displayFilterChips(this.filters);
                this.is_filtered = true;
            } else {
                this.$router.replace({
                    name: "Activities"
                });
            }
            this.getList();
            this.filter_dialog_box = false;
        },
        resetFilters() {
            this.$router.replace({
                name: "Activities",
                query: {}
            });
            this.resetFiltersParameters();
            this.is_filtered = false;
            this.getList();
            this.filter_dialog_box = false;
        },
        resetFiltersParameters: function () {
            for (var key in this.filters) {
                this.filters[key] = "";
            }
        },
        displayFilterChips: function (filters) {
            if (this.$route.query.filters) {
                this.filter_chips.username = this.filters.username;
                // this.filter_chips.assigned_to = this.filters.assigned_to;
                this.filter_chips.location_status = this.filters.location_status;
                this.filter_chips.type = this.filters.type;
                this.filter_chips.activity_date = this.filters.activity_date;

                this.is_filtered = true;
            }
        },
        clearFilter: function (filter, key) {
            this.filter_chips[key] = this.filters[key] = "";
            this.filterList();
        },
        applyFiltersOnLoad: function () {
            this.filters.username = this.$route.query.username;
            this.filters.type = this.$route.query.type;
            this.filters.location_status = this.$route.query.location_status;
            this.filters.activity_date = this.$route.query.activity_date;
            this.filters.late = this.$route.query.late;
            this.filters.unauthorized = this.$route.query.unauthorized;
        },
        validateBeforeSubmit: function (scope) {
            this.$validator.validateAll(scope).then(result => {
                if (result) {
                    this[scope]();
                }
            });
        },
        fullWidthImage(item_image) {
            this.image_dialog_box = true;
            this.image_path = item_image;
        },
        checkPermission(permission, record = null) {
            let p_record = this.$store.getters.get_permissions.find(
                p => p.name == permission
            );
            if (p_record !== undefined) {
                if (record == null) {
                    return true;
                } else {
                    switch (p_record.access_to) {
                        case "created":
                            if (record.user_id == this.$store.getters.get_user.id) {
                                return true;
                            } else {
                                return false;
                            }

                            case "group":
                                return true;

                            case "all":
                                return true;

                            default:
                                break;
                    }
                }
            } else {
                return false;
            }
        },

        exportData() {
            this.export_loader = true;
            this.options.filters = Object.assign({}, this.filters);
            axios
                .get(`${process.env.VUE_APP_API_URL}/api/activities/export`, {
                    params: this.options
                })
                .then(response => {
                    if (response.status == 0) {
                        this.snackbar.message = response.data.message;
                        this.snackbar.display = true;
                        this.export_loader = false;
                    }
                    this.download(response.data);
                    this.export_loader = false;
                })
                .catch(error => {
                    this.export_loader = false;
                    this.snackbar.message = "Error Occured";
                    this.snackbar.display = true;
                });
        },
        download(data) {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Activity-Report" + Date.now() + ".csv");
            document.body.appendChild(link);
            link.click();
        }
    },
    mounted() {
        this.applyFiltersOnLoad();
        this.getEmployeeList();
        this.auth_user = this.$store.getters.get_user;
        if (this.auth_user) {
            this.auth_user_role = this.auth_user.roles[0].name;
        }
    }
};
</script>
