<template>
<v-container class="pa-1 full-width-div">
    <v-row>
        <v-col cols="12" md="6">
            <v-row>
                <v-col cols="12" md="12">
                    <base-material-card>
                        <template v-slot:heading>
                            <v-row class="py-0">
                                <v-col cols="10" class="py-0">
                                    <h3 color="white lighten-1">Group Details</h3>
                                </v-col>
                                <v-col cols="2" class="py-0">
                                    <v-btn class="text-right" icon :to="{ name:'Edit Group',params: { id: details.id } }">
                                        <v-icon large color="white lighten-1">mdi-pencil</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>

                        </template>

                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <h3>
                                        <span class="grey--text"> Name :</span> {{details.name}}
                                    </h3>

                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <p>
                                        <span class="grey--text">Type :</span> {{toTitleCase(details.type)}}
                                    </p>

                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <p>
                                        <span class="grey--text">Location/Department :</span> {{details.record.name}}
                                    </p>

                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <p>
                                        <span class="grey--text">Users Count :</span> {{details.users_count}}
                                    </p>

                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <p><span class="grey--text">Admins :</span> </p>
                                    <p v-for="(admin, index) in details.group_admins" :key="admin.id">
                                        {{admin.name}}<span v-if="index != (details.group_admins.length - 1)">,</span>
                                    </p>

                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <p>
                                        <span class="grey--text">Created At :</span> {{formatDateTime(details.created_at)}}
                                    </p>

                                </v-col>
                            </v-row>

                        </v-card-text>
                    </base-material-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <base-material-card>
                        <template v-slot:heading>
                            <v-row class="py-0">
                                <v-col cols="10" class="py-0">
                                    <h3 color="white lighten-1"> Admins</h3>
                                </v-col>
                                <!-- <v-col cols="2" class="py-0">
                                    <v-btn class="text-right" icon @click="addUserDialog()">
                                        <v-icon large color="white lighten-1">mdi-plus-circle</v-icon>
                                    </v-btn>
                                </v-col> -->
                            </v-row>
                        </template>

                        <v-card-text>
                            <!-- <v-text-field class="mx-3" flat label="Search" prepend-inner-icon="search" solo v-model="search" clearable @click:clear="clearSearch"></v-text-field> -->
                            <v-list style="max-height: 100vh; overflow-y:auto;" dense>

                                <template v-for="(group_related_user,index) in admin">
                                    <v-list-item :key="group_related_user.id" class="pt-2">
                                        <v-list-item-action>

                                            <v-avatar size="36">
                                                <img :src="storage_path()+group_related_user.image.image">
                                            </v-avatar>

                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title v-if="group_related_user">{{group_related_user.name}} - {{group_related_user.employee_id}}</v-list-item-title>

                                            <v-list-item-subtitle v-if="group_related_user.designation">{{group_related_user.designation.name}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        

                                    </v-list-item>
                                    <v-divider v-if="index + 1 < group_related_users.length" :key="index"></v-divider>
                                </template>
                            </v-list>
                        </v-card-text>
                    </base-material-card>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" md="6">

            <base-material-card>
                <template v-slot:heading>

                    <v-row class="py-0">
                        <v-col cols="10" class="py-0">
                            <h3 color="white lighten-1"> Users</h3>
                        </v-col>
                        <v-col cols="2" class="py-0">
                            <v-btn class="text-right" icon @click="addUserDialog()">
                                <v-icon large color="white lighten-1">mdi-plus-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                </template>

                <v-card-text>
                    <v-text-field class="mx-3" flat label="Search" prepend-inner-icon="search" solo v-model="search" clearable @click:clear="clearSearch"></v-text-field>
                    <v-list style="max-height: 100vh; overflow-y:auto;" dense>

                        <template v-for="(group_related_user,index) in filteredItems">
                            <v-list-item :key="group_related_user.id" class="pt-2">
                                <v-list-item-action>

                                    <v-avatar size="36">
                                        <img :src="storage_path()+group_related_user.image.image">
                                    </v-avatar>

                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-if="group_related_user">{{group_related_user.name}} - {{group_related_user.employee_id}}</v-list-item-title>

                                    <v-list-item-subtitle v-if="group_related_user.designation">{{group_related_user.designation.name}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon small @click="remove_user_box= !remove_user_box; selected_user_id=group_related_user.id">
                                        <v-icon color="red lighten-1">mdi-close</v-icon>
                                    </v-btn>
                                </v-list-item-action>

                            </v-list-item>
                            <v-divider v-if="index + 1 < group_related_users.length" :key="index"></v-divider>
                        </template>
                    </v-list>
                </v-card-text>
            </base-material-card>
        </v-col>
    </v-row>

    <!-- Add User Box -->
    <v-dialog v-model="add_user_box" scrollable :overlay="false" max-width="600" transition="dialog-transition" hide-overlay>
        <v-card style="height:100%;">
            <v-form id="addUsers" @submit.prevent="validateBeforeSubmit('addUsers')" data-vv-scope="addUsers" v-model="add_user_valid">
                <v-card-text>
                    <v-card-title primary-title>
                        <span class="headline">Add Member/Admin To - {{details.name}}</span>
                    </v-card-title>
                    <div class="pa-3">
                        <v-autocomplete :items="search_user_list" :loading="user_search_loader" :search-input.sync="user_search_query" v-model="user_search_select" class="hidden-sm-and-down" label="Search" item-text="name" return-object :filter="v => v">
                            <template v-slot:item="{ item }">

                                <v-list-item-content>
                                    <v-list-item-title v-text="item.name"></v-list-item-title>
                                    <v-list-item-subtitle v-text="item.employee_id"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                        <v-layout row wrap>
                            <v-flex xs12 class="pl-2">
                                <span class="subheading">Selected Users : </span>
                            </v-flex>
                            <v-flex xs12>
                                <v-chip v-for="u in selected_user_list" :key="u.name" close @click:close="removeChip(u)">
                                    {{u.name}}
                                </v-chip>
                            </v-flex>
                        </v-layout>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="add_user_box = false" :disabled="add_user_loader">Cancel</v-btn>
                    <!-- <v-btn  color="error" @click="resetFilters">Reset</v-btn> -->
                    <v-btn small color="primary" type="submit" :disabled="add_user_loader || !add_user_valid || selected_user_list.length==0" :loading="add_user_loader">Add</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <!-- Remove User Dialog Box -->
    <v-dialog v-model="remove_user_box" scrollable max-width="450px" transition="dialog-transition" hide-overlay>
        <v-card>

            <v-card-title primary-title>
                <span>Are you sure you wish to remove this User ?</span>
            </v-card-title>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small @click="remove_user_box=false">Cancel</v-btn>
                <v-btn small color="primary" @click="removeUser">OK</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
/* eslint-disable */
export default {
    watch: {
        user_search_query: {
            handler(val) {
                this.search_user_list = [];
                clearInterval(this.search_timer);
                if (val) {
                    this.search_timer = setTimeout(() => {
                        this.getEmployeeList(val);
                    }, 500);
                }
            },
        },
        user_search_select: {
            handler(val) {
                if (val) {
                    let v = val;
                    let i = this.selected_user_list.findIndex((user) => {
                        return user.id == v.id;
                    });
                    if (i == -1) {
                        this.selected_user_list.push(val);
                        this.user_search_query = "";
                    }
                }
            }
        },

    },
    computed: {
        filteredItems() {
            if (this.search) {

                return this.group_related_users.filter(item => {
                    return (item.name.toLowerCase().includes(this.search.toLowerCase()));
                });
            } else {
                return this.group_related_users;
            }
        }
    },
    methods: {
        getDetails() {

            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/groups/` + this.$route.params.id)
                .then(response => {
                    if (response.data.status == 1) {

                        this.details = response.data.group;
                        this.admin = response.data.group.group_admins;

                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        getGroupRelatedUsers() {
            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/groups/groupRelatedUsers/` + this.$route.params.id)
                .then(response => {
                    if (response.data.status == 1) {

                        this.group_related_users = response.data.group_related_users;

                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        removeUser() {
            this.remove_user_box = true;
            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/groups/removeUser/` + this.selected_user_id, { group_id: this.$route.params.id })
                .then((response) => {
                    if (response.data.status == 1) {
                        this.remove_user_box = false;
                        this.getDetails();
                        this.getGroupRelatedUsers();

                    }
                })
                .catch((error) => {
                    console.log(error);

                })
                .finally(() => {

                });

        },
         removeAdmin(){
          this.remove_loader=true;
           let data = {
                       id:this.admin_list,
                       group_id:this.group.id};

           this.$axios.post(`${process.env.VUE_APP_API_URL}/api/groups/removeAdmin`,data)
           .then((response) => {
                 this.remove_loader=false;
                if (response.data.status ==1) {
                    this.snackbar.message= response.data.message;
                    this.snackbar.display=false;
                    this.getDetails();
                }
                if (response.data.status === 0) {
                    this.snackbar.message= response.data.message;
                    this.snackbar.display=false;
                }
            }).catch((error) => {
                this.snackbar.message="Error Occured";
                this.snackbar.display=false;
                this.remove_loader=false;
            })
        },
        getEmployeeList(username) {
            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/groups/groupUnrelatedUsers/` + this.$route.params.id, { params: { username: username } }).then((response) => {

                if (response.data.status === 1) {
                    this.search_user_list = response.data.employee_list;
                }
            }).catch((error) => {

                console.log(error)
            })
        },
        addUserDialog(group_id) {
            this.add_user_box = true;
            this.selected_user_list = [];
            this.selected_group_id = group_id;
            this.getEmployeeList();
        },
         addAdminDialog(group_id) {
            this.add_admin_box = true;
            this.selected_user_list = [];
            this.selected_group_id = group_id;
            this.getEmployeeList();
        },
        removeChip(user) {

            this.selected_user_list.splice(this.selected_user_list.indexOf(user), 1)
            this.selected_user_list = [...this.selected_user_list]

        },
        addUsers() {
            this.add_user_loader = true;
            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/groups/addAdminsToGroup/` + this.$route.params.id, { users: this.selected_user_list })
                .then((response) => {
                    if (response.data.status == 1) {
                        this.add_user_box = false;

                        this.add_user_loader = false;
                        this.getGroupRelatedUsers();
                        this.getDetails();

                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.add_user_loader = false;
                })
                .finally(() => {

                });
        },
        validateBeforeSubmit: function (scope) {

            this.$validator.validateAll(scope).then(result => {
                if (result) {

                    this[scope]();
                }
            });
        },
        clearSearch() {
            this.search = "";
        },

    },
    data() {
        return {

            search: '',

            details: {},
            group_related_users: [],
            remove_user_box: false,
            selected_user_id: "",
            add_user_box: false,
            selected_user_list: [],
            search_user_list: [],
            search_timer: "",
            add_user_loader: false,
            add_user_valid: false,
            user_search_select: [],
            user_search_query: "",
            user_search_loader: false,

            admin: [],

        }
    },
    mounted() {
        this.getDetails();
        this.getGroupRelatedUsers();
    }
};
</script>
