<template>
<v-container id="user-profile" fluid tag="section" class="pa-1 full-width-div">
    <v-snackbar v-model="snackbar.display" :color="snackbar.color" :top="snackbar.top" :bottom="snackbar.bottom" :left="snackbar.left" :right="snackbar.right">
        {{ snackbar.message }}
    </v-snackbar>
    <v-row justify="center">
        <v-col cols="12" md="10">
            <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                        Add Attendance
                    </div>
                </template>
                <v-form id="addActivity" @submit.prevent="validateBeforeSubmit('addActivity')" data-vv-scope="addActivity" v-model="valid">
                    <v-container class="pt-8">
                        <v-row>
                            <v-col cols="6">
                                <label>Name <span style="color:red">*</span></label>
                                <v-autocomplete v-model="activity.user_id" :items="registered_employee_list" item-text="name" item-value="id" class="purple-input" name="Name" v-validate="'required'" :error-messages="errors.collect('addActivity.Radius')">
                                </v-autocomplete>
                            </v-col>
                            
                            <v-col cols="6">
                                <label>Date <span style="color:red">*</span></label>
                                <v-menu v-model="date_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :value="formatDate" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="activity.date" :max="new Date().toISOString().substr(0, 10)" @input="date_menu = false">

                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="6">
                                <label>Check-in Time <span style="color:red">*</span></label>
                                <v-menu ref="menu1" v-model="time_menu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="activity.time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="activity.time" readonly v-on="on" name="Start Time"></v-text-field>
                                    </template>
                                    <v-time-picker v-if="time_menu" v-model="activity.time" @click:minute="$refs.menu1.save(activity.time)"></v-time-picker>
                                </v-menu>
                            </v-col>
                              <v-col cols="6">
                                <label>Check-out Time <span style="color:red">*</span></label>
                                <v-menu ref="menu2" v-model="time_menu2" :close-on-content-click="false" :nudge-right="40" :return-value.sync="activity.time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="activity.time2" readonly v-on="on" name="Start Time"></v-text-field>
                                    </template>
                                    <v-time-picker v-if="time_menu2" :min="activity.time" v-model="activity.time2" @click:minute="$refs.menu2.save(activity.time)"></v-time-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <gmap-autocomplete id="styled-input" @place_changed="setPlace" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <gmap-map :center="center" :zoom="15" style="width:100%;  height: 400px;">
                                    <gmap-marker ref="myMarker" :position="marker.position" :draggable="true" @click="center=marker.position" @drag="updateCoordinates" />
                                </gmap-map>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <label>Address <span style="color:red">*</span></label>
                                <v-text-field v-model="activity.address" readonly class="purple-input" name="Address" v-validate="'required'" :error-messages="errors.collect('addActivity.Address')" />
                                <span class="red--text" v-text="formErrors.address" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <input v-model="activity.lat" type="hidden">
                                <span class="red--text" v-text="formErrors.lat" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <input v-model="activity.long" type="hidden" class="purple-input">
                                <span class="red--text" v-text="formErrors.long" />
                            </v-col>
                            <v-col cols="12" class="text-right">
                                <v-btn to="/activities" color="#FB8C00">
                                    Back
                                </v-btn>
                                <v-btn color="primary" :loading="loading" :disabled="loading" type="submit">
                                    Add
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </base-material-card>
        </v-col>
    </v-row>
</v-container>
</template>

<style>
#styled-input {
    height: 40px;
    width: 850px;
    font-size: 15pt;
}
</style>

<script>
/*global google*/

import { mapActions } from 'vuex'
import { mapState } from 'vuex';
import moment from 'moment';

export default {
    name: 'GoogleMap',
    computed: {

        formatDate() {
            return this.activity.date ? moment(this.activity.date).format('DD-MMM-YY') : ''
        },

    },
    data() {
        return {
            snackbar: {
                message: "",
                display: false,
                color: "",
                top: true,
                right: true,
                bottom: false,
                left: false
            },
            activity: {
                id: '',
                datetime: '',
                user_id: '',
                type: '',
                attendance_id: '',
                lat: '',
                long: '',
                address: '',
                radius: '',
            },
            loading: false,
            current_time: moment().format('hh:mm'),
            time_menu: false,
            time_menu2: false,
            date_menu: false,
            center: { lat: 45.508, lng: -73.587 },
            marker: {},
            places: [],
            currentPlace: null,
            formErrors: {},
            markedRadius: null,
            valid: false,
            registered_employee_list: [],
            type_list: [
                { key: 'check_in', value: 'Check In' },
                { key: 'check_out', value: 'Check Out' }
            ],
        }
    },
    mounted() {

        this.geolocate();
        this.getRegisteredEmployeeList();
    },
    methods: {
        ...mapActions(['userAuth']),

        getRegisteredEmployeeList() {

            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/employees/registeredEmployeeList`).then((response) => {

                if (response.data.status === 1) {
                    this.registered_employee_list = response.data.registered_employee_list;

                }
            }).catch((error) => {

            })
        },
        // receives a place object via the autocomplete component
        setPlace(place) {
            const marker = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            }
            this.marker.position = marker
            this.center = marker
            this.mapZoom = 15;
            this.$emit('input', this.getAddressComponents(place))
        },
        getAddressComponents(place) {
            let postal_code = place.address_components.find(addressComponent => addressComponent.types.includes('postal_code'))
            let city = place.address_components.find(addressComponent => addressComponent.types.includes('locality'))
            let state = place.address_components.find(addressComponent => addressComponent.types.includes('administrative_area_level_1'))
            let country = place.address_components.find(addressComponent => addressComponent.types.includes('country'))
            let address = place.formatted_address

            // postal_code: postal_code ? postal_code.long_name : null,
            // city: city ? city.long_name : null,
            // state: state ? state.long_name : null,
            // country: country ? country.long_name : null,
            this.activity.address = address ? address : null;
            this.activity.lat = place.geometry.location.lat();
            this.activity.long = place.geometry.location.lng();
            // }
            // this.location.address = returnObject.address
        },

        geolocate: function () {

            navigator.geolocation.getCurrentPosition(position => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                }
            })
        },
        validateBeforeSubmit: function (scope) {

            this.$validator.validateAll(scope).then(result => {
                if (result) {

                    this[scope]();
                }
            });
        },
        addActivity() {
            this.loading = true;  console.log(this.activity);
            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/attendance/manual-attendance`, this.activity)
            .then((response) => {
                this.loading = false;
                if (response.data.status === 1) {
                    // this.location = response.data.location
                    this.loading = false;
                    this.$router.push({ name: 'Attendance' })
                }
                if (response.data.status === 0) {
                    this.loading = false;
                    this.snackbar.message = response.data.message;
                    this.snackbar.display = true;
                }
            }).catch((error) => {
                this.loading = false;
                this.snackbar.message = "Error Occured";
                this.snackbar.display = true;
            })
        },
    },
};
</script>
