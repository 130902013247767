<template>
<v-container class="pa-1 full-width-div">
    <v-row>
        <v-col cols="12" md="6">

            <base-material-card>
                <template v-slot:heading>
                    <v-row class="py-0">
                        <v-col cols="10" class="py-0">
                             <h3 color="white lighten-1"> Location Details</h3>
                        </v-col>
                        <v-col cols="2" class="py-0">
                            <v-btn class="text-right" icon :to="{ name:'Edit Location',params: { id: details.id } }" v-if="checkPermission('update-locations')">
                                <v-icon large color="white lighten-1">mdi-pencil</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                </template>

                <v-card-text>
                    <v-row>
                        <v-col>
                            <p>
                                {{details.name}}
                            </p>
                            <p class="font-weight-light grey--text">
                                {{details.address}}
                            </p>
                        </v-col>
                    </v-row>
                    <gmap-map :center="{lat:details.lat, lng:details.long}" :zoom="15" style="width:100%;  height: 400px;">
                    </gmap-map>

                </v-card-text>
            </base-material-card>
        </v-col>
        <v-col cols="12" md="6">

            <base-material-card>
                <template v-slot:heading>

                    <v-row class="py-0">
                        <v-col cols="10" class="py-0">
                             <h3 color="white lighten-1"> Authorized Users</h3>
                        </v-col>
                        <v-col cols="2" class="py-0">
                            <v-btn class="text-right" icon @click="addUserDialog()">
                                <v-icon large color="white lighten-1">mdi-plus-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                </template>

                <v-card-text>
                    <v-list style="max-height: 100vh; overflow-y:auto;" class="pt-0" dense>

                        <v-list-item v-for="authorized_user in authorized_users" :key="authorized_user.id" class="pt-2">
                            <v-list-item-action>

                                <v-avatar size="36">
                                    <img :src="storage_path()+authorized_user.image.image"  @error="setAltImg" >
                                </v-avatar>

                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title v-if="authorized_user.name">
                                  {{authorized_user.name}} - {{authorized_user.employee_id}}
                                </v-list-item-title>
                                <v-list-item-title v-else> - </v-list-item-title>

                                <v-list-item-subtitle v-if="authorized_user.designation">
                                  {{authorized_user.designation.name}}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-else> - </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon @click="remove_user_box= !remove_user_box; 
                                selected_user_id=authorized_user.id" style="background-color: #e4e2e2;">
                                    <v-icon color="red lighten-1">mdi-close</v-icon>
                                </v-btn>
                            </v-list-item-action>

                        </v-list-item>
                    </v-list>
                </v-card-text>
            </base-material-card>
        </v-col>
    </v-row>

    <!-- Add User Box -->
    <v-dialog v-model="add_user_box" scrollable :overlay="false" max-width="600" transition="dialog-transition">
        <v-card style="height:100%;">
            <v-form id="addUsers" @submit.prevent="validateBeforeSubmit('addUsers')" data-vv-scope="addUsers" v-model="add_user_valid">
                <v-card-text>
                    <v-card-title primary-title>
                        <span class="headline">Add User to Location - {{details.name}}</span>
                    </v-card-title>
                    <div class="pa-3">
                        <v-autocomplete :items="search_user_list" :loading="user_search_loader" :search-input.sync="user_search_query" v-model="user_search_select" class="hidden-sm-and-down" label="Search" item-text="name" return-object :filter="v => v">
                            <template v-slot:item="{ item }">

                                <v-list-item-content>
                                    <v-list-item-title v-text="item.name"></v-list-item-title>
                                    <v-list-item-subtitle v-text="item.employee_id"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                        <v-layout row wrap>
                            <v-flex xs12 class="pl-2">
                                <span class="subheading">Selected Users : </span>
                            </v-flex>
                            <v-flex xs12>
                                <v-chip v-for="u in selected_user_list" :key="u.name" close @click:close="removeChip(u)">
                                    {{u.name}}
                                </v-chip>
                            </v-flex>
                        </v-layout>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="add_user_box = false" :disabled="add_user_loader">Cancel</v-btn>
                    <!-- <v-btn  color="error" @click="resetFilters">Reset</v-btn> -->
                    <v-btn small color="primary" type="submit" :disabled="add_user_loader || !add_user_valid || selected_user_list.length==0" :loading="add_user_loader">Add</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <!-- Remove User Dialog Box -->
    <v-dialog v-model="remove_user_box" scrollable :overlay="false" max-width="650px" transition="dialog-transition">
        <v-card>
            <v-form>
                <v-card-text>
                    <v-card-title primary-title>
                        <span class="headline">Are you sure you wish to remove this User ?</span>
                    </v-card-title>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="black" small @click="remove_user_box=false">Cancel</v-btn>
                    <v-btn color="primary" small @click="removeUser">OK</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
// employee and user are same
export default {
    watch: {
        user_search_query: {
            handler(val) {
                this.search_user_list = [];
                clearInterval(this.search_timer);
                if (val) {
                    this.search_timer = setTimeout(() => {
                        this.getLocationUnrelatedUsers(val);
                    }, 500);
                }
            },
        },
        user_search_select: {
            handler(val) {
                if (val) {
                    let v = val;
                    let i = this.selected_user_list.findIndex((user) => {
                        return user.id == v.id;
                    });
                    if (i == -1) {
                        this.selected_user_list.push(val);
                        this.user_search_query = "";
                    }
                }
            }
        },
    },
    data() {
        return {
            details: {},
            center: {},
            authorized_users: [],
            remove_user_box: false,
            selected_user_id: "",
            selected_user_list: [],
            add_user_box: false,
            employee_list: [],
            search_user_list: [],
            search_timer: "",
            add_user_loader: false,
            add_user_valid: false,
            user_search_select: [],
            user_search_query: "",
            user_search_loader: false,

        }
    },
    methods: {
        getDetails() {

            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/locations/` + this.$route.params.id)
                .then(response => {
                    if (response.data.status == 1) {

                        this.details = response.data.location;
                        const current_marker = {
                            lat: this.details.lat,
                            lng: this.details.long,

                        }
                        this.center = current_marker

                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        getAuthorizedUsers() {

            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/locations/authorizedUsers/` + this.$route.params.id)
                .then(response => {
                    if (response.data.status == 1) {

                        this.authorized_users = response.data.authorized_users;

                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        removeChip(user) {

            this.selected_user_list.splice(this.selected_user_list.indexOf(location), 1)
            this.selected_user_list = [...this.selected_user_list]

        },
        addUserDialog() {
            // this.add_user_group = Object.assign({}, group);
            this.selected_user_list = [];
            this.add_user_box = true;
        },
        addUsers() {
            this.add_user_loader = true;
            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/locations/addUserToLocation/${this.$route.params.id}`, { users: this.selected_user_list })
                .then((response) => {
                    if (response.data.status == 1) {
                        this.add_user_box = false;
                        this.getDetails();
                        this.add_user_loader = false;
                        this.getAuthorizedUsers();

                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.add_user_loader = false;
                })
                .finally(() => {

                });
        },
        removeUser() {
            this.remove_user_box = true;
            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/locations/removeUser/` + this.selected_user_id, { location_id: this.$route.params.id })
                .then((response) => {
                    if (response.data.status == 1) {
                        this.remove_user_box = false;
                        this.getDetails();
                        this.getAuthorizedUsers();

                    }
                })
                .catch((error) => {
                    console.log(error);

                })
                .finally(() => {

                });

        },

        getLocationUnrelatedUsers(username) {
            console.log(username)
            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/locations/locationUnrelatedUsers/` + this.$route.params.id, { params: { username: username } }).then((response) => {

                if (response.data.status === 1) {
                    this.search_user_list = response.data.employee_list;

                }
            }).catch((error) => {

            })
        },
        validateBeforeSubmit: function (scope) {

            this.$validator.validateAll(scope).then(result => {
                if (result) {

                    this[scope]();
                }
            });
        },
    },
    mounted() {
        this.getDetails();
        this.getAuthorizedUsers();

    }
};
</script>
