<template>
<v-container id="user-profile" fluid tag="section" class="pa-1 full-width-div">
    <v-row justify="center">
        <v-col cols="12" md="8">
            <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                        Add Leave
                    </div>
                    <div class="subtitle-1 font-weight-light">
                        Add Leave Here
                    </div>
                </template>

                <v-form id="addLeave" @submit.prevent="validateBeforeSubmit('addLeave')" data-vv-scope="addLeave" v-model="valid">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="6">
                                <label>Employee Name <span style="color:red">*</span></label>
                                <v-autocomplete v-model="leave.user_id" :items="registered_employee_list" item-text="name" item-value="id" :readonly="auth_user_role=='employee'  || auth_user_role=='location_admin' || auth_user_role=='group_head'" class="purple-input" name="Name" v-validate="'required'" :error-messages="errors.collect('addLeave.Name')">
                                </v-autocomplete>

                            </v-col>
                            <v-col cols="12" md="6">
                                <label>Leave Type <span style="color:red">*</span></label>
                                <v-autocomplete v-model="leave.leave_type" :items="leave_type_list" item-text="value" item-value="key" class="purple-input" name="Leave Type" v-validate="'required'" :error-messages="errors.collect('addLeave.Leave Type')">
                                </v-autocomplete>

                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" v-if="leave.duration_type=='half_day'">

                                <label>Duration Type <span style="color:red">*</span></label>
                                <v-autocomplete v-model="leave.duration_type" :items="duration_type_list" item-text="value" item-value="key" class="purple-input" name="Duration Type" v-validate="'required'" :error-messages="errors.collect('addLeave.Duration Type')">
                                </v-autocomplete>

                            </v-col>
                            <v-col cols="12" md="12" v-else>

                                <label>Duration Type <span style="color:red">*</span></label>
                                <v-autocomplete v-model="leave.duration_type" :items="duration_type_list" item-text="value" item-value="key" class="purple-input" name="Duration Type" v-validate="'required'" :error-messages="errors.collect('addLeave.Duration Type')">
                                </v-autocomplete>

                            </v-col>

                            <v-col cols="12" md="6" v-if="leave.duration_type=='half_day'">
                                <label> Session <span style="color:red">*</span></label>
                                <v-autocomplete v-model="leave.start_session" :items="session_type_list" item-text="value" item-value="key" class="purple-input" name="Start Session" v-validate="'required'" :error-messages="errors.collect('addLeave.Start Session')">
                                </v-autocomplete>

                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12" v-if="leave.duration_type=='full_day'|| leave.duration_type=='half_day'">

                                <label>Select Date <span style="color:red">*</span></label>

                                <v-menu ref="start_menu" v-model="start_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :value="formatStartDate" readonly v-bind="attrs" v-on="on" v-validate="'required'" :error-messages="errors.collect('addLeave.Start Date')"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="leave.start_date" @input="start_menu = false">

                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="6" v-if="leave.duration_type=='multi_days'">

                                <label>Start Date <span style="color:red">*</span></label>
                                <v-menu ref="start_menu" v-model="start_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :value="formatStartDate" readonly v-bind="attrs" v-on="on" v-validate="'required'" :error-messages="errors.collect('addLeave.Start Date')"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="leave.start_date" :max="leave.end_date" @input="start_menu = false">

                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="6" v-if="leave.duration_type=='multi_days'">
                                <label>End Date <span style="color:red">*</span></label>
                                <v-menu v-model="end_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :value="formatEndDate" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="leave.end_date" @input="end_menu = false" :min="leave.start_date">

                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="12">
                                <label>Reason <span style="color:red">*</span></label>
                                <v-text-field v-model="leave.reason" class="purple-input" name="Reason" v-validate="'required'" :error-messages="errors.collect('addLeave.Reason')" />
                                <span class="red--text" v-text="formErrors.date" />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" class="text-right">
                                <v-btn to="/leaves" color="#FB8C00">
                                    Back
                                </v-btn>
                                <v-btn color="success" type="submit" :loading="loading" :disabled="loading">
                                    Add
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </base-material-card>
        </v-col>
    </v-row>

    <v-snackbar v-model="snackbar.display" :color="snackbar.color" :top="snackbar.top" :bottom="snackbar.bottom" :left="snackbar.left" :right="snackbar.right">
        {{ snackbar.message }}
    </v-snackbar>
</v-container>
</template>

<script>
import moment from 'moment'
export default {
    name: 'AddLeave',
    watch: {
        "leave.start_date": {
            handler(val) {
                if (val) {
                    if (this.leave.duration_type == 'half_day' || this.leave.duration_type == 'full_day') {
                        this.leave.end_date = val
                    }

                }
            }
        },
        "leave.duration_type": {
            handler(val) {

                if (val == 'full_day' || val == 'multi_days') {

                    this.leave.start_session = 'first_half'
                    this.leave.end_session = 'second_half'

                }
                if (val == 'half_day') {
                    this.leave.end_session = this.leave.start_session;
                }

            }
        },
        "leave.start_session": {
            handler(val) {
                if (this.leave.duration_type == 'half_day') {
                    this.leave.end_session = val;
                }

            }
        },

    },
    computed: {
        formatStartDate() {
            return this.leave.start_date ? moment(this.leave.start_date).format('DD-MMM-YY') : ''
        },
        formatEndDate() {
            return this.leave.end_date ? moment(this.leave.end_date).format('DD-MMM-YY') : ''
        },

    },

    data() {
        return {
            leave: {
                leave_type: 'casual_leave',
                start_date: '',
                end_date: '',
                duration_type: '',
                start_session: '',
                end_session: '',
                user_id: '',
                reason: ''
            },
            loading: false,
            start_menu: false,
            auth_user: {},
            end_menu: false,
            leave_type_list: [],
            duration_type_list: [{ key: "half_day", value: "Half Day" },
                { key: "full_day", value: "Full Day" },
                { key: "multi_days", value: "Multi Days" }
            ],
            formErrors: {},
            valid: false,
            registered_employee_list: [],
            session_type_list: [{ key: "first_half", value: "First Half" },
                { key: "second_half", value: "Second Half" }
            ],
            snackbar: {
                message: "",
                display: false,
                color: "",
                top: true,
                right: true,
                bottom: false,
                left: false
            },
            auth_user_role: "",

        }
    },

    methods: {

        validateBeforeSubmit: function (scope) {

            this.$validator.validateAll(scope).then(result => {
                if (result) {

                    this[scope]();
                }
            });
        },
        addLeave() {

            this.loading = true;
            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/leaves`, this.leave).then((response) => {

                this.snackbar.message = response.data.message;
                this.snackbar.display = true;

                this.loading = false;
                if (response.data.status === 1) {
                    // this.location = response.data.location
                    this.$router.push({
                        name: 'Leaves',
                        params: { message: response.data.message }
                    })

                }

            }).catch((error) => {
                 this.loading = false;
                 this.snackbar.message = "Error Occured";
                this.snackbar.display = true;
                // this.formErrors = error.response.data.error
            })
        },
        getRegisteredEmployeeList() {
            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/employees/registeredEmployeeList`).then((response) => {

                if (response.data.status === 1) {
                    this.registered_employee_list = response.data.registered_employee_list;

                }
            }).catch((error) => {

            })
        },
        getLeaveTypeList() {
            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/leaves/leaveTypeList`).then((response) => {

                if (response.data.status === 1) {
                    this.leave_type_list = response.data.leave_type_list;
                }
            }).catch((error) => {

                this.formErrors = error.response.data.error
            })
        }
    },

    mounted() {
        this.auth_user = this.$store.getters.get_user;

        if (this.auth_user) {
            this.auth_user_role = this.auth_user.roles[0].name
            this.leave.user_id = this.auth_user.id;
        }

        this.getRegisteredEmployeeList();
        this.getLeaveTypeList();

    }
};
</script>
