<template>
<div>
    <div v-if="loader.activty" style="position:absolute; top:0; right:0; bottom:0; left:0; padding-top: 20%; padding-left: 45%;background-color:rgba(255,255,255,0.31)">
    <div class="text-xs-center">
        <v-progress-circular color="success" class="text-xs-center my-5" :size="100" :width="10" indeterminate></v-progress-circular>

    </div>
   </div>
    <v-container id="user-profile" fluid tag="section" class="pa-1 full-width-div" v-else>
        <v-row justify="center">
            <v-col cols="6" md="6">
                <base-material-card title="Activty Detail" class="v-card-profile" :avatar="storage_path()+activity.image.image">
                    <v-card-text class="text-center">
                        <v-row class="mb-3">
                            <v-row style="margin-left: 37% !important;">
                                <v-chip class="ma-2" color="red" text-color="red" v-if="activity.is_identified==0">
                                    Employee unidentified
                                </v-chip>
                                <v-chip class="ma-2 pa-4" color="green" text-color="green" v-if="activity.is_identified==1">
                                    Employee Identified
                                </v-chip>
                            </v-row>
                            <v-row v-if="activity.is_identified==0">
                                <!-- Approve reject imgage code -->
                                <v-flex xs12 class="mt-3">
                                    <v-btn color="success" class="mx-2" @click="attendanceStatus('approve')">
                                        Approve
                                    </v-btn>
                                    <v-btn class="mx-2" color="error" @click="attendanceStatus('approve')">
                                        Reject
                                    </v-btn>
                                </v-flex>
                            </v-row>
                        </v-row>
                    </v-card-text>
                </base-material-card>
                <base-material-card title="Activty Detail">
                    <v-card-text class="text-center">
                        <v-row class="mt-7">
                            <v-col cols="4" class="pt-0 pb-0 text-left">
                                <p class="font-weight-light grey--text">Activity Type :</p>
                            </v-col>
                            <v-col cols="8" class="pt-0 pb-0 text-left">
                                <p v-if="activity.type=='check_in' || activity.type=='check-in'">Check-in</p>
                                <p v-else-if="activity.type=='check_out' || activity.type=='check-out'">Check-out</p>
                                <p v-else> - </p>
                            </v-col>
                            <v-col cols="4" class="pt-0 pb-0 text-left">
                                <p class="font-weight-light grey--text">Activity Time</p>
                            </v-col>
                            <v-col cols="8" class="pt-0 pb-0 text-left">
                                <p v-if="activity.datetime">
                                    {{formatDateTime(activity.datetime)}}
                                </p>
                                <p v-else> - </p>
                            </v-col>
                            <v-col cols="4" class="pt-0 pb-0 text-left">
                                <p class="font-weight-light grey--text">Address</p>
                            </v-col>
                            <v-col cols="8" class="pt-0 pb-0 text-left">
                                <p v-if="activity.address">{{activity.address}} </p>
                                <p v-else> - </p>
                            </v-col>

                            <v-col cols="4" class="pt-0 pb-0 text-left">
                                <p class="font-weight-light grey--text">Location Status</p>
                            </v-col>
                            <v-col cols="8" class="pt-0 pb-0 text-left">
                                <p v-if="activity.location_status">{{activity.location_status}} </p>
                                <p v-else> - </p>
                            </v-col>
                            <v-col cols="4" class="pt-0 pb-0 text-left">
                                <p class="font-weight-light grey--text">Manual Creation</p>
                            </v-col>
                            <v-col cols="8" class="pt-0 pb-0 text-left">
                                <p v-if="activity.is_manual==1">
                                    Yes
                                </p>
                                <p v-else> No </p>
                            </v-col>

                            <v-col cols="4" class="pt-0 pb-0 text-left">
                                <p class="font-weight-light grey--text">Late Check-in :</p>
                            </v-col>
                            <v-col cols="8" class="pt-0 pb-0 text-left">
                                <p v-if="activity.is_late=='1'">Yes</p>
                                <p v-if="activity.is_late=='0'">No</p>
                                <p v-else> - </p>
                            </v-col>
                            <v-col cols="4" class="pt-0 pb-0 text-left">
                                <p class="font-weight-light grey--text">longitude</p>
                            </v-col>
                            <v-col cols="8" class="pt-0 pb-0 text-left">
                                <p v-if="activity.long">{{activity.long}} </p>
                                <p v-else> - </p>
                            </v-col>

                            <v-col cols="4" class="pt-0 pb-0 text-left">
                                <p class="font-weight-light grey--text">latitude</p>
                            </v-col>
                            <v-col cols="8" class="pt-0 pb-0 text-left">
                                <p v-if="activity.lat">{{activity.lat}} </p>
                                <p v-else> - </p>
                            </v-col>
                            <v-col cols="4" class="pt-0 pb-0 text-left">
                                <p class="font-weight-light grey--text">Shift</p>
                            </v-col>
                            <v-col cols="8" class="pt-0 pb-0 text-left">
                                <p v-if="activity.shift">{{formatShiftTime(activity.shift.start_time)}} -
                                    {{ formatShiftTime(activity.shift.end_time)}} </p>
                                <p v-else> - </p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </base-material-card>
            </v-col>
            <v-col cols="6" md="6">
                <base-material-card>
                    <template v-slot:heading>
                        <v-row>
                            <v-col cols="4">
                                <div class="display-2 font-weight-light">
                                    Activity Location
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <gmap-map :center="center" :zoom="17" style="width:100%;  height: 400px;">
                                    <gmap-marker ref="myMarker" :position="marker" :draggable="false" />
                                </gmap-map>
                            </v-col>
                        </v-row>

                    </template>
                </base-material-card>
            </v-col>
        </v-row>

    </v-container>
    
    <v-dialog v-model="changing_dialog_box" max-width="290" max-height="290" hide-overlay color="white">
        <v-card elevation="0">
            <v-card-text>
                <h4 style="color: black;"> Are you sure you want to {{ attendance_status.status }} this activity</h4>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small @click="changing_dialog_box = !changing_dialog_box; is_changing=false">Cancel</v-btn>
                <v-btn small color="primary" :loading="is_changing" @click="changeStatus()">Change
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import axios from "axios";
import { mapActions } from 'vuex';
export default {

    data() {
        return {
            activity: {
                user: {}
            },
            center: {},
            marker: {},
            places: [],
            currentPlace: null,
            markedRadius: 150,
        // Attendance status change
            is_changing: false,
            changing_dialog_box: false,
            attendance_status: {
                id: [],
                status: '',
            },
            loader:{
                activty:false,
            }
        }
        
    },
    methods: {
        ...mapActions(['userAuth']),
        getactivity() {
           this.loader.activty= true;
            this.$axios
                .post(`${process.env.VUE_APP_API_URL}/api/activities/` + this.$route.params.id + `/detail`)
                .then(response => {
                    if (response.data.status == 1) {
                        this.loader.activty= false;
                        this.activity = response.data.activity;
                        this.geolocate();
                    }
                    if(response.data.status==0){
                        this.loader.activty= false;
                    }
                })
                .catch(error => {
                        this.loader.activty= false;
                });
        },
        attendanceStatus(status) {
            this.attendance_status.status = status;
            this.changing_dialog_box = true;
        },
        
        changeStatus() {
            this.is_changing = true;
            this.attendance_status.id= this.$route.params.id;
            axios.post(`${process.env.VUE_APP_API_URL}/api/activities/update-status`, this.attendance_status)
                .then(response => {
                    this.is_changing = false;
                    this.snackbar.message = response.data.message;
                    this.snackbar.display = true;
                    this.changing_dialog_box = false;
                    this.$router.push({ path: this.$router.currentRoute.fullPath });
                });
            this.is_changing = false;
            this.changing_dialog_box = false;
           this.$router.push({ path: this.$router.currentRoute.fullPath });
        },

        geolocate: function () {
            // navigator.geolocation.getCurrentPosition(position => {
                this.center = {
                    lat: this.activity.lat,
                    lng: this.activity.long,
                }
                this.marker = {
                    lat: this.activity.lat,
                    lng: this.activity.long,
                }
                this.marker.position = this.marker;

            // })
        },

    },
    mounted() {
        this.getactivity();
    }
}
</script>
