import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base:process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name:'login',
      component: require('./components/Login').default,
      meta: {
        forGuests: true
      }
    },
    {
      path: '/logout',
      name:'logout',
      component: require('./views/auth/Logout'),
    },

        {
          name: 'Dashboard',
          path: '/',
          component: require('./views/dashboard/Dashboard').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:true,
              href:'/'
            }
            ],
          }
        },
        {
          name: 'Employee Detail',
          path: '/employees/:id',
          component:  require('./views/dashboard/employee/Detail').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
              text: 'Employees',
              disabled: false,
              href: '/employees'
            },
            {
              text: 'Employee Details',
              disabled:true,
              href:'/employees/:id',
            }
            ],

          }
        },
        {
          name: 'Employees',
          path: '/employees',
          component:  require('./views/dashboard/employee/Index').default,
          meta: {
            forUsers: true,
            breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/'
            },
            {
              text: 'Employees',
              disabled: true,
              href: '/employees'
            },

          ],
          }
        },
        {
          name: 'Add Approvers',
          path: '/addApprover',
          component:  require('./views/dashboard/employee/Approver').default,
          meta: {
            forUsers: true,
            breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/'
            },
            {
              text: 'Employees',
              disabled: true,
              href: '/employees'
            },
            {
              text: 'Employees',
              disabled: true,
              href: '/addApprover'
            },

          ],
          }
        },
        {
          name: 'Add Employee',
          path: '/addEmployee',
          component: require('./views/dashboard/employee/EmployeeAdd').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
                text: 'Employees',
                disabled: false,
                href: '/employees'
            },
            {
                text: 'Add Employee',
                disabled: true,
                href: '/addEmployee'
            },

            ]
          }
        },
        {
          name: 'Edit Employee',
          path: '/employees/edit/:id',
          component: require('./views/dashboard/employee/Edit').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
                text: 'Employees',
                disabled: false,
                href: '/employees'
            },
            {
                text: 'Edit Employee',
                disabled: true,
            },

            ]
          }
        },
        {
          name: 'Change Shift',
          path: '/shift',
          component: require('./views/dashboard/employee/Shift').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
                text: 'Employees',
                disabled: false,
                href: '/employees'
            },
            {
                text: 'Shift',
                disabled: true,
            },

            ]
          }
        },
        {
          name: 'View Mapping',
          path: '/view-mapping',
          component: require('./views/dashboard/employee/Mapping').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
                text: 'Employees',
                disabled: false,
                href: '/employees'
            },
            {
                text: 'Mapping',
                disabled: true,
            },

            ]
          }
        },
        {
          name: 'Manage Shift',
          path: '/shift/manage',
          component: require('./views/dashboard/employee/ManageShift').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
                text: 'Employees',
                disabled: false,
                href: '/employees'
            },
            {
                text: 'Shift',
                disabled: true,
            },

            ]
          }
        },
        // Group Routes
        {
          name: 'Group Detail',
          path: '/groups/:id',
          component: require('./views/dashboard/groups/Detail').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
              text: 'Groups',
              disabled: false,
              href: '/groups'
            },
            {
              text: 'Group Details',
              disabled:true,
              href:'/groups/:id',
            }
            ],
          }
        },
        {
          name: 'Groups',
          path: '/groups',
          component: require('./views/dashboard/groups/Index').default,
          meta: {
            forUsers: true,
            breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/'
            },
            {
              text: 'Groups',
              disabled: true,
              href: '/groups'
            },

          ],
          }
        },
        {
          name: 'Add Group',
          path: '/addGroup',
          component: require('./views/dashboard/groups/Create').default,
          mets:{
            forUsers:true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
                text: 'Groups',
                disabled: false,
                href: '/groups'
            },
            {
                text: 'Add Group',
                disabled: true,
                href: '/addGroup'
            },

            ]
          }
        },
        {
          name: 'Edit Group',
          path: '/groups/edit/:id',
          component: require('./views/dashboard/groups/Edit').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
                text: 'Groups',
                disabled: false,
                href: '/groups'
            },
            {
                text: 'Edit Group',
                disabled: true,
                href: '/groups/edit/:id'
            },

            ]
          }
        },

        {
          name: 'Activities',
          path: '/activities',
          component: require('./views/dashboard/activities/Index').default,
          meta: {
            forUsers: true,
            breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/'
            },
            {
              text: 'Activities',
              disabled: true,
              href: '/activities'
            },

          ],
          }
        },
        {
          name: 'Activity Detail',
          path: '/activity-detail',
          component: require('./views/dashboard/activities/Detail').default,
          meta: {
            forUsers: true,
            breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/'
            },
            {
              text: 'Activities',
              disabled: true,
              href: '/activities'
            },

          ],
          }
        },
        {
          name: 'Add Activity',
          path: '/addActivity',
          component: require('./views/dashboard/activities/Create').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
                text: 'Activities',
                disabled: false,
                href: '/activities'
            },
            {
                text: 'Add Activity',
                disabled: true,
                href: '/addActivity'
            },

            ]
          }
        },
        {
          name: 'Activity Detail',
          path: '/activities/:id',
          component: require('./views/dashboard/activities/Detail').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
              text: 'Activities',
              disabled: false,
              href: '/activities'
            },
            {
              text: 'Activity Details',
              disabled:true,
              href:'/activities/:id',
            }
            ],
          }
        },

        {
          name: 'Registrations',
          path: '/registrations',
          component: require('./views/dashboard/registered-employees/RegisteredEmployeeList').default,
          meta: {
            forUsers: true,
            breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/'
            },
            {
              text: 'Registrations',
              disabled: true,
              href: '/registrations'
            },

          ],
          }
        },

        // roles
        {
          name: 'Roles',
          path: '/roles',
          component: require('./views/dashboard/role/rolesList').default,
          meta: {
            forUsers: true,
            breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/'
            },
            {
              text: 'Registrations',
              disabled: true,
              href: '/registrations'
            },

          ],
          }
        },


        // leaves
        {
          name: 'Leaves',
          path: '/leaves',
          component: require('./views/dashboard/leave/leavesList').default,
          meta: {
            forUsers: true,
            breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/'
            },
            {
              text: 'Leaves',
              disabled: true,
              href: '/leaves'
            },

          ],
          }
        },
        {
          name: 'Leave Detail',
          path: '/leave/:id',
          component: require('./views/dashboard/leave/Detail').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
              text: 'Leaves',
              disabled: false,
              href: '/leaves'
            },
            {
              text: 'Leave Details',
              disabled:true,
              href:'/leave/:id',
            }
            ],
          }
        },
        {
          name: 'Add Leave',
          path: '/addLeave',
          component: require('./views/dashboard/leave/leaveAdd').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
                text: 'Leaves',
                disabled: false,
                href: '/leaves'
            },
            {
                text: 'Add Leave',
                disabled: true,
                href: '/addALeave'
            },

            ]
          }
        },
        {
          name: 'Edit Leave',
          path: '/leaves/edit/:id',
          component: require('./views/dashboard/leave/Edit').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
                text: 'Leaves',
                disabled: false,
                href: '/leaves'
            },
            {
                text: 'Edit Leave',
                disabled: true,
                href: '/leaves/edit/:id'
            },

            ]
          }
        },
        {
          name: 'Approve Leaves',
          path: '/approveleaves',
          component: require('./views/dashboard/leave/ApproveLeaves').default,
          meta: {
            forUsers: true,
            breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/'
            },
            {
              text: 'Leaves',
              disabled: true,
              href: '/leaves'
            },
            {
              text: 'Approve Leave',
              disabled: true,
              href: '/approveleaves'
           },
          ],
          }
        },
        // holiday
        {
          name: 'Holidays',
          path: '/holidays',
          component: require('./views/dashboard/holiday/holidaysList').default,
          meta: {
            forUsers: true,
            breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/'
            },
            {
              text: 'Holidays',
              disabled: true,
              href: '/holidays'
            },

          ],
          }
        },
        {
          name: 'Holiday Detail',
          path: '/holiday-detail/:id',
          component: require('./views/dashboard/holiday/Detail').default,
          meta: {
            forUsers: true,
            breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/'
            },
            {
              text: 'Holidays',
              disabled: false,
              href: '/holidays'
            },
            {
              text: 'Holiday Detail ',
              disabled: true,
              href: '/holiday-detail'
            },
          ],
          }
        },
        {
          name: 'Add Holiday',
          path: '/addHoliday',
          component: require('./views/dashboard/holiday/holidayAdd').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
                text: 'Holidays',
                disabled: false,
                href: '/activities'
            },
            {
                text: 'Add Holiday',
                disabled: true,
                href: '/addHoliday'
            },

            ]
          }
        },
        {
          name: 'Edit Holiday',
          path: '/holidays/edit/:id',
          component: require('./views/dashboard/holiday/Edit').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
                text: 'Holidays',
                disabled: false,
                href: '/holidays'
            },
            {
                text: 'Add Holiday',
                disabled: true,
                href: '/holidays/edit/:id'
            },

            ]
          }
        },
        //Locations
        {
          name: 'Locations',
          path: '/locations',
          component: require('./views/dashboard/location/LocationList').default,
          meta: {
            forUsers: true,
            breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/'
            },
            {
              text: 'Locations',
              disabled: true,
              href: '/locations'
            },

          ],
          }
        },
        {
          name: 'Add Location',
          path: '/addLocation',
          component: require('./views/dashboard/location/LocationAdd').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
                text: 'Locations',
                disabled: false,
                href: '/locations'
            },
            {
                text: 'Add Location',
                disabled: true,
                href: '/addLocation'
            },

            ]
          }
        },
        {
          name: 'Edit Location',
          path: '/locations/edit/:id',
          component: require('./views/dashboard/location/Edit').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
                text: 'Locations',
                disabled: false,
                href: '/locations'
            },
            {
                text: 'Edit Location',
                disabled: true,
                href: '/locations/edit/:id'
            },

            ]
          }
        },

        {
          name: 'Location Detail',
          path: '/locations/:id',
          component: require('./views/dashboard/location/Location').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
              text: 'Locations',
              disabled: false,
              href: '/locations'
            },
            {
              text: 'Location Details',
              disabled:true,
              href:'/locations/:id',
            }
            ],
          }
        },

        {
          name: 'Notifications',
          path: '/notifications',
          component: require('./views/dashboard/notifications/NotificationsList').default,
          meta: {
            forUsers: true,
            breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/'
            },
            {
              text: 'Notifications',
              disabled: true,
              href: '/notifications'
            },

          ],
          }
        },
        {
          name: 'Create notifications',
          path: '/addNotification',
          component: require('./views/dashboard/notifications/NotificationAdd').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
                text: 'Notifications',
                disabled: false,
                href: '/notifications'
            },
            {
                text: 'Send Notification',
                disabled: true,
                href: '/addNotification'
            },

            ]
          }
        },
        {
          name: 'Notification',
          path: '/notification/:id',
          component: require('./views/dashboard/notifications/Notification').default,
          meta: {
            forUsers: true,
            breadcrumbs:[
            {
              text:'Dashboard',
              disabled:false,
              href:'/'
            },
            {
                text: 'Notifications',
                disabled: false,
                href: '/notifications'
            },
            {
                text: 'Notification Detail',
                disabled: true,
                href: '/notification/:id'
            },

            ]

          }
        },
        // Pages
        {
          name: 'User Profile',
          path: '/userProfile',
          component: require('./views/dashboard/employee/UserProfile').default,
          meta: {
            forUsers: true,
            breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/'
            },
            {
              text: 'User Profile',
              disabled: true,
              href: '/userProfile'
            },

          ],
          }
        },
        {
          name: 'Configurations',
          path: '/configurations',
          component: require('./views/dashboard/Configuration').default,
          meta: {
            forUsers: true,
            breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/'
            },
            {
              text: 'Configurations',
              disabled: true,
              href: '/configurations'
            },

          ],
          }
        },
        {
          name: 'Reports',
          path: '/reports',
          component: require('./views/dashboard/reports/Index').default,
          meta: {
            forUsers: true,
            breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/'
            },
            {
              text: 'Reports',
              disabled: true,
              href: '/reports/Index'
            },

          ],
          }
        },
        {
          name: 'Muster Report',
          path: '/musterReport',
          component: require('./views/dashboard/reports/MusterReport').default,

        },
        {
          name: 'Employee work hour Report',
          path: '/workhourReport',
          component: require('./views/dashboard/reports/WorkhourReport').default,

        },
        {
          name: 'Manual Attendance Report',
          path: '/ManulattendanceReport',
          component: require('./views/dashboard/reports/LateCheckInReport').default,
        },
        {
          name: 'Employee Leave Report',
          path: '/LeaveReport',
          component: require('./views/dashboard/reports/LeavesReport').default,
        },
    //Organization
    {
      name: 'Organization',
      path: '/organization',
      component: require('./views/dashboard/Organization/Index').default,
      meta: {
        forUsers: true,
        breadcrumbs: [
          {
            text: 'Dashboard',
            disabled: false,
            href: '/'
          },
          {
            text: 'Organization',
            disabled: true,
            href: '/organization'
          },

        ],
      }
    },
    {
      name: 'Add Organization',
      path: '/addOrganization',
      component: require('./views/dashboard/Organization/Create').default,
      meta: {
        forUsers: true,
        breadcrumbs:[
          {
            text:'Dashboard',
            disabled:false,
            href:'/'
          },
          {
            text: 'Organization',
            disabled: false,
            href: '/organization'
          },
          {
            text: 'Add Organization',
            disabled: true,
            href: '/addOrganization'
          },

        ]
      }
    },
    {
      name: 'Edit Organization',
      path: '/organization/edit/:id',
      component: require('./views/dashboard/Organization/Edit').default,
      meta: {
        forUsers: true,
        breadcrumbs:[
          {
            text:'Dashboard',
            disabled:false,
            href:'/'
          },
          {
            text: 'Organization',
            disabled: false,
            href: '/organization'
          },
          {
            text: 'Edit Organization',
            disabled: true,
          },

        ]
      }
    },
    {
      name: 'Organization Detail',
      path: '/organization/detail/:id',
      component: require('./views/dashboard/Organization/Detail').default,
      meta: {
        forUsers: true,
        breadcrumbs:[
          {
            text:'Dashboard',
            disabled:false,
            href:'/'
          },
          {
            text: 'Organization',
            disabled: false,
            href: '/organization'
          },
          {
            text: 'Organization Detail',
            disabled: true,
          },

        ]
      }
    },
    {
      name: 'Attendance',
      path: '/attendance',
      component: require('./views/dashboard/attendance/Index').default,
      meta: {
        forUsers: true,
        breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Attendance',
          disabled: true,
          href: '/attendance/Index'
        },

      ],
      }
    },
    {
      name: 'Add Attendance',
      path: '/create-attendance',
      component: require('./views/dashboard/attendance/Create').default,
      meta: {
        forUsers: true,
        breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Attendance',
          disabled: false,
          href: '/attendance/Index'
        },
        {
          text: 'Add Attendance',
          disabled: true,
          href: '/attendance/Create'
        },

      ],
      }
    },
    {
      name: 'Attendance Edit',
      path: '/attendance/:id',
      component: require('./views/dashboard/attendance/Edit').default,
      meta: {
        forUsers: true,
        breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Attendance',
          disabled: false,
          href: '/attendance'
        },
        {
          text: 'Attendance Edit',
          disabled: true,
          href: '/attendance/Edit'
        },
      ],
      }
    },
    {
      name: 'Attendance Detail',
      path: '/attendance/:id/detail',
      component: require('./views/dashboard/attendance/Detail').default,
      meta: {
        forUsers: true,
        breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Attendance',
          disabled: false,
          href: '/attendance'
        },
        {
          text: 'Attendance Detail',
          disabled: true,
          href: '/attendance/Detail'
        },
      ],
      }
    },
  ],
})
