import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    access_token: '',
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: null,
    permissions: [],
    user:{},
    organization: {},
  },
  getters: {
    get_access_token: (state) => state.access_token,
    get_permissions: (state) => state.permissions,
    get_user:(state) =>state.user,
    get_organization:(state) => state.organization
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    storeAccessToken(state, access_token) {
      state.access_token = access_token;
    },
    removeAccessToken(state) {
      state.access_token = '';
    },
    storePermissions(state, permissions) {
      state.permissions = permissions;
    },
    removePermissions(state) {
      state.permissions = [];
    },
    storeUser(state,user) {
      state.user = user;
    },
    removeUser(state) {
      state.user = {};
    },
    storeOrganization(state, organization) {
      state.organization = organization;
    }
  },
  actions: {
    storeUser({commit}, user) {
      return new Promise((resolve, reject) => {
          commit('storeUser', user);
          resolve();
      });
    },
    
    removeUser({commit}) {
      return new Promise((resolve, reject) => {

          commit('removeUser');
          resolve();
      });
    },
    
    storePermissions({commit}, permissions) {
      return new Promise((resolve, reject) => {

          commit('storePermissions', permissions);
          resolve();
      });
    },
    removePermissions({commit}) {
      return new Promise((resolve, reject) => {
        commit('removePermissions');
        resolve();
      });
    },
    storeAccessToken({commit}, access_token) {
      return new Promise((resolve, reject) => {
          commit('storeAccessToken', access_token);
          resolve();
      });
    },
    removeAccessToken({commit}) {
      return new Promise((resolve, reject) => {
          commit('removeAccessToken');
          resolve();
      });
    },
    storeOrganization({commit}, organization) {
      return new Promise((resolve, reject) => {
          commit('storeOrganization', organization);
          resolve();
      });
    },
    
   async userAuth() {
     /*
     const user = JSON.parse(localStorage.getItem('user'))
        if (user === null) {
          window.location.href = '/#/login'
        } else {
          const token = Promise.resolve(user.access_token)
          token.then(function(result) { console.log(result) })
          const access = axios.defaults.headers.common.Authorization = 'Bearer ' + user.access_token
          return access
        }
        */ 
    },
  },
  modules: {
  },
})
