<template>
<v-container id="user-profile" fluid tag="section" class="pa-1 full-width-div">
    <v-row>
        
    <v-snackbar :color="snackBar.color" :top="snackBar.top" :bottom="snackBar.bottom" :left="snackBar.left" :right="snackBar.right" v-model="snackBar.show">
        {{ snackBar.message }}
        <v-btn dark @click.native="snackBar.show = false">
            <v-icon>close</v-icon>
        </v-btn>
    </v-snackbar>
        <v-col cols="12" md="6">

            <base-material-card class="v-card-profile" :avatar="storage_path()+details.image.image">
                <v-card-text class="text-center">
                    <h6 class="display-1 mb-1 grey--text" v-if="details.designation">
                        {{details.designation.name}}
                    </h6>
                    <h6 class="display-1 mb-1 grey--text" v-else>
                        -
                    </h6>

                    <h4 class="display-2 font-weight-light mb-3 black--text">
                        {{details.name}} - {{details.employee_id}}
                    </h4>
                    <v-row class="mt-7">
                        <v-col cols="4" class="pt-0 pb-0 text-left">
                            <p class="font-weight-light grey--text">Email 1:</p>
                        </v-col>
                        <v-col cols="8" class="pt-0 pb-0 text-left">
                            <p v-if="details.emails.length>0"> 
                                {{details.emails[0].email}}
                            </p>
                        </v-col>
                        <v-col cols="4" class="pt-0 pb-0 text-left">
                            <p class="font-weight-light grey--text">Email 2:</p>
                        </v-col>
                        <v-col cols="8" class="pt-0 pb-0 text-left">
                            <p v-if="details.emails.length>1"> 
                                {{details.emails[1].email}}
                            </p>
                            <p v-else>-</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4" class="pt-0 pb-0 text-left">
                            <p class="font-weight-light grey--text">Office Phone :</p>
                        </v-col>
                        <v-col cols="8" class="pt-0 pb-0 text-left">
                            <p v-if="details.contacts.length>0">
                                {{details.contacts[0].contact}}
                            </p>
                            <p v-else>-</p>
                        </v-col>
                        <v-col cols="4" class="pt-0 pb-0 text-left">
                            <p class="font-weight-light grey--text">Personal Phone:</p>
                        </v-col>
                        <v-col cols="8" class="pt-0 pb-0 text-left">
                            <p v-if="details.contacts.length>1  ">
                                {{details.contacts[1].contact}}
                            </p>
                            <p v-else>-</p>
                        </v-col>
                        <v-col cols="4" class="pt-0 pb-0 text-left">
                            <p class="font-weight-light grey--text">Role:</p>
                        </v-col>
                        <v-col cols="8" class="pt-0 pb-0 text-left">
                            <p v-if="details.roles[0]">
                                {{details.roles[0].display_name}}
                            </p>
                            <p v-else>-</p>
                        </v-col>
                    </v-row>

                    <!-- <p class="font-weight-light grey--text" v-if="details.contacts">
     {{details.contacts[0].contact}}
    </p>
    <p class="font-weight-light grey--text" v-if="details.base_location">
     {{details.base_location.name}}
    </p> -->

                    <v-btn color="success" rounded class="mr-0 mt-7" text v-if="details.is_active">
                        Active
                    </v-btn>
                    <v-btn color="warning" rounded class="mr-0 mt-7" text v-else>
                        InActive
                    </v-btn>
                </v-card-text>
            </base-material-card>
            <v-row>
                <v-col cols="12" md="12">
                    <base-material-card>
                        <template v-slot:heading>
                            <v-row class="py-0">
                                <v-col cols="10" class="py-0">
                                    <h3>Other Details</h3>
                                </v-col>
                            </v-row>

                        </template>
                        <v-card-text>
                            <v-row>
                                <v-col cols="6" class="pt-0 pb-0">
                                    <p class="font-weight-light grey--text">Gender:</p>
                                </v-col>
                                <v-col cols="6" class="pt-0 pb-0" v-if="details.gender">
                                    <p>{{details.gender}}</p>
                                </v-col>
                                <v-col cols="6" class="pt-0 pb-0" v-else>-</v-col>

                                <v-col cols="6" class="pt-0 pb-0">
                                    <p class="font-weight-light grey--text">Landline:</p>
                                </v-col>
                                <v-col cols="6" class="pt-0 pb-0">
                                    <p>-</p>
                                </v-col>

                                <v-col cols="6" class="pt-0 pb-0">
                                    <p class="font-weight-light grey--text">Location:</p>
                                </v-col>
                                <v-col cols="6" class="pt-0 pb-0" v-if="details.base_location">
                                    <p>{{details.base_location.name}}</p>
                                </v-col>
                                <v-col cols="6" class="pt-0 pb-0" v-else>-</v-col>

                                <v-col cols="6" class="pt-0 pb-0">
                                    <p class="font-weight-light grey--text">Division:</p>
                                </v-col>
                                <v-col cols="6" class="pt-0 pb-0" v-if="details.department">
                                    <p>{{details.department.name}}</p>
                                </v-col>
                                <v-col cols="6" class="pt-0 pb-0" v-else>-</v-col>

                                <v-col cols="6" class="pt-0 pb-0">
                                    <p class="font-weight-light grey--text">Date Of Joining:</p>
                                </v-col>
                                <v-col cols="6" class="pt-0 pb-0" v-if="details.date_of_joining">
                                    <p>{{formatDate(details.date_of_joining)}}</p>
                                </v-col>
                                <v-col cols="6" class="pt-0 pb-0" v-else>-</v-col>

                                <v-col cols="6" class="pt-0 pb-0">
                                    <p class="font-weight-light grey--text">Date Of Birth:</p>
                                </v-col>
                                <v-col cols="6" class="pt-0 pb-0" v-if="details.date_of_birth">
                                    <p>{{formatDate(details.date_of_birth)}}</p>
                                </v-col>
                                <v-col cols="6" class="pt-0 pb-0" v-else>-</v-col>

                                <v-col cols="6" class="pt-0 pb-0">
                                    <p class="font-weight-light grey--text">Probation End Date:</p>
                                </v-col>
                                <v-col cols="6" class="pt-0 pb-0" v-if="details.probation_end_date">
                                    <p>{{formatDate(details.probation_end_date)}}</p>
                                </v-col>
                                <v-col cols="6" class="pt-0 pb-0" v-else>-</v-col>
                                
                            </v-row>
                        </v-card-text>
                    </base-material-card>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" md="6">
            <!-- <v-row>
                <v-col cols="12" md="12">
                    <base-material-card>
                        <template v-slot:heading>
                            <v-row class="py-0">
                                <v-col cols="11" class="py-0">
                                    <h3>Leave Summary</h3>
                                </v-col>
                                <v-col cols="1" class="py-0">
                                    <v-btn class="text-right" icon @click="addLeaveDialog()">
                                        <v-icon large color="white lighten-1">mdi-pencil</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>

                        </template>
                        <v-card-text>
                            <v-row>
                                <v-col cols="3" class="pt-0 pb-0">
                                    <p class="font-weight-light grey--text">Leaves Balance:</p>
                                </v-col>
                                <v-col cols="3" class="pt-0 pb-0">
                                    <p>{{leave_count}}</p>
                                </v-col>
                                <v-col cols="3" class="pt-0 pb-0" >-</v-col>

                                <v-col cols="3" class="pt-0 pb-0">
                                    <p class="font-weight-light grey--text">Comp Off :</p>
                                </v-col>
                                <v-col cols="3" class="pt-0 pb-0">
                                    <p>-</p>
                                </v-col>
                                <v-col cols="3" class="pt-0 pb-0" >-</v-col>

                            </v-row>
                        </v-card-text>
                    </base-material-card>
                </v-col>
            </v-row> -->
            <v-row>
                  <!-- <v-col cols="12" md="12" v-if="role=='admin'">
                    <base-material-card>
                        <template v-slot:heading>
                            <v-row class="py-0">
                                <v-col cols="10" class="py-0">
                                    <h3>Update Password</h3>
                                </v-col>
                            </v-row>

                        </template>
                        <v-form @submit.prevent="validateBeforeSubmit('changePassword')" data-vv-scope="changePassword" id="changePassword" ref="update_form">
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" class="pt-0 pb-0">
                                        <label>Old Password <span style="color:red">*</span></label>
                                        <v-text-field name="Password" type="password" v-model="user_object.old_password" v-validate="'required'" :error-messages="errors.collect('changePassword.Password')"></v-text-field>

                                    </v-col>

                                    <v-col cols="12" class="pt-0 pb-0">
                                        <label>New Password <span style="color:red">*</span></label>
                                        <v-text-field name="New Password" type="password" v-model="user_object.new_password" autocomplete="off"></v-text-field>

                                    </v-col>
                                    <v-col cols="12">
                                        <label>Confirm Password <span style="color:red">*</span></label>
                                        <v-text-field name="Confirm Password" type="password" v-model="confirm_password" v-validate="'required'" :error-messages="errors.collect('changePassword.Confirm Password')" :disabled="user_object.new_password == ''" :rules="[() => (confirm_password != '' && (user_object.new_password == confirm_password)) || 'Password does not Match']"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">

                                        <v-btn class="my-4" color="primary" type="submit" :loading="password_loader" :disabled="password_loader">Update</v-btn>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                        </v-form>
                    </base-material-card>
                </v-col> -->
                <v-col cols="12" md="12">
                    <base-material-card>
                        <template v-slot:heading>
                            <v-row class="py-0">
                                <v-col cols="10" class="py-0">
                                    <h3>Recent Activities</h3>
                                </v-col>
                            </v-row>

                        </template>

                        <v-card-text>
                            <v-list class="pt-0 activity" dense style="max-height: 100vh; overflow-y:auto;">

                                <v-list-item v-for="activity in recent_activity_list" :key="activity.id" class="pt-2">
                                    <v-list-item-avatar v-if="activity.image">
                                        <img :src="storage_path()+activity.image.image" 
                                        @click="fullWidthImage(activity.image.image)" @error="setAltImg" >
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>{{formatDateTime(activity.datetime)}}</v-list-item-title>
                                        <v-list-item-subtitle v-if="activity.location">{{activity.location.name}}</v-list-item-subtitle>
                                        <v-list-item-subtitle v-else>Unauthorized Location</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-chip color="#0392524a" text-color="success" v-if="activity.type=='check_in'">{{ toTitleCase(activity.type).replace(/_/g, " ")}}</v-chip>
                                        <v-chip color="#ff52523d" text-color="error" v-else>{{ toTitleCase(activity.type).replace(/_/g, " ")}}</v-chip>
                                    </v-list-item-action>

                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </base-material-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <base-material-card>
                        <template v-slot:heading>
                            <v-row class="py-0">
                                <v-col cols="11" class="py-0">
                                    <h3>Assigned Locations</h3>
                                </v-col>
                                <v-col cols="1" class="py-0">
                                    <v-btn class="text-right" icon @click="assignLocationDialog()">
                                        <v-icon large color="white lighten-1">mdi-plus-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>

                        </template>
                        <v-card-text>
                            <v-list style="max-height: 100vh; overflow-y:auto;" dense>

                                <v-list-item v-for="location in user_location_list" :key="location.id" class="pt-2">

                                    <v-list-item-action>

                                        <v-icon large>mdi-map</v-icon>

                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="location.name"></v-list-item-title>

                                        <v-list-item-subtitle v-text="location.address"></v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon @click="remove_location_box= !remove_location_box; selected_location_id=location.id">
                                            <v-icon large color="red lighten-1">mdi-close</v-icon>
                                        </v-btn>
                                    </v-list-item-action>

                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </base-material-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row pt="0">

    </v-row>
    <!-- Assign Location Box -->
    <v-dialog v-model="assign_location_box" scrollable :overlay="false" max-width="600" transition="dialog-transition">
        <v-card style="height:100%;">
            <v-form id="assignLocations" @submit.prevent="validateBeforeSubmit('assignLocations')" data-vv-scope="assignLocations" v-model="add_location_valid">
                <v-card-text>
                    <v-card-title primary-title>
                        <span class="headline">Assign Location To - {{details.name}}</span>
                    </v-card-title>
                    <div class="pa-3">
                        <v-autocomplete :items="search_location_list" :loading="location_search_loader" :search-input.sync="location_search_query" v-model="location_search_select" class="hidden-sm-and-down" label="Search" item-text="name" return-object :filter="v => v">
                            <template v-slot:item="{ item }">

                                <v-list-item-content>
                                    <v-list-item-title v-text="item.name"></v-list-item-title>
                                    <v-list-item-subtitle v-text="item.address"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                        <v-layout row wrap>
                            <v-flex xs12 class="pl-2">
                                <span class="subheading">Selected Locations : </span>
                            </v-flex>
                            <v-flex xs12>
                                <v-chip v-for="u in selected_location_list" :key="u.name" close @click:close="removeChip(u)">
                                    {{u.name}}
                                </v-chip>
                            </v-flex>
                        </v-layout>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="assign_location_box = false" :disabled="add_location_loader">Cancel</v-btn>
                    <!-- <v-btn  color="error" @click="resetFilters">Reset</v-btn> -->
                    <v-btn small color="primary" type="submit" :disabled="add_location_loader || !add_location_valid || selected_location_list.length==0" :loading="add_location_loader">Add</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <!-- Remove Location Dialog Box -->
    <v-dialog v-model="remove_location_box" scrollable :overlay="false" max-width="650px" transition="dialog-transition">
        <v-card>
            <v-form>
                <v-card-text>
                    <v-card-title primary-title>
                        <span class="headline">Are you sure you wish to remove this Location ?</span>
                    </v-card-title>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small color="black" @click="remove_location_box=false">Cancel</v-btn>
                    <v-btn small color="primary" @click="removeLocation">OK</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <!-- Image dialog box -->
    <v-dialog v-model="image_dialog_box" max-width="290" max-height="290" hide-overlay color="white">
        <v-card elevation="0">
            <v-img :src="storage_path()+image_path"></v-img>
        </v-card>
    </v-dialog>

    <!-- add Leave Dialog Box -->
    <v-dialog v-model="add_leave_dialog_box" scrollable :overlay="false" max-width="500px" transition="dialog-transition">
        <v-card>
            <v-form id="updateLeaveCount" @submit.prevent="validateBeforeSubmit('updateLeaveCount')" data-vv-scope="updateLeaveCount" ref="updateLeaveCount">
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="12">
                            <label>Available Leaves </label>
                            <v-text-field v-model="leave_count" class="purple-input" name="Name" />

                        </v-col>

                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" @click="add_leave_dialog_box=false">Cancel</v-btn>
                    <v-btn color="success" type="submit">
                        Add
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</v-container>
</template>

 
<script>
/* eslint-disable */

import { mapActions } from 'vuex'

export default {
    watch: {
        location_search_query: {
            handler(val) {
                this.search_location_list = [];
                clearInterval(this.search_timer);
                if (val) {
                    this.search_timer = setTimeout(() => {
                        this.getLocationList(val);
                    }, 500);
                }
            },
        },
        location_search_select: {
            handler(val) {
                if (val) {
                    let v = val;
                    let i = this.selected_location_list.findIndex((location) => {
                        return location.id == v.id;
                    });
                    if (i == -1) {
                        this.selected_location_list.push(val);
                        this.location_search_query = "";
                    }
                }
            }
        },
    },

    methods: {
        
        changePassword: function () {
            this.password_loader = true;

            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/changePassword`, this.user_object)
            .then((response) => {

               
                    this.snackBar.show = true;
                    this.snackBar.message = response.data.message;
               

                // this.$router.push({ name: 'showUser', params: {id: response.data.user_id}});

            }).catch((error) => {

            }).finally(() => {
                this.password_loader = false;
            });
        },
        getDetails() {

            this.$axios.get(
                    `${process.env.VUE_APP_API_URL}/api/employees/` +
                    this.$route.params.id
                )
                .then(response => {
                    if (response.data.status == 1) {

                        this.details = response.data.employee;
                        this.user_object.id = this.details.id;
                        this.leave_count = response.data.leave_count;
                        this.getUserLocationList(this.$route.params.id);

                    }
                })
                .catch(error => {

                });
        },
        removeChip(location) {

            this.selected_location_list.splice(this.selected_location_list.indexOf(location), 1)
            this.selected_location_list = [...this.selected_location_list]

        },
        assignLocationDialog() {
            // this.add_user_group = Object.assign({}, group);
            this.selected_location_list = [];
            this.assign_location_box = true;
        },
        getLocationList(val) {

            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/locations/locationlist`, { params: { location: val } })
                .then(response => {

                    if (response.data.status == 1) {

                        this.search_location_list = response.data.location_list;

                    }
                })
                .catch(error => {

                });
        },
        getUserLocationList(user_id) {
            this.user_id = user_id;
            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/user/` + user_id + `/locations`)
                .then(response => {

                    if (response.data.status == 1) {

                        this.user_location_list = response.data.locations;

                    }
                })
                .catch(error => {

                });
        },
        getRecentActivities() {

            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/activities/recentactivities/` + this.$route.params.id)
                .then(response => {

                    if (response.data.status == 1) {

                        this.recent_activity_list = response.data.recent_activity_list;

                    }
                })
                .catch(error => {

                });
        },
        assignLocations() {
            this.add_location_loader = true;
            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/locations/assignLocation/${this.$route.params.id}`, { locations: this.selected_location_list })
                .then((response) => {
                    if (response.data.status == 1) {
                        this.assign_location_box = false;
                        this.getDetails();
                        this.add_location_loader = false;
                        this.getUserLocationList(this.user_id);

                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.add_location_loader = false;
                })
                .finally(() => {

                });
        },
        removeLocation() {
            this.remove_location_box = true;
            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/locations/removeLocation/` + this.selected_location_id, { user_id: this.$route.params.id })
                .then((response) => {
                    if (response.data.status == 1) {
                        this.remove_location_box = false;
                        this.getDetails();

                    }
                })
                .catch((error) => {
                    console.log(error);

                })
                .finally(() => {

                });

        },
        addLeaveDialog() {
            this.add_leave_dialog_box = true;
        },
        updateLeaveCount() {
            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/leaves/updateLeaveCount/` + this.$route.params.id, { leave_count: this.leave_count })
                .then((response) => {
                    if (response.data.status == 1) {
                        this.add_leave_dialog_box = false;
                    }
                })
                .catch((error) => {
                    console.log(error);

                })
                .finally(() => {

                });
        },
        validateBeforeSubmit: function (scope) {
            this.$validator.validateAll(scope).then(result => {
                if (result) {
                    this[scope]();
                }
            });
        },
        fullWidthImage(item_image) {

            this.image_dialog_box = true;
            this.image_path = item_image;

        },
    },
    data() {
        return {

            details: {
                image: {},
                emails: [{}],
                contacts: [{}],
                roles: [{}],
               
            },
            image_path: '',
            image_dialog_box: false,
            add_leave_dialog_box: false,
            leave_count: "",
            remove_location_box: false,
            search_location_list: [],
            location_search_loader: false,
            location_search_query: "",
            user_location_list: [],
            location_search_select: {},
            recent_activity_list: [],
            approveal: '',
            image: '',
            formErrors: '',
            assign_location_box: false,
            location_list: [],
            location_to_add_list: [],
            selected_location_list: [],
            add_location_valid: false,
            add_location_loader: false,
            search_timer: {},
            selected_location_id: "",
            user_id: "",


            confirm_password: '',
            user_object: {
                old_password: "",
                new_password: "",
                id:'',
            },
            password_loader: false,

             snackBar: {
                show: false,
                color: "",
                message: "",
                top: true,
                right: true,
                bottom: false,
                left: false
            },

            auth_user:'',
            role:'',
        }
    },
    mounted() {
        
        let auth_user = this.$store.getters.get_user;
        this.role = auth_user.roles[0].name;

        this.getDetails();
        // this.getLocationList();
        this.getRecentActivities();
        // this.getUserLocationList();
    }
};
</script>
