<template>
  <v-container id="regular-tables"  fluid tag="section"  class="pa-1 full-width-div">
    <v-snackbar
      v-model="snackbar.display"
      :color="snackbar.color"
      :top="snackbar.top"
      :bottom="snackbar.bottom"
      :left="snackbar.left"
      :right="snackbar.right"
    >
      {{ snackbar.message }}
    </v-snackbar>
    <v-row>
      <v-flex xs12 class="buttons">
        <v-btn
          color="success"
          class="mr-0"
          to="addorganization"
          v-if="auth_user_role=='superadmin'"> Add organization
        </v-btn>
        <v-btn class="ml-5" color="success" @click="filter_dialog_box = !filter_dialog_box" >
          Filters
        </v-btn>
      </v-flex>
    </v-row>
    <base-material-card
      icon="mdi-clipboard-text"
      title="Organization"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="organization"
        item-key="id"
        :sort-by="['name']"
        :sort-desc="[true]"
        :options.sync="options"
        :server-items-length="total_items"
        :loading="is_loading"
        no-results-text="Loading... Please wait"

      >
        <template v-slot:progress>
          <v-progress-linear color="success" indeterminate></v-progress-linear>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
          <tr v-for="item in items" :key="item.id">
            <td>
              <v-avatar size="36">
                <img :src="storage_path()+item.logo_path" @click="fullWidthImage(item.image.image)"  
                 @error="setAltImg">
              </v-avatar>
            </td>
            <td v-if="item.name">
            <router-link v-if="item.id" class="ml-1" 
            :to="{ name: 'Organization Detail', params: { id: item.id } }">
            {{ item.name }}
            </router-link>

              </td><td v-else>-</td>
            <td v-if="item.code"> {{ item.code }}</td><td v-else>-</td>
            <td v-if="item.user_count"> {{ item.user_count }}</td><td v-else>0</td>
            <td v-if="item.organization_domain"> {{ item.organization_domain }}</td><td v-else>-</td>
            <td v-if="item.frontend_url"> {{ item.frontend_url }}</td><td v-else>-</td>
            <td v-if="!is_loading" style="width: 94px;padding: 0px;"><v-btn
              :disabled="!checkPermission('update-organization')"
              text
              icon
              large
              color="blue lighten-2"
              :to="`organization/edit/${item.id}`"
            >
              <v-icon large>mdi-pencil</v-icon>
            </v-btn>

            <v-btn
              :disabled="!checkPermission('delete-organization')"
              text
              icon
              large
              color="red lighten-2"
              @click="delete_organization_box= !delete_organization_box; selected_org_id=item.id ">
              <v-icon large>mdi-delete</v-icon>
            </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
        <template v-slot:no-data-text>
          <v-layout row wrap justify-center class="text-xs-center" v-if="is_loading">
            Loading... Please wait
          </v-layout>
          <v-layout row wrap justify-center class="text-xs-center" v-else>
            No data available
          </v-layout>
        </template>
      </v-data-table>


    </base-material-card>

    <v-dialog
      v-model="image_dialog_box"
      max-width="290"
      max-height="290"
      hide-overlay
      color="white"
    >
      <v-card elevation="0">
        <v-img :src="storage_path()+image_path"></v-img>
      </v-card>
    </v-dialog>


    <!-- filter dialog box -->
    <v-dialog
      v-model="filter_dialog_box"
      scrollable
      :overlay="false"
      max-width="450px"
      transition="dialog-transition"
      hide-overlay
    >
      <v-card style="height:100%">
        <v-form
          id="filterList" @submit.prevent="validateBeforeSubmit('filterList')" data-vv-scope="filterList"
          v-model="valid"
        >
          <v-card-text>
            <v-card-title primary-title>
              <span class="headline">Filters</span><br><br>
            </v-card-title>
            <v-container fluid grid-list-md style="padding: 0 16px 16px 16px">
              <v-layout row wrap class="field_wrapper">
                <v-flex xs12 md12>
                  <label>Organization Name</label>
                  <br>
                  <v-text-field
                    name="username"
                    id="username"
                    type="text"
                    v-model="filters.name"
                    placeholder="Enter Organization Name"
                  ></v-text-field>

                </v-flex>
                <v-flex xs12 md12>
                  <label>organization code</label>
                  <br>
                  <v-text-field
                    name="username"
                    id="username"
                    type="text"
                    v-model="filters.code"
                    placeholder="Enter Organization Code"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small @click="filter_dialog_box = !filter_dialog_box">Cancel</v-btn>
            <v-btn small color="error" @click="resetFilters">Reset</v-btn>
            <v-btn small color="primary" :disabled="!valid" type="submit">Apply</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Delete organization Dialog Box -->
    <v-dialog
      v-model="delete_organization_box"
      scrollable
      :overlay="false"
      max-width="450px"
      transition="dialog-transition"
      hide-overlay
    >
      <v-card>
        <v-form>

          <v-card-title primary-title>
            <span>Are you sure you wish to delete this organization ?</span>
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small @click="delete_organization_box=false">Cancel</v-btn>
            <v-btn small color="primary" @click="deleteorganization" :loading="delete_loader">OK</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import axios from 'axios'
import {mapActions} from 'vuex'

export default {
  watch: {
    options: {
      handler() {
        this.getList();
      }
    },

  },
  data() {
    return {
      organization: [],
      delete_loader:false,
      showModal: false,
      image_dialog_box: false,
      image_path: "",
      total_items: 10,
      page: 0,
      selected_org_id:'',
      options: {},
      filters: {
        name: "",
        code: "",
      },
      snackbar:{
        message:"",
        display:false,
        color: "",
        top: true,
        right: true,
        bottom: false,
        left: false
      },
      is_filtered: false,
      filter_dialog_box: false,
      filter_chips: {
        name: "",
        code: "",
      },
      valid: false,
      headers: [
        {text: "Image", value: "logo_path", align: "left", sortable: false},
        {text: "Organization Name", value: "name", align: "left",sortable: false},
        {text: "Code", value: "code", align: "left", sortable: false},
        {text: "Emp. Count", value: "user_count", align: "left", sortable: false},
        {text: "Domain", value: "organization_domain", align: "left", sortable: false},
        {text: "URL", value: "url", align: "left", sortable: false},
        {text: "Action"}
      ],
      employee_list: [],
      delete_organization_box: false,
      selected_organization_id: "",
      is_loading: false,
      auth_user_role: "",
      auth_user: {},

    }
  },
  methods: {
    ...mapActions(['userAuth']),
    getList() {
      this.is_loading = true;

      this.options.filters = Object.assign({}, this.filters);
      axios.get(`${process.env.VUE_APP_API_URL}/api/organization`, {
        params: this.options
      }).then((response) => {
        // console.log(response.data.organization.data)
        if (response.data.status == 1) {
          this.organization = response.data.organization.data;
          this.page = response.data.organization.page;
          this.total_items = response.data.organization.total;
          this.is_loading = false;
        }
      }).catch((error) => {

        console.log(error)
      })
    },
    deleteorganization:function()
    {
      this.delete_loader=true;
      this.$axios.post(`${process.env.VUE_APP_API_URL}/api/organization/` + this.selected_org_id + `/delete`)
        .then((response)=>{
          this.delete_loader=false;
        if(response.data.status ==1){
          this.delete_organization_box= false;
          this.snackbar.message = response.data.message;
          this.snackbar.display =true;
          this.getList();
        }
        else if(response.data.status == 2){
          this.delete_organization_box= false;
          this.snackbar.message = response.data.message;
          this.snackbar.display =true;
        }
      })
        .catch(error => {
          this.delete_organization_box= false;
          this.snackbar.message = "Error Occured";
          this.snackbar.display =true;
        });
    },
    filterList() {

      let filter_values = Object.values(this.filters);
      if (filter_values.join('').length > 0) {
        this.$router.replace({
          name: "Organization",
          query: {
            filters: true,
            name: this.filters.name,
            code: this.filters.code,
          }
        });
        this.displayFilterChips(this.filters);
        this.is_filtered = true;
      } else {
        this.$router.replace({
          name: "Organization"
        });
      }
      this.getList();
      this.filter_dialog_box = false;
    },
    resetFilters() {
      this.$router.replace({
        name: "Organization",
        query: {}
      });
      this.resetFiltersParameters();
      this.is_filtered = false;
      this.getList();
      this.filter_dialog_box = false;
    },
    resetFiltersParameters: function () {
      for (var key in this.filters) {
        this.filters[key] = "";
      }
    },
    displayFilterChips: function (filters) {

      if (this.$route.query.filters) {

        this.filter_chips.name = this.filters.name;
        this.filter_chips.code = this.filters.code;
        this.is_filtered = true;

      }
    },
    clearFilter: function (filter, key) {
      this.filter_chips[key] = this.filters[key] = '';
      this.filterList();
    },
    applyFiltersOnLoad: function () {
      this.filters.name = this.$route.query.name;
      this.filters.code = this.$route.query.code;
      this.displayFilterChips(this.filters)
    },
    validateBeforeSubmit: function (scope) {
      this.$validator.validateAll(scope).then(result => {
        if (result) {
          this[scope]();
        }
      });
    },

    fullWidthImage(item_image) {
      this.image_dialog_box = true;
      this.image_path = item_image;
    },
  },
  mounted() {
    this.applyFiltersOnLoad();
    this.auth_user = this.$store.getters.get_user;

    if (this.auth_user) {
      this.auth_user_role = this.auth_user.roles[0].name

    }
  }
};
</script>
