<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
    class="pa-1 full-width-div"
    
  >
   <v-row>
    <v-flex xs12 class="buttons">
  <v-btn
      color="success"
      class="mr-0"
      to="addNotification"
      v-if="checkPermission('create-holidays')"
    >
      Create Notification
    </v-btn></v-flex></v-row>
  <base-material-card
      icon="mdi-clipboard-text"
      title="Notifications"
      class="px-5 py-3"
    >
     

      <v-data-table
        :headers="headers"
        :items="notifications"
        item-key="id"
        :sort-by="['created_at']"
        :sort-desc="[true]"
        :loading="is_loading"
        :options.sync="options" 
        :server-items-length="total_items" 
        
        no-results-text="Loading... Please wait"
        
      >
      <template v-slot:progress>
        <v-progress-linear color="success" indeterminate></v-progress-linear>
      </template>
        <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="item in items"
            :key="item.name"
          >
            <td>{{item.title }}</td>
            <td>{{item.type}}</td>
            <td>{{item.description}}</td>
            <td>{{formatDateTime(item.created_at)}}</td>
            
            <!-- <td><v-btn
              :disabled="!checkPermission('update-notifications')"
              text
              icon
              large
              color="blue lighten-2"
              :to="`notifications/edit/${item.id}`"
              >
              <v-icon large>mdi-pencil</v-icon>
            </v-btn>
          </td> -->
            
          </tr>
        </tbody>
      </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
  
  import { mapActions } from 'vuex'
  export default {
    name: 'Notifications',
    watch: {
        options: {
            handler() {
                this.getList();
            }
        },

    },
    data() {
      return {
        
        notifications: [],
        is_loading:false,
        total_items:10,
        page:0,
        options:{},
        headers:[{ text: "Title", value: "title", align: "left", sortable: false},
        { text: "Type", value: "type", align: "left" },
         { text: "Description", value: "description", align: "left" },
        { text: "Scheduled At", value: "created_at", align: "left", sortable: false },
        
        ],
      }
    },
    created() {
      
    },
    methods: {
      
      getList() {
        this.is_loading=true;
        
        this.$axios.get(`${process.env.VUE_APP_API_URL}/api/notifications`,{params: this.options}).then((response) => {
          if(response.data.status == 1){
            this.notifications = response.data.notifications.data;
            
            this.total_items= response.data.notifications.total;
            this.is_loading =false;
          }
          
          
        }).catch((error) => {
         
          console.log(error)
        })
      },
      
    },
  };
</script>
