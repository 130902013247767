<template>
<div>
    <!-- snackbar -->
    <v-snackbar v-model="snackbar.display" :color="snackbar.color" :top="snackbar.top" :bottom="snackbar.bottom" :left="snackbar.left" :right="snackbar.right">
        {{ snackbar.message }}
    </v-snackbar>
    <!-- loader -->
    <div v-if="loader.attendance" style="position:absolute; top:0; right:0; bottom:0; left:0; padding-top: 20%; padding-left: 45%;background-color:rgba(255,255,255,0.31)">
        <div class="text-xs-center">
            <v-progress-circular color="success" class="text-xs-center my-5" :size="100" :width="10" indeterminate></v-progress-circular>
        </div>
    </div>
    <!-- details -->
    <v-container id="user-profile" fluid tag="section" class="pa-1 full-width-div" v-else>
        <v-row justify="center">
            <v-col cols="6" md="6">
                <base-material-card title="Attendance Detail">
                    <v-card-text>
                        <v-row class="mb-3">
                            <v-col cols="4" class="pt-0 pb-0">
                                <p class="font-weight-light grey--text">Employee</p>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0" v-if="attendance.user">
                                <p>{{attendance.user.name}}</p>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0" v-else>-</v-col>

                             <v-col cols="4" class="pt-0 pb-0">
                                <p class="font-weight-light grey--text">Employee ID</p>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0" v-if="attendance.user">
                                <p>{{attendance.user.employee_id}}</p>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0" v-else>-</v-col>

                            <hr>

                            <v-col cols="4" class="pt-0 pb-0">
                                <p class="font-weight-light grey--text">Date</p>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0" v-if="attendance">
                                <p>{{attendance.date}}</p>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0" v-else>-</v-col>


                             <v-col cols="4" class="pt-0 pb-0">
                                <p class="font-weight-light grey--text">First Check-in At</p>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0" v-if="attendance.first_check_in">
                                <p>{{formatTime(attendance.first_check_in)}}</p>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0" v-else>-</v-col>

                            <v-col cols="4" class="pt-0 pb-0">
                                <p class="font-weight-light grey--text">Last Check-out At</p>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0" v-if="attendance.last_check_out">
                                <p>{{formatTime(attendance.last_check_out)}}</p>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0" v-else>-</v-col>


                             <v-col cols="4" class="pt-0 pb-0">
                                <p class="font-weight-light grey--text">Total Duration</p>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0" v-if="attendance.total_duration">
                                <p>{{attendance.total_duration}}</p>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0" v-else>-</v-col>

                             <v-col cols="4" class="pt-0 pb-0">
                                <p class="font-weight-light grey--text">Reconciled</p>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0" v-if="attendance.is_reconsile==1">
                                <p>Yes</p>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0" v-else>No</v-col>

                            <v-col cols="4" class="pt-0 pb-0">
                                <p class="font-weight-light grey--text">Reconciled By</p>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0" v-if="attendance.reconciled_by">
                                <p>{{ attendance.reconciled_by.name}}</p>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0" v-else>-</v-col>

                            
                             <v-col cols="4" class="pt-0 pb-0">
                                <p class="font-weight-light grey--text">Shift</p>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0" v-if="attendance.shift">
                                <p>{{formatShiftTime(attendance.shift.start_time)}}-  {{formatShiftTime(attendance.shift.end_time)}}</p>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0" v-else>-</v-col>
                        </v-row>
                    </v-card-text>
                </base-material-card>
            </v-col>
            <v-col cols="6" md="6">
                <base-material-card title="Activity Detail">
                    <v-card-text class="text-center">
                        <v-row class="mb-3">
                            <v-row>
                                <v-col cols="8">
                                <v-card-text>
                                    <v-list class="pt-0 activity" dense style="max-height: 100vh; overflow-y:auto;">

                                        <v-list-item v-for="activity in activity" :key="activity.id" class="pt-2">
                                            <v-list-item-avatar v-if="activity.image">
                                                <img :src="storage_path()+activity.image.image" @click="fullWidthImage(activity.image.image)">
                                            </v-list-item-avatar>
                                            <v-list-item-avatar v-else>
                                                -
                                            </v-list-item-avatar>
                                            <v-list-item-content style="text-align: left">
                                                <v-list-item-title class="mb-1">{{formatDateTime(activity.datetime)}}</v-list-item-title>
                                                <v-list-item-subtitle class="mb-4">{{activity.location_status}}</v-list-item-subtitle>
                                                
                                                <!-- <v-list-item-subtitle v-if="activity.address!=null">{{activity.address}}</v-list-item-subtitle>
                                                <v-list-item-subtitle v-else>-</v-list-item-subtitle>
                                                <br> -->
                                                <router-link v-if="activity.id" class="ml-1" :to="{ name: 'Activity Detail', params: { id: activity.id } }">
                                                 <v-list-item-title>View Detail</v-list-item-title>
                                                </router-link>

                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-chip color="#0392524a" text-color="success" v-if="activity.type=='check_in'">{{ toTitleCase(activity.type).replace(/_/g, " ")}}</v-chip>
                                                <v-chip color="#ff52523d" text-color="error" v-else>{{ toTitleCase(activity.type).replace(/_/g, " ")}}</v-chip>
                                            </v-list-item-action>

                                        </v-list-item>
                                    </v-list>
                                </v-card-text>
                                </v-col>
                            </v-row>
                        </v-row>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
export default {
    data() {
        return {
            attendance: {},
            activity: {},
            loader: { attendance: false },
            snackbar: {
                message: "",
                display: false,
                color: "",
                top: true,
                right: true,
                bottom: false,
                left: false
            },
        }
    },

    methods: {
        getattendance() {
            this.loader.attendance = true;
            this.$axios
                .get(`${process.env.VUE_APP_API_URL}/api/attendance/` + this.$route.params.id)
                .then(response => {
                    if (response.data.status == 1) {
                        this.loader.attendance = false;
                        this.attendance = response.data.attendance;
                        this.activity = response.data.activity;
                    }
                    if (response.data.status == 0) {
                        this.loader.attendance = false;
                        this.snackbar.message = response.data.message;
                        this.snackbar.display = true;
                    }
                })
                .catch(error => {
                    this.loader.attendance = false;
                    this.snackbar.message = "Error Occured";
                    this.snackbar.display = true;
                });
        },
    },

    mounted() {
        this.getattendance();
    }
}
</script>
