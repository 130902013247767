<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
    class="pa-1 full-width-div"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Add Group
            </div>
            <div class="subtitle-1 font-weight-light">
              Add Group Here
            </div>
          </template>

          <v-form id="addGroup"  @submit.prevent="validateBeforeSubmit('addGroup')"  data-vv-scope="addGroup" v-model="valid">
            <v-container class="py-0">
              <v-row>
                 <v-col
                  cols="12"
                  md="6"
                >
                <label>Name <span style="color:red">*</span></label>
                <br>
                  <v-text-field
                    v-model="group.name"
                    
                    class="purple-input"
                    name="Name"
                    v-validate="'required'"
                    :error-messages="errors.collect('addGroup.Name')"
                  />
                  <span
                    class="red--text"
                    v-text="formErrors.day"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                <label>Type <span style="color:red">*</span></label>
                    <br>
                    <v-autocomplete
                    v-model="group.type"
                    :items="type_list"
                    item-text="value"
                    item-value="key"
                    name="Type"
                    v-validate="'required'"
                    :error-messages="errors.collect('addGroup.Type')"
                    class="purple-input"
                    
                  >
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  v-if="group.type=='department'"
                >
                <label>Division <span style="color:red">*</span></label>
                    <br>
                    <v-select
                    :items="department_list"
                    v-model="group.record_id"
                    item-text="name"
                    item-value="id"
                    class="purple-input"
                    name="Department"
                    
                    v-validate="'required'"
                    :error-messages="errors.collect('addGroup.Department')"
                    ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  v-if="group.type=='location'"
                >
                <label>Location <span style="color:red">*</span></label>
                    <br>
                    <v-select
                    :items="location_list"
                    v-model="group.record_id"
                    item-text="name"
                    item-value="id"
                    class="purple-input"
                    name="Location"
                    
                    v-validate="'required'"
                    :error-messages="errors.collect('addGroup.Location')"
                    ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                <label>Employees</label>
                    <br>
                    <v-autocomplete
                    v-model="group.user_id"
                    :items="employee_list"
                    item-text="name"
                    item-value="id"
                    multiple
                    class="purple-input"
                    name="Employee"  
                  >
                  </v-autocomplete>
                </v-col>

                
               

                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="#FB8C00"
                    to="/groups"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    color="success"
                    type="submit"
                    :loading="loading"
                    :disabled="loading"
                  >
                    Add
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.display"
      :color="snackbar.color"
          :top="snackbar.top"
          :bottom="snackbar.bottom"
          :left="snackbar.left"
          :right="snackbar.right"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapActions } from 'vuex'
  export default {
    name: 'addGroup',
    watch: {
      "group.type":{
            deep: true,
            handler(val){
              if(val =='location'){
                this.getLocationList();
              }
              else{
                this.getDepartmentList();
              }
            }
          },  

    },

    data() {
      return {
        group: {
          name: '',
          user_id:'',
        },
        loading:false,
        snackbar:{
          message:"",
          display:false,
          color: "",
          top: true,
          right: true,
          bottom: false,
          left: false
        },
        formErrors: {},
        valid:false,
        holiday_type_list:[
        {key:'optional',value:'Optional'},
        {key:'public',value:'Public'}],
        type_list:[
        {key:'department',value:'Department'},
        {key:'location',value:'Location'}],
        department_employee_list:[],
        location_employee_list:[],
        employee_list:[],
        department_list:[],
        location_list:[],
      }
    },
    methods: {

    validateBeforeSubmit: function(scope) {
      
      this.$validator.validateAll(scope).then(result => {
        if (result) {
          
          this[scope]();
        }
      });
    },
      addGroup() {
        
        this.loading=true;
        this.$axios.post(`${process.env.VUE_APP_API_URL}/api/groups`, this.group).then((response) => {
          this.snackbar.message = response.data.message;
            this.snackbar.display =true;
            this.loading=false;
          if (response.data.status === 1) {
            // this.location = response.data.location
            this.$router.push({ name: 'Groups' })
          }
          
        }).catch((error) => {
         
          // this.formErrors = error.response.data.error
        })
      },
      getDepartmentUsers()
      {
        this.$axios.get(`${process.env.VUE_APP_API_URL}/api/employees/departmentEmployeeList`).then((response) => {
          
          if (response.data.status === 1) {
            this.department_employee_list = response.data.department_employee_list;
            
            
            
          }
        }).catch((error) => {
         
          console.log(error)
        })
      },
      getLoctionsUsers()
      {
        this.$axios.get(`${process.env.VUE_APP_API_URL}/api/employees/locationEmployeeList`).then((response) => {
          
          if (response.data.status === 1) {
            this.location_employee_list = response.data.location_employee_list;
            
            
            
          }
        }).catch((error) => {
         
          console.log(error)
        })
      },
      searchEmployeeList(group_type)
      {
        
        this.$axios.get(`${process.env.VUE_APP_API_URL}/api/employees/searchEmployeeList`).then((response) => {
          
          if (response.data.status === 1) {
            this.employee_list = response.data.employee_list;
            
            
            
          }
        }).catch((error) => {
         
          console.log(error)
        })
      },
      getLocationList()
      {
        
        this.$axios.get(`${process.env.VUE_APP_API_URL}/api/locations/locationListForGroups`).then((response) => {
          console.log(response.data.status)
          if (response.data.status == 1) {
            
            this.location_list = response.data.location_list;
            
            
          }
        }).catch((error) => {
         
          console.log(error)
        })
      },
      getDepartmentList()
      {
        
        this.$axios.get(`${process.env.VUE_APP_API_URL}/api/departments/departmentList`).then((response) => {
          
          if (response.data.status === 1) {
            this.department_list = response.data.department_list;
            
            
          }
        }).catch((error) => {
         
          console.log(error)
        })
      },
    },
    mounted()
    {
      this.searchEmployeeList();
      
    }
  };
</script>
