<template>
<v-container id="regular-tables" fluid tag="section" class="pa-1 full-width-div">
    <!-- <base-v-component
      heading="Simple Tables"
      link="components/simple-tables"
    /> -->
    <v-row>
        <v-flex xs12 class="buttons">
            <v-btn color="success" class="mr-0" to="addGroup" v-if="checkPermission('create-groups')">
                Add Group
            </v-btn>
            <v-btn class="ml-5" color="success" @click="filter_dialog_box = !filter_dialog_box">
                Filters
            </v-btn>
        </v-flex>
    </v-row>
    <base-material-card icon="mdi-clipboard-text" title="Groups" class="px-5 py-3">
        <v-data-table :headers="headers" :items="groups" item-key="id" :loading="progress_loader" :sort-by="['updated_at']" :sort-desc="[true]" :options.sync="options" :server-items-length="total_items" loading-text="loading... Please Wait" no-results-text="Loading... Please wait">
            <template v-slot:progress>
                <v-progress-linear color="success" indeterminate></v-progress-linear>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" :key="item.name">
                        <td>
                            <router-link :to="{ name: 'Group Detail', params: { id: item.id } }">{{ item.name }}</router-link>
                        </td>

                        <td>{{ toTitleCase(item.type) }}</td>
                        <td v-if="item.record">{{ item.record.name }}</td>
                        <td v-else>-</td>
                        <!-- <td v-if="item.created_at">
                            {{ formatDateTime(item.created_at) }}
                        </td>
                        <td v-else>-</td> -->

                        <td>{{ item.users_count }}</td>
                        <td v-if="item.group_admins">
                            <p v-for="(admin, index) in item.group_admins" :key="admin.id">
                                {{ admin.name}}<span v-if="index != item.group_admins.length - 1">,</span>
                            </p>
                        </td>
                        <td v-else>-</td>
                        <td>
                            <v-tooltip left><template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="!checkPermission('update-groups')" v-bind="attrs" v-on="on"  text icon small color="blue lighten-2" :to="`groups/edit/${item.id}`">
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                            </template><span>Edit Group</span></v-tooltip>
                            

                            <v-tooltip left><template v-slot:activator="{ on, attrs }">
                            <v-btn small text icon color="green lighten-2"  v-bind="attrs" v-on="on"  @click="addUserDialog(item.id)">
                                <v-icon>mdi-account-multiple-plus</v-icon>
                            </v-btn>
                            </template><span>Add Member To Group</span></v-tooltip>

                            <v-tooltip left><template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="!checkPermission('delete-groups')" text icon small  v-bind="attrs" v-on="on" color="red lighten-2" @click="
                             delete_group_box = !delete_group_box; selected_activity_id = item.id;">
                                <v-icon small>mdi-delete</v-icon>
                            </v-btn>
                            </template><span>Delete Group</span></v-tooltip>

                            <v-tooltip left><template v-slot:activator="{ on, attrs }">
                             <v-btn small text icon color="#a0836a" @click="addAdminDialog(item.id)" v-bind="attrs" v-on="on" >
                                <v-icon>mdi-account-plus</v-icon>
                            </v-btn>
                            </template><span>Add Admin To Group</span></v-tooltip>

                        </td>
                    </tr>
                </tbody>
            </template>
            <template slot="no-data">
                <v-layout row wrap justify-center class="text-xs-center" v-if="progress_loader">
                    Loading... Please wait
                </v-layout>
                <v-layout row wrap justify-center class="text-xs-center" v-else>
                    No data available
                </v-layout>
            </template>
        </v-data-table>
    </base-material-card>

    <!-- filter dialog box -->
    <v-dialog v-model="filter_dialog_box" scrollable :overlay="false" max-width="450px" transition="dialog-transition" hide-overlay>
        <v-card style="height:100%">
            <v-form id="filterList" @submit.prevent="validateBeforeSubmit('filterList')" data-vv-scope="filterList" v-model="valid">
                <v-card-text>
                    <v-card-title primary-title>
                        <span class="headline">Filters</span>
                    </v-card-title>
                    <v-container fluid grid-list-md style="padding: 0 16px 16px 16px">
                        <v-layout row wrap class="field_wrapper">
                            <v-flex xs12 md12>
                                <label>Group Name</label>
                                <br />

                                <v-text-field name="name" v-model="filters.name" placeholder="Enter Group Name" class="purple-input"></v-text-field>
                            </v-flex>
                            <v-flex>
                                <label>Group Type</label>
                                <br />
                                <v-autocomplete v-model="filters.type" :items="type_list" item-text="value" item-value="key" name="Type" placeholder="Select Group Type" class="purple-input">
                                </v-autocomplete>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="filter_dialog_box = !filter_dialog_box">Cancel</v-btn>
                    <v-btn small color="error" @click="resetFilters">Reset</v-btn>
                    <v-btn small color="primary" :disabled="!valid" type="submit">Apply</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <!-- Delete Group Dialog Box -->
    <v-dialog v-model="delete_group_box" scrollable :overlay="false" max-width="450px" transition="dialog-transition" hide-overlay>
        <v-card>
            <v-form>
                <v-card-title primary-title>
                    <span>Are you sure you wish to delete this Group ?</span>
                </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="delete_group_box = false">Cancel</v-btn>
                    <v-btn small color="primary" @click="deleteGroup">OK</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <!-- Add User Box -->
    <v-dialog v-model="add_user_box" scrollable :overlay="false" max-width="600" transition="dialog-transition" hide-overlay>
        <v-card style="height:100%;">
            <v-form id="addUsers" @submit.prevent="validateBeforeSubmit('addUsers')" data-vv-scope="addUsers" v-model="add_user_valid">
                <v-card-text>
                    <v-card-title primary-title>
                        <span class="headline">Add Group Member</span>
                    </v-card-title>
                    <div class="pa-10">
                        <v-autocomplete :items="search_user_list" :loading="user_search_loader" :search-input.sync="user_search_query" v-model="user_search_select" class="hidden-sm-and-down" label="Search" item-text="name" return-object :filter="v => v">
                            <template v-slot:item="{ item }">
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.name"></v-list-item-title>
                                    <v-list-item-subtitle v-text="item.employee_id"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                        <v-layout row wrap>

                        </v-layout>
                        <v-layout row wrap v-if="selected_user_list.length!=0" pa-4>
                            <v-flex xs12 class="pl-2 pb-5">
                                <span class="subheading">Selected Users : </span>
                            </v-flex>
                            <v-flex xs12>
                                <v-chip v-for="u in selected_user_list" :key="u.name" close @click:close="removeChip(u)">
                                    {{ u.name }}
                                </v-chip>
                            </v-flex>
                        </v-layout>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="add_user_box = false" :disabled="add_user_loader">Cancel</v-btn>
                    <!-- <v-btn  color="error" @click="resetFilters">Reset</v-btn> -->
                    <v-btn small color="primary" type="submit" :disabled="
                add_user_loader ||
                  !add_user_valid ||
                  selected_user_list.length == 0
              " :loading="add_user_loader">Add</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
     <v-dialog v-model="add_admin_box" scrollable :overlay="false" max-width="600" transition="dialog-transition" hide-overlay>
        <v-card style="height:100%;">
            <v-form id="addUsers" @submit.prevent="validateBeforeSubmit('addAdmin')" data-vv-scope="addUsers" v-model="add_user_valid">
                <v-card-text>
                    <v-card-title primary-title>
                        <span class="headline">Add Group Admin</span>
                    </v-card-title>
                    <div class="pa-10">
                        <v-autocomplete :items="search_user_list" :loading="user_search_loader" :search-input.sync="user_search_query" v-model="user_search_select" class="hidden-sm-and-down" label="Search" item-text="name" return-object :filter="v => v">
                            <template v-slot:item="{ item }">
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.name"></v-list-item-title>
                                    <v-list-item-subtitle v-text="item.employee_id"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                        <v-layout row wrap>

                        </v-layout>
                        <v-layout row wrap v-if="selected_user_list.length!=0" pa-4>
                            <v-flex xs12 class="pl-2 pb-5">
                                <span class="subheading">Selected Users : </span>
                            </v-flex>
                            <v-flex xs12>
                                <v-chip v-for="u in selected_user_list" :key="u.name" close @click:close="removeChip(u)">
                                    {{ u.name }}
                                </v-chip>
                            </v-flex>
                        </v-layout>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="add_admin_box = false" :disabled="add_user_loader">Cancel</v-btn>
                    <!-- <v-btn  color="error" @click="resetFilters">Reset</v-btn> -->
                    <v-btn small color="primary" type="submit" :disabled="add_user_loader || !add_user_valid ||  selected_user_list.length == 0" :loading="add_user_loader">Add</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
export default {
    watch: {
        options: {
            handler() {
                this.getList();
            }
        },
        user_search_query: {
            handler(val) {
                this.search_user_list = [];
                clearInterval(this.search_timer);
                if (val) {
                    this.search_timer = setTimeout(() => {
                        this.getEmployeeList(val);
                    }, 500);
                }
            }
        },
        user_search_select: {
            handler(val) {
                if (val) {
                    let v = val;
                    let i = this.selected_user_list.findIndex(user => {
                        return user.id == v.id;
                    });
                    if (i == -1) {
                        this.selected_user_list.push(val);
                        this.user_search_query = "";
                    }
                }
            }
        }
    },
    data() {
        return {
            groups: [],
            showModal: false,
            image_dialog_box: false,
            image_path: "",
            total_items: 10,
            page: 0,
            options: {},
            filters: {
                name: ""
            },
            is_filtered: false,
            filter_dialog_box: false,
            filter_chips: {
                name: ""
            },
            status_list: [],

            valid: false,
            type_list: [
                { key: "Division", value: "Department" },
                { key: "location", value: "Location" }
            ],
            headers: [
                { text: "Name", value: "name", align: "left", sortable: false },
                { text: "Type", value: "type", align: "left", sortable: false },
                {
                    text: "Division/Location",
                    value: "group",
                    align: "left",
                    sortable: false
                },
                // { text: "Created At", value: "created_at" },
                { text: "Users Count", value: "users_count" },
                { text: "Admins", value: "admins" },
                { text: "Action" }
            ],
            employee_list: [],
            delete_group_box: false,
            selected_activity_id: "",
            is_loading: false,
            progress_loader: true,
            add_user_box: false,
            add_admin_box:false,
            selected_user_list: [],
            search_user_list: [],
            search_timer: "",
            add_user_loader: false,
            add_user_valid: false,
            user_search_select: [],
            user_search_query: "",
            user_search_loader: false,
            selected_group_id: ""
        };
    },
    // created() {
    //   this.userAuth()
    //   this.getActivities()
    // },
    template: "#modal-template",
    methods: {
        getList() {
            this.progress_loader = true;
            this.options.filters = Object.assign({}, this.filters);
            axios
                .get(`${process.env.VUE_APP_API_URL}/api/groups`, {
                    params: this.options
                })
                .then(response => {
                    if (response.data.status == 1) {
                        this.progress_loader = false;
                        this.groups = response.data.groups.data;

                        this.page = response.data.groups.page;
                        this.total_items = response.data.groups.total;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },

        getEmployeeList(username) {
            this.$axios
                .get(
                    `${process.env.VUE_APP_API_URL}/api/groups/groupUnrelatedUsers/` +
                    this.selected_group_id, { params: { username: username } }
                )
                .then(response => {
                    if (response.data.status === 1) {
                        this.search_user_list = response.data.employee_list;
                        // console.log(this.search_user_list)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        addUserDialog(group_id) {
            this.add_admin_box = false;
            this.add_user_box = true;
            this.selected_user_list = [];
            this.selected_group_id = group_id;
            this.getEmployeeList();
        },
        addAdminDialog(group_id) {
            this.add_user_box = false;
            this.add_admin_box = true;
            this.selected_user_list = [];
            this.selected_group_id = group_id;
            this.getEmployeeList();
        },
        addUsers() {
            this.add_user_loader = true;
            this.$axios
                .post(
                    `${process.env.VUE_APP_API_URL}/api/groups/addUsersToGroup/` +
                    this.selected_group_id, { users: this.selected_user_list }
                )
                .then(response => {
                    if (response.data.status == 1) {
                        this.add_user_box = false;
                        this.getList();
                        this.add_user_loader = false;
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.add_user_loader = false;
                })
                .finally(() => {});
        },
        addAdmin() {
            this.add_user_loader = true;
            this.$axios
                .post(
                    `${process.env.VUE_APP_API_URL}/api/groups/addAdminsToGroup/` +
                    this.selected_group_id, { users: this.selected_user_list }
                )
                .then(response => {
                    if (response.data.status == 1) {
                        this.add_admin_box = false;
                        this.getList();
                        this.add_user_loader = false;
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.add_user_loader = false;
                })
                .finally(() => {});
        },
        removeChip(location) {
            this.selected_user_list.splice(
                this.selected_user_list.indexOf(location),
                1
            );
            this.selected_user_list = [...this.selected_user_list];
        },
        deleteGroup: function () {
            this.$axios
                .post(
                    `${process.env.VUE_APP_API_URL}/api/groups/` +
                    this.selected_activity_id +
                    `/delete`
                )
                .then(response => {
                    this.response_message = response.data.message;

                    if (response.data.status == 1) {
                        this.delete_group_box = false;
                        this.getList();
                    } else if (response.data.status == 2) {
                        this.delete_group_box = false;
                        this.getList();
                    }
                    // this.snackBar.message = response.data.message;
                    // this.snackBar.show = true;
                })
                .catch(error => {});
        },
        filterList() {
            let filter_values = Object.values(this.filters);
            if (filter_values.join("").length > 0) {
                this.$router.replace({
                    name: "Groups",
                    query: {
                        filters: true,
                        name: this.filters.name,
                        type: this.filters.type
                    }
                });
                this.displayFilterChips(this.filters);
                this.is_filtered = true;
            } else {
                this.$router.replace({
                    name: "Groups"
                });
            }
            this.getList();
            this.filter_dialog_box = false;
        },
        resetFilters() {
            this.$router.replace({
                name: "Groups",
                query: {}
            });
            this.resetFiltersParameters();
            this.is_filtered = false;
            this.getList();
            this.filter_dialog_box = false;
        },
        resetFiltersParameters: function () {
            for (var key in this.filters) {
                this.filters[key] = "";
            }
        },
        displayFilterChips: function (filters) {
            if (this.$route.query.filters) {
                this.filter_chips.name = this.filters.name;
                this.filter_chips.type = this.filters.type;

                this.is_filtered = true;
            }
        },
        clearFilter: function (filter, key) {
            this.filter_chips[key] = this.filters[key] = "";
            this.filterList();
        },
        applyFiltersOnLoad: function () {
            this.filters.name = this.$route.query.name;
            this.filters.type = this.$route.query.type;

            this.displayFilterChips(this.filters);
        },
        validateBeforeSubmit: function (scope) {
            this.$validator.validateAll(scope).then(result => {
                if (result) {
                    this[scope]();
                }
            });
        },
        fullWidthImage(item_image) {
            this.image_dialog_box = true;
            this.image_path = item_image;
        }
    },
    mounted() {
        this.applyFiltersOnLoad();
        // this.getEmployeeList();
    }
};
</script>
