<template>
<div v-if="detail_loader" style="position:absolute; top:0; right:0; bottom:0; left:0; padding-top: 25%;">
    <div class="text-center">
        <v-progress-circular indeterminate color="primary" class="text-center my-5" large></v-progress-circular>
    </div>
</div>
<v-container id="user-profile" fluid tag="section" v-else class="pa-1 full-width-div">
    <v-snackbar v-model="snackbar.display" :color="snackbar.color" :top="snackbar.top" :bottom="snackbar.bottom" :left="snackbar.left" :right="snackbar.right">
        {{ snackbar.message }}
    </v-snackbar>
    <v-row justify="center">
        <v-col cols="12" md="12">
            <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                        Edit Employee
                    </div>
                </template>

                <v-form id="updateUser" @submit.prevent="validateBeforeSubmit('updateUser')"
                 data-vv-scope="updateUser" ref="userUser">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="4">
                                <label>Name <span style="color:red">*</span></label>
                                <v-text-field v-model="user.name" class="purple-input" name="Name" v-validate="'required'" :error-messages="errors.collect('updateUser.Name')" />
                                <span class="red--text" v-text="formErrors.name" />
                            </v-col>

                            <v-col cols="12" md="4">
                                <label>Employee ID <span style="color:red">*</span></label>
                                <v-text-field v-model="user.employee_id" class="purple-input" name="Employee ID" v-validate="'required'" :error-messages="errors.collect('updateUser.Employee ID')" :disabled="auth_user_role !=='admin'" />
                                <span class="red--text" v-text="formErrors.employee_id" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <label>Official Email <span style="color:red">*</span></label>
                                <v-text-field v-model="user.official_email" class="purple-input" name="Official Email" v-validate="'required'" :rules="emailRules" :error-messages="
                      errors.collect('updateUser.Official Email')
                    " />
                                <span class="red--text" v-text="formErrors.email" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <label>Secondary Email </label>
                                <v-text-field v-model="user.secondary_email" class="purple-input" />
                                <span class="red--text" v-text="formErrors.secondary_email" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <label>Office Phone <span style="color:red">*</span></label>
                                <v-text-field v-model="user.primary_contact" type="number" class="purple-input" name="Office Phone" :rules="mobileRules" v-validate="'required'" :error-messages="errors.collect('updateUser.Office Phone')" />
                                <span class="red--text" v-text="formErrors.primary_contact" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <label>Personal Phone </label>
                                <v-text-field v-model="user.secondary_contact" type="number" class="purple-input" />
                                <span class="red--text" v-text="formErrors.secondary_contact" />
                            </v-col>

                            <v-col cols="6" md="6">
                                <label>Gender <span style="color:red">*</span></label>
                                <v-radio-group v-model="user.gender" row name="Gender" v-validate="'required'" :error-messages="errors.collect('updateUser.Gender')">
                                    <v-radio name="gender" label="Male" value="Male" />
                                    <v-radio name="gender" label="Female" value="Female" />
                                </v-radio-group>
                                <span class="red--text" v-text="formErrors.gender" />
                            </v-col>
                            <v-col cols="6" md="6">
                                <label>Status <span style="color:red">*</span></label>
                                <v-radio-group v-model="user.is_active" row name="Status" v-validate="'required'" :error-messages="errors.collect('updateUser.Status')">
                                    <v-radio name="status" label="Active" value="Active" />
                                    <v-radio name="status" label="InActive" value="InActive" />
                                </v-radio-group>
                            </v-col>

                            <v-col cols="12" md="4">
                                <label> Division <span style="color:red">*</span></label>
                                <v-select :items="department_list" v-model="user.department_id" item-text="name"
                                 item-value="id" class="purple-input" name="Department" chips  :error-messages="errors.collect('updateUser.Department')"></v-select>
                                <span class="red--text" v-text="formErrors.department_id" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <label>Designation <span style="color:red">*</span></label>
                                <v-select :items="designation_list" v-model="user.designation_id"
                                 item-text="name" item-value="id" class="purple-input" name="Designation"
                                  chips :error-messages="errors.collect('updateUser.Designation')"></v-select>

                                <span class="red--text" v-text="formErrors.designation" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <label>Role <span style="color:red">*</span></label>
                                <v-select :items="role_list" v-model="user.role_id" item-text="display_name" item-value="id" class="purple-input" name="Role" chips v-validate="'required'" :error-messages="errors.collect('updateUser.Role')"></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label>Base Location <span style="color:red">*</span></label>
                                <v-select :items="location_list" v-model="user.base_location_id" item-text="name" item-value="id" class="purple-input" name="Location" v-validate="'required'" :error-messages="errors.collect('updateUser.Location')"></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label>Date of Birth <span style="color:red">*</span></label>
                                <v-menu v-model="birthdate_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :value="formatBirthdayDate" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="user.date_of_birth" :max="new Date().toISOString().substr(0, 10)" min="1950-01-01" @input="birthdate_menu = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label>Date of Joining <span style="color:red">*</span></label>
                                <v-menu v-model="date_joining_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :value="formatJoiningDate" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="user.date_of_joining" @input="date_joining_menu = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label>Probation End Date </label>
                                <v-menu v-model="probation_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :value="formatProbationDate" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="user.probation_end_date" :min="user.date_of_joining" @input="probation_menu = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label>Date of Exit <span style="color:red">*</span></label>
                                <v-menu v-model="dateofexit_menu" :close-on-content-click="false" 
                                transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field readonly 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        :value="formatdateofexit" 
                                        name="Date of Exit" :error-messages="
                                        errors.collect('addUser.Date of Exit')
                                        "></v-text-field>
                                    </template>
                                    <v-date-picker v-model="user.date_of_exit" :min="user.date_of_joining" 
                                    @input="dateofexit_menu = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" class="text-right">
                                <v-btn color="#FB8C00" to="/employees">
                                    Back
                                </v-btn>
                                <v-btn color="success" type="submit" :loading="loading">
                                    Update
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </base-material-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
/* eslint-disable */

import axios from "axios";
import { mapActions } from "vuex";
import moment from "moment";
export default {
    name: "Employee",
    computed: {
      formatdateofexit(){
                return this.user.date_of_exit ?
                moment(this.user.date_of_exit).format("DD-MMM-YY") : "";
        },
        
        formatProbationDate() {
            return this.user.probation_end_date ?
                moment(this.user.probation_end_date).format("DD-MMM-YY") :
                "";
        },
        formatBirthdayDate() {
            return this.user.date_of_birth ?
                moment(this.user.date_of_birth).format("DD-MMM-YY") :
                "";
        },
        formatJoiningDate() {
            return this.user.date_of_joining ?
                moment(this.user.date_of_joining).format("DD-MMM-YY") :
                "";
        }
    },
    data() {
        return {
            user: {},
            formErrors: "",
            contacts: [],
            emails: [],
            valid: true,
            date_of_birth_menu: false,
            date_of_joining_menu: false,
            role_list: [],
            location_list: [],
            designation_list: [],
            department_list: [],
            detail_loader: false,
            probation_menu: false,
            date_joining_menu: false,
            birthdate_menu: false,
            mobileRules: [
                v => /^[0-9]+$/.test(v) || "Only Numbers are allowed",
                v => (v && v.length == 10) || "Mobile No must be of 10 digits "
            ],
            emailRules: [
                v =>
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                "E-mail must be valid"
            ],

            auth_user: '',
            auth_user_role: '',

            snackbar: {
                message: "",
                display: false,
                color: "",
                top: true,
                right: true,
                bottom: false,
                left: false
            },

            loading:false,
            dateofexit_menu:false,
        };
    },
    created() {
        this.userAuth();
    },
    methods: {
        ...mapActions(["userAuth"]),

        getDetails() {
            this.detail_loader = true;
            axios
                .get(
                    `${process.env.VUE_APP_API_URL}/api/employees/` +
                    this.$route.params.id +
                    `/edit`
                )
                .then(response => {
                    // console.log(response.data.employee)
                    if (response.data.status == 1) {
                        this.user = response.data.employee;
                        this.contacts = response.data.employee.contacts;
                        this.emails = response.data.employee.emails;

                        let roles = {};
                        let k;
                        for (k = 0; k < response.data.employee.roles.length; k++) {
                            this.user = Object.assign(response.data.employee, {
                                role_id: response.data.employee.roles[k].id
                            });
                        }

                        if (this.user.is_active == 1) {
                            this.user.is_active = "Active";
                        } else {
                            this.user.is_active = "InActive";
                        }

                        let i;

                        for (i in this.contacts) {
                            if (this.contacts[i].is_primary) {
                                this.user.primary_contact = this.contacts[i].contact;
                            } else {
                                this.user.secondary_contact = this.contacts[i].contact;
                            }
                        }
                        let j;
                        for (j in this.emails) {
                            if (this.emails[j].is_primary) {
                                this.user.official_email = this.emails[j].email;
                            } else {
                                this.user.secondary_email = this.emails[j].email;
                            }
                        }
                        this.detail_loader = false;
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {});
        },
        getRoleList() {
            axios
                .get(`${process.env.VUE_APP_API_URL}/api/roles/rolelist`)
                .then(response => {
                    if (response.data.status == 1) {
                        if (this.role == "superadmin") {
                            this.role_list = response.data.role_list;
                        } else {
                            response.data.role_list.forEach(element => {
                                if (element.name == "superadmin") {} else if (element.name == "hr") {} else {
                                    this.role_list.push(element);
                                }
                            });
                        }
                    }
                })
                .catch(error => {});
        },
        getLocationList() {
            this.$axios
                .get(`${process.env.VUE_APP_API_URL}/api/locations/locationlist`)
                .then(response => {
                    if (response.data.status == 1) {
                        this.location_list = response.data.location_list;
                    }
                })
                .catch(error => {});
        },
        getDesignationList() {
            this.$axios
                .get(`${process.env.VUE_APP_API_URL}/api/designations/designationList`)
                .then(response => {
                    if (response.data.status == 1) {
                        this.designation_list = response.data.designation_list;
                    }
                })
                .catch(error => {});
        },
        getDepartmentList() {
            this.$axios
                .get(`${process.env.VUE_APP_API_URL}/api/departments/departmentList`)
                .then(response => {
                    if (response.data.status == 1) {
                        this.department_list = response.data.department_list;
                    }
                })
                .catch(error => {});
        },
        updateUser() {
            this.loading=true;
            axios
                .post(`${process.env.VUE_APP_API_URL}/api/employees/`+this.$route.params.id +`/update`,this.user)
                .then(response => {
                     this.loading=false;
                    this.snackbar.display=true;
                    this.snackbar.message=response.data.message;
                    if (response.data.status === 1) {
                        this.$router.push({ name: "Employees" });
                    }
                })
                .catch(error => {
                    this.loading=false;
                  this.snackbar.display=true;
                        this.snackbar.message="Error Occured";
                });
        },
        validateBeforeSubmit: function (scope) {
            this.$validator.validateAll(scope).then(result => {
                if (result) {
                    this[scope]();
                }
            });
        }
    },
    mounted() {
        this.getDetails();
        this.getRoleList();
        this.getLocationList();
        this.getDepartmentList();
        this.getDesignationList();

        this.auth_user = this.$store.getters.get_user;
        if (this.auth_user) {
            this.auth_user_role = this.auth_user.roles[0].name
        }
    }
};
</script>
