<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
    class="pa-1 full-width-div"
  >
    <v-row>
      <v-col cols="12" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Configurations
            </div>
          </template>
          <v-form
            id="updateConfiguration"
            @submit.prevent="validateBeforeSubmit('updateConfiguration')"
            data-vv-scope="updateConfiguration"
            v-model="valid"
          >
            <v-container class="py-0 mt-10">
              <v-row>
                <v-col cols="12" md="2" class="mt-5 pb-0">
                  <label>Radius :</label>
                </v-col>
                <v-col cols="12" md="3" class="pb-5">
                  <v-text-field
                    v-model="configuration.value"
                    class="purple-input"
                    name="Radius"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-btn color="success" type="submit">
                    Update
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.display"
      :color="snackbar.color"
      :top="snackbar.top"
      :bottom="snackbar.bottom"
      :left="snackbar.left"
      :right="snackbar.right"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
export default {
  name: "Configuration",

  data() {
    return {
      holiday: {
        date: "",
        name: "",
        type: ""
      },
      snackbar: {
        message: "",
        display: false,
        color: "",
        top: true,
        right: true,
        bottom: false,
        left: false
      },
      formErrors: {},
      valid: false,
      configuration: {},
      holiday_type_list: [
        { key: "optional", value: "Optional" },
        { key: "public", value: "Public" }
      ]
    };
  },
  methods: {
    validateBeforeSubmit: function(scope) {
      this.$validator.validateAll(scope).then(result => {
        if (result) {
          this[scope]();
        }
      });
    },
    getConfigurations: function() {
      this.$axios
        .get(`${process.env.VUE_APP_API_URL}/api/configurations`)
        .then(response => {
          if (response.data.status === 1) {
            this.configuration = response.data.configuration;
          }
        })
        .catch(error => {});
    },
    updateConfiguration: function() {
      this.$axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/configurations`,
          this.configuration
        )
        .then(response => {
          this.snackbar.message = response.data.message;
          this.snackbar.display = true;
          if (response.data.status === 1) {
            this.getConfigurations();
          }
        })
        .catch(error => {});
    }
  },
  mounted() {
    this.getConfigurations();
  }
};
</script>
