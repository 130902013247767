// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/base";
import "./plugins/chartist";
// import './plugins/vee-validate'
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import Toasted from "vue-toasted";
import VueConfirmDialog from "vue-confirm-dialog";
import * as VueGoogleMaps from "vue2-google-maps";
import VeeValidate from "vee-validate";
import axios from "axios";
import moment from "moment";
import DatetimePicker from "vuetify-datetime-picker";
import { createLogger } from "vuex";

// import firebase from 'firebase/app'
// Vue.use(firebase);

require("./assets/css/style.css");
Vue.use(VeeValidate);
Vue.use(DatetimePicker);
Vue.prototype.$axios = require("axios");
Vue.prototype.moment = moment;
window.CEvent = new Vue();
Vue.config.productionTip = false;

axios.interceptors.request.use(function(config) {
  config.headers.devicetype = "web";
  return config;
});

Vue.prototype.$axios.interceptors.response.use(null, function(error) {
  switch (error.response.status) {
    case 401:
      localStorage.removeItem("eas_access_token");
      localStorage.removeItem("eas_expiration");
      store.dispatch("removeAccessToken");
      router.push({ name: "login", query: { error: "Session Expired" } });
      break;
    case 403:
      //
      break;
    case 404:
      //
      break;
    case 429:
      //
      break;
    default:
      break;
  }
  return Promise.reject(error);
});
/*eslint no-unreachable: 2*/
Vue.mixin({
  methods: {
    storage_path() {
      return `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_STORAGE_PATH}/`;
    },
    toTitleCase: function(str) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    formatDateTime(date) {
      return moment(date).format("DD-MMM-YY HH:mm");
    },
    formatTime(date) {
      return moment(date).format("HH:mm");
    },
    formatShiftTime(date) {
      var dates = "2021-03-05 " + date;
      return moment(dates).format("HH:mm");
    },
    formatDate(date) {
      return moment(date).format("DD-MMM-YY");
    },
    getStatusColor: function(status, status_list) {
      for (var i = 0; i < status_list.length; i++) {
        if (status == status_list[i].key) {
          return status_list[i].color;
        }
      }
    },
    getStatusValue: function(status, status_list) {
      for (var i = 0; i < status_list.length; i++) {
        if (status == status_list[i].key) {
          return status_list[i].value;
        }
      }
    },

    checkPermission(permission, record = null) {
      let p_record = store.getters.get_permissions.find(
        p => p.name == permission
      );
      if (p_record !== undefined) {
        if (record == null) {
          return true;
        } else {
          switch (p_record.access_to) {
            case "created":
              if (record.user_id == this.$store.getters.get_user.id) {
                return true;
              } else {
                return false;
              }

            case "group":
              return true;

            case "all":
              return true;

            default:
              break;
          }
        }
      } else {
        return false;
      }
    },
    download(data, file_name) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file_name + Date.now() + "." + "csv");
      document.body.appendChild(link);
      link.click();
    },
    setAltImg(event) {
      event.target.src = process.env.VUE_APP_URL+'/file/default_user.jpg';
    },
    getInitials: function(name) {
      let blank_index = name.indexOf(" ");
      let return_string = name[0].toUpperCase();
      if (blank_index > -1) {
        return_string += name[blank_index + 1].toUpperCase();
      }
      return return_string;
    }
  }
});

Vue.use(Toasted);

Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);
// Vue.component('ValidationProvider', ValidationProvider);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAhUtWxC0Jt_bDjbQ058ZS7uMwtpbTBUu0",
    libraries: "places" // necessary for places input
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.forGuests)) {
    next();
  } else if (to.matched.some(record => record.meta.forUsers)) {
    if (localStorage.getItem("eas_access_token") === null) {
      next({
        name: "login"
      });
    } else {
      if (!store.getters.get_access_token) {
        store.dispatch(
          "storeAccessToken",
          localStorage.getItem("eas_access_token")
        );
        store.dispatch(
          "storeUser",
          JSON.parse(localStorage.getItem("eas_auth_user"))
        );
        store.dispatch(
          "storePermissions",
          JSON.parse(localStorage.getItem("eas_user_permissions"))
        );
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + store.getters.get_access_token;
        // store.dispatch('storePermissions', localStorage.getItem('resource_management_permissions'))
      }
      // let permissions = store.getters.get_permissions;
      // if(permissions.indexOf(to.meta.permission) == -1) {
      //   next({
      //     name: 'unauthorized',
      //   })
      // }
      next();
    }
  } else next();
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
