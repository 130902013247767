<template>
<v-app>
    <v-app-bar id="app-bar" absolute app color="transparent" flat height="50" v-if='this.$route.name !== "login"'>
    </v-app-bar>
    <v-app-bar id="app-bar" absolute app color="transparent" flat height="75" v-if='this.$route.name !== "login"'>
        <v-btn class="mr-3" elevation="1" fab small @click="drawer = !drawer">
            <v-icon v-if="value">
                mdi-view-quilt
            </v-icon>

            <v-icon v-else>
                mdi-dots-vertical
            </v-icon>
        </v-btn>

        <v-toolbar-title class="hidden-sm-and-down font-weight-light" v-text="$route.name" />
        <!-- <h2 >{{$route.meta.breadcrumbs.text}}</h2> -->

        <v-spacer />
        <h4 style="color:#949494;">Hello, {{ this.$store.getters.get_user.name }}</h4>
        <v-menu bottom left offset-y origin="top right" transition="scale-transition">
            <template v-slot:activator="{ attrs, on }">
                <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
                    <v-icon>mdi-account</v-icon>
                </v-btn>
            </template>

            <v-list>
                <v-list-item to="/userProfile">
                    <v-list-item-title>Profile</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout">
                    <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

    </v-app-bar>
    <v-navigation-drawer id="core-navigation-drawer" v-model="drawer" :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'" :expand-on-hover="expandOnHover" :right="$vuetify.rtl" src="@/assets/login.jpg" mobile-breakpoint="960" app width="260" v-bind="$attrs" v-if='this.$route.name !== "login"'>
        <template v-slot:img="props">
            <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
        </template>

        <v-divider class="mb-1" />
        <v-list dense nav>
            <v-list-item>
                <v-list-item-avatar class="align-self-center logo" color="white" contain>
                    <v-img :src="storage_path()+logo" width="40" height="40" @error="setAltImg" />
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title class="display-1" v-if="this.$store.getters.get_user.organization">
                        {{ this.$store.getters.get_user.organization.name }}
                    </v-list-item-title>
                    <v-list-item-title class="display-1" v-else>
                        CDP India
                    </v-list-item-title>

                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-divider class="mb-2" />

        <v-list expand nav>
            <!-- Style cascading bug  -->
            <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
            <div />

            <template v-for="(item, i) in items">
                <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
                    <!--  -->
                </base-item-group>
                <base-item v-else :key="`item-${i}`" :item="item" />
            </template>
            <div />
        </v-list>
    </v-navigation-drawer>
    <v-content>
        <v-container grid-list-md fluid style="padding-top: 0px;">
            <v-breadcrumbs :items="$route.meta.breadcrumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                        {{ toTitleCase(item.text) }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
            <br>
            <router-view :key="$route.fullPath"></router-view>
        </v-container>
    </v-content>
           <customfooter></customfooter>

    <!-- <dashboard-core-settings /> -->
</v-app>
</template>

<script>
import customfooter from './views/dashboard/components/core/Footer';
/* eslint-disable */

var config = {

    apiKey: "AIzaSyA35Shz25u_1cLU5ji2cBKigdxHDUv-WZc",
    authDomain: "cdp-eas.firebaseapp.com",
    databaseURL: "https://cdp-eas.firebaseio.com",
    projectId: "cdp-eas",
    storageBucket: "cdp-eas.appspot.com",
    messagingSenderId: "863920722731",
    appId: "1:863920722731:web:86ce2d169f65189daa6b6e"
};
firebase.initializeApp(config);

const messaging = firebase.messaging();
messaging
    .requestPermission()
    .then(function (permission) {
        return messaging.getToken()
    })
    .then(function (token) {
        localStorage.setItem("1cview_firebase_token", token);
    })
    .catch(function (err) {
        console.log("Unable to get permission to notify.", err);
    });

import { mapGetters } from "vuex";
import { mapState } from "vuex";
export default {

    name: 'App',
    components: {
        customfooter,
        DashboardCoreSettings: () => import('./views/dashboard/components/core/Settings'),

    },
    computed: {
        ...mapGetters(["get_access_token", "get_permissions"]),
        ...mapState(['barColor', 'barImage']),
        drawer: {
            get() {
                return this.$store.state.drawer
            },
            set(val) {
                this.$store.commit('SET_DRAWER', val)
            },
        },

    },

    watch: {

        get_permissions: {
            immediate: true,
            handler() {
                this.setUserMenu();
            }
        }
    },
    mounted()
    { 
       
    
    },
    methods: {
        setUserMenu() {
            // console.log(this.get_permissions)
            if (this.get_permissions != null) {
                let permissions = this.get_permissions.map(p => p.name);
                if (!permissions.length) {
                    this.items = [];
                }
                this.items = this.all_items.filter(sidenav => {
                    if (sidenav.permission == "") {
                        return true;
                    }

                    return (permissions.indexOf(sidenav.permission) > -1);
                });
      
               if(this.$store.getters.get_user.organization){
                        this.logo = this.$store.getters.get_user.organization.logo_path;
                }

            }
        },

        logout() {
            this.$store.dispatch("removeAccessToken");
            this.$store.dispatch("removeUser");
            this.$router.push({ name: "login" });
        }

    },
    data() {
        return {
            user: 'User Profile',
            appearance: 'Appearance',

            mini: false,

            value: false,
            logo:require('../src/assets/logo.png'),

            expandOnHover: false,
            title: "Aarvi Encon Limited",

            all_items: [{
                    icon: 'mdi-view-dashboard',
                    title: 'Dashboard',
                    to: '/',
                    permission: "read-dashboard",
                },
                {
                    icon: 'mdi-account-multiple',
                    title: 'Employees',
                    to: '/employees',
                    permission: "read-users",
                },
                {
                    icon: 'mdi-account-details-outline',
                    title: 'Registrations',
                    to: '/registrations',
                    permission: "read-registrations",
                },
                {
                    icon: 'mdi-map-marker-plus',
                    title: 'Activities',
                    to: '/activities',
                    permission: "read-activities",
                },
                {
                    icon: 'mdi-account-network',
                    title: 'Attendance',
                    to: '/attendance',
                    permission: "read-attendance",
                },
                {
                    icon: 'mdi-account',
                    title: 'Roles',
                    to: '/roles',
                    permission: "read-roles",
                },
                {
                    icon: 'mdi-account-group',
                    title: 'Groups',
                    to: '/groups',
                    permission: "read-groups",
                },
                {
                    icon: 'mdi-walk',
                    title: 'Leaves',
                    to: '/leaves',
                    permission: "read-leaves",
                },
                {
                    icon: 'mdi-beach',
                    title: 'Holidays',
                    to: '/holidays',
                    permission: "read-holidays",
                },
                {
                    icon: 'mdi-map',
                    title: 'Locations',
                    to: '/locations',
                    permission: "read-locations",
                },
                {
                    icon: 'mdi-home-outline',
                    title: 'Organization',
                    to: '/organization',
                    permission: "read-organization",
                },
                {
                    icon: 'mdi-bell',
                    title: 'Notifications',
                    to: '/notifications',
                    permission: "read-notifications",
                },
                {
                    icon: 'mdi-cog',
                    title: 'Configurations',
                    to: '/configurations',
                    permission: "read-configurations",
                },
                {
                    icon: 'mdi-file-chart',
                    title: 'Reports',
                    to: '/reports',
                    permission: "",
                },

            ],

        }
    },
};
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>

