<template>
  <v-container fluid tag="section" class="ma-0 pa-0">
      <v-row style="flex-direction: row-reverse;">
         <v-btn color="success" class="ml-3" @click="new_approver_dialog_box = !new_approver_dialog_box">
                Add Approver
            </v-btn>
          <v-btn color="success" class="ml-3" @click="filter_dialog_box = !filter_dialog_box">
                Filters
            </v-btn>
      </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <base-material-card>
          <template v-slot:heading>
            <v-row>
              <v-col cols="10">
                <div class="display-2 font-weight-light">
                  Leave Approver Details
                </div>
              </v-col>
            </v-row>
          </template>
          <v-card flat>
            <v-data-table
              class="mt-5"
              :headers="headers2"
              :items="approver"
              item-key="id"
              :sort-desc="[false]"
              :loading="progress_loader"
              :options.sync="options"
              dense
              :server-items-length="total_items"
              no-results-text="Loading... Please wait"
              :footer-props="{}"
            >
              <template v-slot:progress>
                <v-progress-linear
                  color="success"
                  indeterminate
                ></v-progress-linear>
              </template>
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td>
                      <v-list dense>
                        <!-- <v-subheader>Approvers</v-subheader> -->
                        <v-list-item-group color="primary">
                          <v-list-item
                            v-for="(item, i) in item.leave_approver"
                            :key="i"
                          >
                            <v-list-item-content>
                              <v-list-item-title
                                >{{ item.approver.name }} [{{
                                  item.approver.employee_id
                                }}] -Level
                                {{ item.approver_level }}</v-list-item-title
                              >
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-icon small @click="deleteItem(item)">
                                mdi-delete
                              </v-icon>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </td>
                  </tr>
                </tbody>
              </template>
              <template slot="no-data">
                <v-layout
                  row
                  wrap
                  justify-center
                  class="text-xs-center"
                  v-if="progress_loader"
                >
                  Loading... Please wait
                </v-layout>
                <v-layout row wrap justify-center class="text-xs-center" v-else>
                  No data available
                </v-layout>
              </template>
            </v-data-table>
          </v-card>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="delete_box"
      scrollable
      :overlay="false"
      max-width="480px"
      transition="dialog-transition"
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title primary-title>
          <span>Are you sure you wish to delete this approver ?</span>
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            @click="
              delete_box = false;
              delete_loader = false;
            "
            >Cancel</v-btn
          >
          <v-btn
            small
            color="primary"
            @click="deleteApprover"
            :loading="delete_loader"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackBar.display"
      :color="snackBar.color"
      :top="snackBar.top"
      :bottom="snackBar.bottom"
      :left="snackBar.left"
      :right="snackBar.right"
    >
      {{ snackBar.message }}
    </v-snackbar>
    <!-- filter dialog box -->
        <v-dialog v-model="filter_dialog_box" scrollable :overlay="false" max-width="450px" transition="dialog-transition" hide-overlay>
            <v-card style="height:100%">
                <v-form id="filterList" @submit.prevent="validateBeforeSubmit('filterList')" data-vv-scope="filterList" v-model="valid">
                    <v-card-text>
                        <v-card-title primary-title>
                            <span class="headline">Filters</span>
                        </v-card-title>
                        <v-container fluid grid-list-md style="padding: 0 16px 16px 16px">
                            <v-layout row wrap class="field_wrapper">
                                <v-flex xs12 md12>
                                    <label>Employee Name</label>
                                    <br />
                                    <v-text-field name="username" id="username" type="text" v-model="filters.username" placeholder="Enter Employee Name"></v-text-field>
                                </v-flex>
                                <v-flex xs12 md12>
                                    <label>Emplpyee ID</label>
                                    <br />
                                    <v-text-field name="emplplpyee_id" type="text" v-model="filters.employee_id" placeholder="Enter Employee ID"></v-text-field>
                                </v-flex>

                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small @click="filter_dialog_box = !filter_dialog_box">Cancel</v-btn>
                        <v-btn small color="error" @click="resetFilters">Reset</v-btn>
                        <v-btn small color="primary" :disabled="!valid" type="submit">Apply
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
     <!-- filter dialog box -->
        <v-dialog v-model="new_approver_dialog_box" scrollable :overlay="false" 
        max-width="650px" transition="dialog-transition" hide-overlay>
            <v-card style="height:100%">
                <v-form id="newApprover" @submit.prevent="validateBeforeSubmit('newApprover')" data-vv-scope="newApprover" 
                v-model="valid">
                    <v-card-text>
                        <v-card-title primary-title class="mb-5">
                            <span class="headline">Add Approver</span>
                        </v-card-title>
                        <v-container fluid grid-list-md style="padding: 0 16px 16px 16px">
                            <v-layout row wrap class="field_wrapper">
                                <v-flex xs12 md12>
                                    <label>Employee Name</label>
                                    <br />
                                    <v-autocomplete
                                      v-model="new_approver.employee_id"
                                      :items="registered_employee_list"
                                      item-text="name"
                                      item-value="id"
                                      class="purple-input"
                                      name="Employee"  
                                      required="'required'"
                                    >
                                    </v-autocomplete>
                                </v-flex>
                                <v-flex xs12 md12>
                                    <label>Approver Name</label>
                                    <br />
                                    <v-autocomplete
                                      v-model="new_approver.approver_id"
                                      :items="registered_employee_list"
                                      item-text="name"
                                      item-value="id"
                                      class="purple-input"
                                      required="'required'"
                                      name="Employee"  
                                    >
                                    </v-autocomplete>
                                </v-flex>
                                <v-flex>
                                  <label>Approver Level</label>
                                    <br />
                                  <v-select
                                        :items="level"
                                        v-model="new_approver.approver_level"
                                      ></v-select>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small @click="new_approver_dialog_box = !new_approver_dialog_box">Cancel</v-btn>
                        <v-btn small color="primary" :disabled="!valid" type="submit" :loading='newmapping_loader'>ADD
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  watch: {
    options: {
      handler() {
        this.getdetail();
      }
    }
  },
  data() {
    return {
      approver: [],
      is_loading: false,
      headers2: [
        { text: "Name", value: "name", align: "left", sortable: false },
        { text: "Approver", value: "", align: "left", sortable: false }
      ],
      total_items: 0,
      page: 0,
      progress_loader: false,
      options: { page: 1 },

      snackBar: {
        message: "",
        display: false,
        color: "balck",
        top: true,
        right: true,
        bottom: false,
        left: false
      },
      search: "",

      delete_box: false,
      delete: { id: "" },
      delete_loader: false,

      filters: {
                employee_id: "",
                username: "",
                status: ""
            },
      filter_dialog_box: false,
      valid: false,

      new_approver_dialog_box:false,
      new_approver:{
              employee_id:'',
              approver_id:'',
              approver_level:'1',
      },
       registered_employee_list:[],
       level:['1','2'],
       newmapping_loader:false,


    };
  },
  methods: {
    newApprover(){
      this.newmapping_loader = true;
            this.$axios
                .post(`${process.env.VUE_APP_API_URL}/api/employees/new-approver`, this.new_approver)
                .then(response => {
                    if (response.data.status === 1) {
                       this.snackBar.message = response.data.message;
                        this.snackBar.display = true;
                        
                    }else{
                      this.snackBar.message = response.data.message;
                      this.snackBar.display = true;
                    }
                    this.newmapping_loader = false;
                    this.new_approver_dialog_box=false;
                    this.getdetail();

                })
                .catch(error => {
                     this.newmapping_loader = false;
                     this.snackBar.message = "Error occured";
                     this.snackBar.display = true;
                     this.new_approver_dialog_box=false;
                });
    },
     getRegisteredEmployeeList() {
            this.$axios
                .get(
                    `${process.env.VUE_APP_API_URL}/api/employees/registeredEmployeeList`
                )
                .then(response => {
                    if (response.data.status === 1) {
                        response.data.registered_employee_list.forEach(element => {
                            this.registered_employee_list.push({
                                id: element.id,
                                name: element.name
                            });
                        });
                    }
                })
                .catch(error => {});
        },
    deleteItem(item) {
      this.delete_box = true;
      this.delete.id = item.id;
    },
    deleteApprover() {
      this.$axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/approver/delete/` + this.delete.id
        )
        .then(response => {
          if (response.data.status == 1) {
            this.snackBar.message = response.data.message;
            this.snackBar.display = true;
            this.delete_box = false;
            this.getdetail();
          } else {
            this.snackBar.message = response.data.message;
            this.snackBar.display = true;
            this.delete_box = false;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getdetail() {
      this.progress_loader = true;
      this.options.filters = Object.assign({}, this.filters);

      this.$axios
        .get(`${process.env.VUE_APP_API_URL}/api/approver`, {
          params: this.options
        })
        .then(response => {
          if (response.data.status == 1) {
            this.approver = response.data.approver.data;
            this.total_items = response.data.approver.total; 
            this.progress_loader = false;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    validateBeforeSubmit: function(scope) {
      this.$validator.validateAll(scope).then(result => {
        if (result) {
          this[scope]();
        }
      });
    },
     filterList() {
            let filter_values = Object.values(this.filters);
            if (filter_values.join("").length > 0) {
                this.getdetail();
                // this.displayFilterChips(this.filters);
                // this.is_filtered = true;
                this.filter_dialog_box = false;
            } else {
                this.getdetail();
            }
        },
        resetFilters() {
            this.resetFiltersParameters();
            this.is_filtered = false;
            this.getdetail();
            this.filter_dialog_box = false;
        },
        resetFiltersParameters: function () {
            for (var key in this.filters) {
                this.filters[key] = "";
            }
        },
        clearFilter: function (filter, key) {
            this.filter_chips[key] = this.filters[key] = "";
            this.filterList();
        },
        applyFiltersOnLoad: function () {
            this.filters.username = this.$route.query.username;
            this.filters.employee_id = this.$route.query.employee_id;
            this.filters.status = this.$route.query.status;
            // this.displayFilterChips(this.filters);
        },
  },
  mounted() {
    this.getRegisteredEmployeeList();
  }
};
</script>

<style scoped>
h5 {
  color: black;
}
</style>
