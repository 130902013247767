<template>
<v-container id="user-profile" fluid tag="section" class="pa-1 full-width-div">
    <v-snackbar v-model="snackbar.display" :color="snackbar.color" :top="snackbar.top" :bottom="snackbar.bottom" :left="snackbar.left" :right="snackbar.right">
        {{ snackbar.message }}
    </v-snackbar>
    <v-row justify="center">
        <v-col cols="12" md="7">
            <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                        Edit Group
                    </div>
                </template>

                <v-form id="updateGroup" @submit.prevent="validateBeforeSubmit('updateGroup')" data-vv-scope="updateGroup" v-model="valid">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="6">
                                <label>Name <span style="color:red">*</span></label>
                                <br>
                                <v-text-field v-model="group.name" class="purple-input" name="Name" v-validate="'required'" :error-messages="errors.collect('updateGroup.Name')" />
                                <span class="red--text" v-text="formErrors.day" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <label>Type</label>
                                <br>
                                <v-autocomplete v-model="group.type" :items="type_list" item-text="value" item-value="key" class="purple-input">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="12" v-if="group.type=='department'">
                                <label>Division <span style="color:red">*</span></label>
                                <br>
                                <v-select :items="department_list" v-model="group.record_id" item-text="name" item-value="id" class="purple-input" name="Department" v-validate="'required'" :error-messages="errors.collect('addGroup.Department')"></v-select>
                            </v-col>
                            <v-col cols="12" md="12" v-if="group.type=='location'">
                                <label>Location <span style="color:red">*</span></label>
                                <br>
                                <v-select :items="location_list" v-model="group.record_id" item-text="name" item-value="id" class="purple-input" name="Location" v-validate="'required'" :error-messages="errors.collect('addGroup.Location')"></v-select>
                            </v-col>
                            <v-col cols="12" md="12">
                                <label>Employees</label>
                                <br>
                                <v-select v-model="group.user_id" :items="employee_list" item-text="name" item-value="id" name="Employees" multiple chips class="purple-input" clearable>
                                </v-select>
                            </v-col>

                            <v-col cols="12" class="text-right">
                                <v-btn color="#FB8C00" to="/groups">
                                    Back
                                </v-btn>
                                <v-btn color="success" type="submit">
                                    Update
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </base-material-card>
        </v-col>
        <v-col cols="12" md="5">
            <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                        Group Admin
                    </div>
                </template>
                <v-row>
                    <v-list dense class="ma-5">
                        <v-list-item v-for="admin in admin" :key="admin.employee_id" class="pl-0">
                            <v-chip class="" style="width: 100%;background-color:#e8e8e2ad">
                                {{ admin['name'] }}</v-chip>
                        </v-list-item>
                    </v-list>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                        <v-form @submit.prevent="validateBeforeSubmit('updateAdmin')" 
                        data-vv-scope="updateAdmin">
                            <v-layout row wrap class="field_wrapper">
                                <v-flex>
                                    <v-col cols="12">
                                        <v-col cols="12" class="pt-9 pb-0 text-left">
                                            <p class="font-weight-light grey--text">Remove Admin</p>
                                        </v-col>
                                        <v-autocomplete 
                                        v-model="admin_list" 
                                        :items="admin" dense chips small-chips 
                                        label="Select admin to remove" item-text="name" item-value="id" 
                                        multiple solo></v-autocomplete>
                                    </v-col>
                                </v-flex>
                            </v-layout>
                            <v-card-actions style="flex-direction: row-reverse;">
                                <v-btn small @click="admin_list= []">Cancel</v-btn>
                                <v-btn small color="error" type="submit" :loading="remove_loader" 
                                :disabled="remove_loader">Remove</v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-row>
            </v-col>
                </v-row>
            </base-material-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import axios from 'axios'
export default {
    name: 'updateGroup',
    watch: {
        "group.type": {
            deep: true,
            handler(val) {
                if (val == 'location') {
                    this.getLocationList();
                } else {
                    this.getDepartmentList();
                }
            }
        },
        // "group.record_id":{
        //   deep:true,
        //   handler(val){
        //     this.getEmployeeList(val);
        //   }
        // } 

    },
    data() {
        return {
            group: {
                name: '',
                user_id: '',
            },
            snackbar: {
                message: "",
                display: false,
                color: "",
                top: true,
                right: true,
                bottom: false,
                left: false
            },
            formErrors: {},
            valid: false,
            department_list: [],
            location_list: [],
            employee_list: [],
            type_list: [
                { key: 'department', value: 'Department' },
                { key: 'location', value: 'Location' }
            ],
            headers: [
                { text: 'User', value: 'user' },
                { text: 'Employee ID', value: 'emp' },
                { text: 'Action' },
            ],
            admin:[],
            admin_list:[],
            import_loading:false,
            remove_loader:false,

        }
    },
    methods: {

        validateBeforeSubmit: function (scope) {

            this.$validator.validateAll(scope).then(result => {
                if (result) {

                    this[scope]();
                }
            });
        },
        getDetails() {
            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/groups/` + this.$route.params.id + `/edit`)
                .then(response => {
                    if (response.data.status == 1) {

                        this.group = response.data.group;
                        let users = [];
                        let k;
                        this.admin = response.data.group.group_admins;
                        for (k = 0; k < response.data.group.users.length; k++) {
                            // users += response.data.user.users[i].id ;
                            users.push(response.data.group.users[k].id);
                        }

                        this.group = Object.assign(response.data.group, { user_id: users });
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        updateGroup() {

            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/groups/` + this.$route.params.id + `/update`, this.group).then((response) => {

                if (response.data.status === 1) {
                    // this.location = response.data.location
                    this.$router.push({ name: 'Groups' })
                }
            }).catch((error) => {

                // this.formErrors = error.response.data.error
            })
        },

        getEmployeeList() {
            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/groups/groupUnrelatedUsers/` + this.$route.params.id).then((response) => {

                if (response.data.status === 1) {
                    this.employee_list = response.data.employee_list;
                    // console.log(this.employee_list)

                }
            }).catch((error) => {

                console.log(error)
            })
        },
        getLocationList() {

            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/locations/locationlist`).then((response) => {

                if (response.data.status == 1) {

                    this.location_list = response.data.location_list;

                }
            }).catch((error) => {

                console.log(error)
            })
        },
        getDepartmentList() {

            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/departments/departmentList`).then((response) => {

                if (response.data.status === 1) {
                    this.department_list = response.data.department_list;

                }
            }).catch((error) => {

                console.log(error)
            })
        },
        updateAdmin(){
          this.remove_loader=true;
           let data = {
                       id:this.admin_list,
                       group_id:this.group.id};

           this.$axios.post(`${process.env.VUE_APP_API_URL}/api/groups/removeAdmin`,data)
           .then((response) => {
                 this.remove_loader=false;
                if (response.data.status ==1) {
                    this.snackbar.message= response.data.message;
                    this.snackbar.display=true;
                    this.getDetails();
                }
                if (response.data.status === 0) {
                    this.snackbar.message= response.data.message;
                    this.snackbar.display=true;
                }
            }).catch((error) => {
                this.snackbar.message="Error Occured";
                this.snackbar.display=true;
                this.remove_loader=false;
                console.log(error)
            })
        }
    },
    mounted() {
        this.getDetails();
        this.getEmployeeList();
    }
};
</script>
