<template>
<v-container id="regular-tables" fluid tag="section" class="full-width-div pa-1">
    <v-row>
        <v-flex xs12 class="buttons">
            <v-btn color="success" class="mx-2" @click="filter_dialog_box = !filter_dialog_box">
                Filters
            </v-btn>
        </v-flex>
    </v-row>
    <base-material-card icon="mdi-clipboard-text" title="Leaves" class="px-5 py-3">
        <v-data-table :headers="headers" :items="leaves" item-key="id" :sort-by="['created_at']" :sort-desc="[true]" :loading="is_loading" :options.sync="options" :server-items-length="total_items" no-results-text="Loading... Please wait">
            <template v-slot:progress>
                <v-progress-linear color="success" indeterminate></v-progress-linear>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" :key="item.name">
                        <td v-if="item.user">
                            <router-link :to="{ name: 'Leave Detail', params: { id: item.leave_id } }">
                                {{ item.user.name }}</router-link>
                        </td>
                        <td v-else>-</td>
                        <td v-if="item.leaves!=null">
                            {{ item.leaves.leave_type.value }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="item.leaves!=null">
                            <v-chip :text-color="getStatusColor(item.status,leave_status_list)" 
                            :color="getStatusColor(item.status,leave_status_list)">
                                {{toTitleCase(item.status).replace(/_/g, " ")}}
                            </v-chip>
                        </td>
                        <td v-else>-</td>
                        <td v-if="item.leaves!=null">
                            <v-chip :text-color="getStatusColor(item.leaves.approval_status,leave_status_list)" :color="getStatusColor(item.leaves.approval_status,leave_status_list)">
                                {{toTitleCase(item.leaves.approval_status).replace(/_/g, " ")}}
                            </v-chip>
                        </td>
                        <td v-else>-</td>
                        <td v-if="item.leaves!=null">
                            {{ toTitleCase(item.leaves.duration_type).replace(/_/g, " ") }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="item.leaves!=null">{{ formatDate(item.leaves.start_date) }}</td>
                        <td v-else>-</td>
                        <td v-if="item.leaves!=null">{{ formatDate(item.leaves.end_date) }}</td>
                        <td v-else>-</td>
                        <td v-if="item.leaves!=null">{{ formatDateTime(item.leaves.created_at) }}</td>
                        <td v-else>-</td>

                        <td style="width:15%;padding: 0px;">
                            <v-btn :disabled="item.status!= 'pending'" 
                            color="green lighten-2" class="mt-2 mr-2" small 
                            @click="leave_confirmation_box = !leave_confirmation_box;
                            (selected_leave_id = item.id), (status = 'approve');">
                                Approve
                            </v-btn>
                            <v-btn :disabled="item.status != 'pending'"  class="mt-2 ml-2" small 
                            color="red lighten-2" 
                            @click="leave_confirmation_box = !leave_confirmation_box;
                            (selected_leave_id = item.id), (status = 'reject');">
                                Reject
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
    </base-material-card>

    <!-- Filter Dialog Box -->

    <v-dialog v-model="filter_dialog_box" scrollable :overlay="false" max-width="450px" transition="dialog-transition" hide-overlay>
        <v-card style="height:100%">
            <v-form id="filterList" @submit.prevent="validateBeforeSubmit('filterList')" data-vv-scope="filterList" v-model="valid">
                <v-card-text>
                    <v-card-title primary-title>
                        <span class="headline">Filters</span>
                    </v-card-title>
                    <v-container fluid grid-list-md style="padding: 0 16px 16px 16px">
                        <v-layout row wrap class="field_wrapper">
                            <v-flex xs12 md12>
                                <label>Employee Name</label>
                                <br />
                                <v-text-field name="Employee Name" v-model="filters.employee_name" placeholder="Enter Employee Name"></v-text-field>
                            </v-flex>
                            <v-flex xs12 md12>
                                <label>Approval Status</label>
                                <br />
                                <v-autocomplete v-model="filters.approval_status" :items="leave_status_list" item-text="value" item-value="key" placeholder="Enter Name" class="purple-input">
                                </v-autocomplete>
                            </v-flex>

                            <v-flex xs12 md12>
                                <label>Start Date</label>
                                <br />
                                <v-menu ref="start_menu" v-model="start_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :value="formatStartDate" readonly v-bind="attrs" v-on="on" placeholder="Enter Start Date"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.start_date" :max="filters.end_date" @input="start_menu = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-flex>

                            <v-flex xs12 md12>
                                <label>End Date</label>
                                <br />
                                <v-menu v-model="end_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :value="formatEndDate" readonly v-bind="attrs" v-on="on" placeholder="Enter End Date"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.end_date" @input="end_menu = false" :min="filters.start_date">
                                    </v-date-picker>
                                </v-menu>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="filter_dialog_box = !filter_dialog_box">Cancel</v-btn>
                    <v-btn small color="error" @click="resetFilters">Reset</v-btn>
                    <v-btn small color="primary" :disabled="!valid" type="submit">Apply
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <!-- Delete Leave Dialog Box -->
    <v-dialog v-model="delete_leave_box" scrollable :overlay="false" max-width="450px" transition="dialog-transition" hide-overlay persistent>
        <v-card>
            <v-form>
                <v-card-title primary-title>
                    <span>Are you sure you wish to delete this Leave ?</span>
                </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="delete_leave_box = false">Cancel</v-btn>
                    <v-btn small color="primary" @click="deleteLeave">OK</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <!-- approve/reject confirmation box -->
    <v-dialog v-model="leave_confirmation_box" scrollable :overlay="false" max-width="500px" transition="dialog-transition" hide-overlay persistent>
        <v-card>
            <v-form>
                <v-card-title primary-title>
                    <span>Are you sure you wish to {{ toTitleCase(status) }} this Leave
                        ?</span>
                </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="leave_confirmation_box = false">Cancel</v-btn>
                    <v-btn color="primary" small @click="leaveConfirmation" :loading="leave_confirmation_box_loading">OK</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.display" :color="snackbar.color" :top="snackbar.top" :bottom="snackbar.bottom" :left="snackbar.left" :right="snackbar.right">
        {{ snackbar.message }}
    </v-snackbar>
</v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
    watch: {
        options: {
            handler() {
                this.getList();
            }
        }
    },
    computed: {
        formatStartDate() {
            return this.filters.start_date ?
                moment(this.filters.start_date).format("DD-MMM-YY") :
                "";
        },
        formatEndDate() {
            return this.filters.end_date ?
                moment(this.filters.end_date).format("DD-MMM-YY") :
                "";
        }
    },
    methods: {
        getList() {
            this.is_loading = true;
            this.options.filters = Object.assign({}, this.filters);
            axios
                .get(`${process.env.VUE_APP_API_URL}/api/leaves/forapprove`, {
                    params: this.options
                })
                .then(response => {  
                    if (response.data.status == 1) {
                        this.leaves = response.data.leaves_to_approve.data;
                        this.page = response.data.leaves_to_approve.page;
                        this.total_items = response.data.leaves_to_approve.total;
                        this.is_loading = false;
                    }else{
                        this.is_loading = false;
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.is_loading = false;
                });
        },
        getLeaveStatusList() {
            axios
                .get(`${process.env.VUE_APP_API_URL}/api/configurations/getLeaveStatus`)
                .then(response => {
                    if (response.data.status == 1) {
                        this.leave_status_list = response.data.leave_status_list;
                        this.leave_status_list.push({
                            type: "leave_status",
                            key: "all",
                            value: "All"
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        leaveConfirmation() {
            if (this.status == "approve") {
                this.approveLeave(this.selected_leave_id);
            }
            if (this.status == "reject") {
                this.rejectLeave(this.selected_leave_id);
            }
        },
        approveLeave(leave_id) {
            this.leave_confirmation_box_loading = true;
            this.$axios
                .post(`${process.env.VUE_APP_API_URL}/api/leaves/approveLeave`, {
                    id: leave_id
                })
                .then(response => {
                    if (response.data.status == 1) {
                        this.leave_confirmation_box= false;
                        this.leave_confirmation_box_loading = false;
                        this.snackbar.message = response.data.message;
                        this.snackbar.display = true;
                        this.getList();
                    } else {
                        this.leave_confirmation_box_loading = false;
                        this.snackbar.message = response.data.message;
                        this.snackbar.display = true;
                    }

                })
                .catch(error => {
                    this.leave_confirmation_box_loading = false;
                    this.leave_confirmation_box = false;
                    this.snackbar.message = "Error Occured";
                    this.snackbar.display = true;
                });
        },
        rejectLeave(leave_id) {
            this.leave_confirmation_box_loading = true;
            this.$axios
                .post(`${process.env.VUE_APP_API_URL}/api/leaves/rejectLeave`, {
                    id: leave_id
                })
                .then(response => {
                    this.leave_confirmation_box= false;
                    if (response.data.status == 1) {
                        this.leave_confirmation_box_loading = false;
                        this.leave_confirmation_box = false;
                        this.snackbar.message = response.data.message;
                        this.snackbar.display = true;
                        this.getList();
                    } else {
                        this.leave_confirmation_box_loading = false;
                        this.leave_confirmation_box = false;
                        this.snackbar.message = response.data.message;
                        this.snackbar.display = true;
                    }
                })
                .catch(error => {
                    this.leave_confirmation_box_loading = true;
                    this.leave_confirmation_box = false;
                    this.snackbar.message = "Error Occured";
                    this.snackbar.display = true;
                });
        },
        deleteLeave: function () {
            this.$axios
                .post(`${process.env.VUE_APP_API_URL}/api/leaves/`+this.selected_leave_id +`/delete`)
                .then(response => {
                    this.response_message = response.data.message;
                    this.snackbar.message = response.data.message;
                    this.snackbar.display = true;
                    if (response.data.status == 1) {
                        this.delete_leave_box = false;
                        this.getList();
                    } else if (response.data.status == 2) {
                        this.delete_leave_box = false;
                        this.getList();
                    }
                    // this.snackBar.message = response.data.message;
                    // this.snackBar.show = true;
                })
                .catch(error => {
                    this.snackbar.message = "Error Occured";
                    this.snackbar.display = true;
                });
        },
        filterList() {
            let filter_values = Object.values(this.filters);
            if (filter_values.join("").length > 0) {
                this.getList();
                this.is_filtered = true;
            } else {
               this.getList();
            }
            this.getList();
            this.filter_dialog_box = false;
        },
        resetFilters() {
            this.resetFiltersParameters();
            this.is_filtered = false;
            this.getList();
            this.filter_dialog_box = false;
        },
        resetFiltersParameters: function () {
            for (var key in this.filters) {
                this.filters[key] = "";
            }
        },
        displayFilterChips: function (filters) {
            if (this.$route.query.filters) {
                this.filter_chips.employee_name = filters.employee_name;
                this.filter_chips.approval_status = filters.approval_status;
                this.filter_chips.start_date = filters.start_date;
                this.filter_chips.end_date = filters.end_date;
                this.is_filtered = true;
            }
        },

        clearFilter: function (filter, key) {
            this.filter_chips[key] = this.filters[key] = "";
            this.filterList();
        },
        applyFiltersOnLoad: function () {
            this.filters.employee_name = this.$route.query.employee_name;
            this.filters.start_date = this.$route.query.start_date;
            this.filters.end_date = this.$route.query.end_date;
            this.filters.approval_status = this.$route.query.approval_status;

            this.displayFilterChips(this.filters);
        },

        validateBeforeSubmit: function (scope) {
            this.$validator.validateAll(scope).then(result => {
                if (result) {
                    this[scope]();
                }
            });
        }
    },

    data() {
        return {
            filters: {
                employee_name: "",
                approval_status: "",
                start_date: "",
                end_date: ""
            },
            leave_confirmation_box_loading: false,
            snackbar: {
                message: "",
                display: false,
                color: "black",
                top: true,
                right: true,
                bottom: false,
                left: false
            },
            start_menu: false,
            end_menu: false,
            leave_confirmation_box: false,
            status: "",
            is_filtered: false,
            filter_chips: {
                start_date: "",
                end_date: "",
                employee_name: "",
                approval_status: ""
            },
            total_items: 10,
            filter_dialog_box: false,
            page: 0,
            options: {},
            valid: false,
            leave_status_list: [],
            headers: [{
                    text: "Employee Name",
                    value: "name",
                    align: "left",
                    sortable: false
                },
                { text: "Type", value: "type", align: "left", sortable: false },
                { text: "Your Approval", value: "approval_status", sortable: false },
                { text: "Final Status", value: "approval_status", sortable: false },
                { text: "Duration", value: "duration_type", sortable: false },
                {
                    text: "Start Date",
                    value: "Start Date",
                    align: "left",
                    sortable: false
                },
                { text: "End Date", value: "End Date", sortable: false },
                { text: "Applied On", value: "created_at", sortable: false },
                { text: "Action" }
            ],
            leaves: [],
            delete_leave_box: false,
            selected_leave_id: "",
            is_loading: false,
            auth_user: {},
            auth_user_role: ""
        };
    },
    mounted() {
        this.applyFiltersOnLoad();
        this.getLeaveStatusList();
        this.auth_user = this.$store.getters.get_user;

        if (this.auth_user) {
            this.auth_user_role = this.auth_user.roles[0].name;
        }

        if (this.$route.params.message) {
            this.snackbar.display = true;
            this.snackbar.message = this.$route.params.message;
        }
    }
};
</script>
