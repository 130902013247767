<template>
<v-container id="regular-tables" fluid tag="section" class="ma-0 pa-0">
    <v-row>
        <v-flex xs12 class="buttons">
            <v-btn color="success" class="mr-0" to="AddLocation" v-if="checkPermission('create-locations')">
                Add Location
            </v-btn>
        </v-flex>
    </v-row>
    <!--   <v-btn
    class="ml-5"
      color="success"
     @click="filter_dialog_box = !filter_dialog_box"

    >
    Filters
    </v-btn> -->
    <base-material-card icon="mdi-clipboard-text" title="Geo Locations" class="px-5 py-3">
        <v-data-table :headers="headers" :items="locations" item-key="id" :sort-by="['updated_at']" :sort-desc="[true]" :loading="is_loading" :options.sync="options" :server-items-length="total_items" no-results-text="Loading... Please wait">
            <template v-slot:progress>
                <v-progress-linear color="success" indeterminate></v-progress-linear>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" :key="item.name">

                        <td>
                            <router-link :to="{ name:'Location Detail',params: { id: item.id } }">{{ item.name }}</router-link>
                        </td>

                        <td class="address-width">{{item.address}}</td>

                        <td>{{item.lat}}</td>
                        <td>{{item.long}}</td>
                         <td>{{item.radius}}</td>
                        <td>
                            <v-btn :disabled="!checkPermission('update-locations')" text icon large color="blue lighten-2" :to="`locations/edit/${item.id}`">
                                <v-icon large>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn :disabled="!checkPermission('delete-locations')" text icon large color="red lighten-2" @click="delete_location_box= !delete_location_box; selected_location_id=item.id">
                                <v-icon large>mdi-delete</v-icon>
                            </v-btn>
                        </td>

                    </tr>
                </tbody>
            </template>
        </v-data-table>
    </base-material-card>

    <!-- Delete Location Dialog Box -->
    <v-dialog v-model="delete_location_box" scrollable :overlay="false" max-width="450px" transition="dialog-transition" hide-overlay>
        <v-card>

            <v-card-title primary-title>
                <span>Are you sure you wish to delete this Location ?</span>
            </v-card-title>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small @click="delete_location_box=false">Cancel</v-btn>
                <v-btn small color="primary" @click="deleteLocation">OK</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
export default {
    name: 'Locations',
    watch: {
        options: {
            handler() {
                this.getList();
            }
        },

    },
    data() {
        return {
            filter_dialog_box: false,
            locations: [],
            total_items: 10,
            page: 0,
            options: {},
            headers: [{ text: "Name", value: "name", align: "left", sortable: false },
                { text: "Address", value: "address", align: "left", sortable: false,width: '43%' },
                { text: "Latitude", value: "lat", align: "left", sortable: false },
                { text: "Longitude", value: "long", align: "left", sortable: false },
                { text: "Radius", value: "radius", align: "left", sortable: false },
                { text: "Action",sortable:false, width:'12%' }
            ],
            delete_location_box: false,
            selected_location_id: "",
            is_loading: false,

        }
    },

    methods: {

        getList() {
            this.is_loading = true;
            axios.get(`${process.env.VUE_APP_API_URL}/api/locations`, { params: this.options }).then((response) => {
                if (response.data.status == 1) {
                    this.locations = response.data.locations.data;

                    this.total_items = response.data.locations.total
                    this.is_loading = false;
                }

            }).catch(error => {

            });
        },
        deleteLocation: function () {

            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/locations/` +
                    this.selected_location_id + `/delete`).then((response) => {

                    this.response_message = response.data.message;

                    if (response.data.status == 1) {
                        this.delete_location_box = false;
                        this.getList();
                    } else if (response.data.status == 2) {
                        this.delete_location_box = false;
                        this.getList();
                    }
                    // this.snackBar.message = response.data.message;
                    // this.snackBar.show = true;

                })
                .catch(error => {

                });
        },

    },
};
</script>
