<template>
<div>
    <v-footer v-if='this.$route.name !== "login"' class="ma-0 pa-0 main">
        <v-flex xs3></v-flex>
        <v-flex xs8 style="text-align: center;">
            <span class="footer"> Designed and Developed by - {{ company_name }}</span>
        </v-flex>
        <v-flex xs3></v-flex>
    </v-footer>
     <v-footer v-else>
        <v-flex xs2></v-flex>
        <v-flex xs8 style="text-align: center;">
            <span class="footer"> Designed and Developed by - {{ company_name }}</span>
        </v-flex>
        <v-flex xs3></v-flex>
    </v-footer>
</div>
</template>
<script>
export default {
  data: () => ({
        company_name :"CDP India Pvt. Ltd.",
  })
}
</script>
<style scoped>
/* .v-sheet.v-footer:not(.v-sheet--outlined) {
     background-color: white;
    box-shadow: 3px -3px 7px 0px #e2e7ec;
} */
.footer{
    font-size: 13px;
    font-weight: 700;
}

</style>
