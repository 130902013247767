<template>
<v-container id="regular-tables" fluid tag="section" class="ma-0 pa-0">
    <!-- Snakbar -->
    <v-snackbar v-model="snackbar.display" :color="snackbar.color" :top="snackbar.top" :bottom="snackbar.bottom" :left="snackbar.left" :right="snackbar.right">
        {{ snackbar.message }}
    </v-snackbar>
    <!-- Filter row -->
    <v-row style="flex-direction: row-reverse;">
        <v-btn color="success" class="mx-1" @click="exportData()" :loading="export_loader">
                Export
        </v-btn>
            <v-btn color="success" class="mx-1" :to="{name: 'Add Attendance'}"
             v-if="checkPermission('create-attendance')">
                Create 
            </v-btn>
            <v-btn color="success" class="mx-1" @click="filter_dialog_box = !filter_dialog_box">
                Filters
            </v-btn>
         <v-flex xs3 class="toggle">
            <!-- toggle -->
                <v-btn-toggle rounded mandatory class="toggle" v-model='toggle_one' >
                    <v-btn text>
                        Pending
                    </v-btn>
                    <v-btn text>
                        Approved
                    </v-btn>
                    <v-btn text>
                        Rejected
                    </v-btn>
                </v-btn-toggle>
            <!--  -->
         </v-flex>
         <v-flex xs2>
            <span v-if="this.selected.length>0 && filters.status=='Pending'">
            <v-btn :disabled="!checkPermission('update-attendance')" small color="green lighten-2" @click="attendanceStatus('approve')">
                Approve
            </v-btn>
            <v-btn :disabled="!checkPermission('update-attendance')"  small color="red lighten-2" @click="attendanceStatus('reject')">
                Reject
            </v-btn>
            </span>
        </v-flex>
    </v-row>
    <!-- data table -->
    <base-material-card icon="mdi-clipboard-text" title="Attendance" class="px-5 py-3">
        <h6>{{ msg}}</h6>
        <v-data-table :headers="headers" :items="attendance" item-key="id" :sort-by="['created_at']"
         :sort-desc="[true]" :options.sync="options" :server-items-length="total_items"
         :loading="is_loading" loading-text="Loading... Please wait" :show-select="show_select" v-model="selected">
            <template v-slot:progress>
                <v-progress-linear color="success" indeterminate></v-progress-linear>
            </template>
            <template v-slot:loading>
                <v-layout row wrap justify-center class="text-xs-center">
                    Loading... Please wait
                </v-layout>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td v-if="show_select">
                            <v-checkbox  class="pa-0 ma-0" :ripple="false" v-model="selected" 
                            :value="item" hide-details
                            >
                            </v-checkbox>
                        </td>
                        <td v-if="item.user">
                            <v-avatar size="36" v-if="item.user.image">
                                <img :src="storage_path() + item.user.image.image"
                                 @click="fullWidthImage(item.image.image)"
                                 @error="setAltImg" />
                            </v-avatar>
                            <v-avatar size="36" v-else>
                                <img src="@/assets/default.png" @click="fullWidthImage(item.user.image.image)" />
                            </v-avatar>
                        </td>
                        <td v-else>-</td>
                        <td>
                             <router-link v-if="item.id" class="ml-1" 
                             :to="{ name:'Attendance Detail',params: { id: item.id } }">
                                {{ item.user.name }}
                            </router-link>
                        </td>
                        <td v-if="item.user">{{ item.user.employee_id }}</td>
                        <td v-else>-</td>
                        <td v-if="item.created_at != null">
                            {{ formatDate(item.date) }}
                        </td>
                        <td v-else>-</td>

                        <td v-if="item.first_check_in">
                            {{ formatTime(item.first_check_in) }}
                        </td>
                        <td v-else>-</td>

                        <td v-if="item.last_check_out">
                            {{ formatTime(item.last_check_out) }}
                        </td>
                        <td v-else>-</td>

                         <td v-if="item.total_duration!=null">{{ formatShiftTime(item.total_duration)}} </td>
                        <td v-else>-</td> 

                        <td v-if="item.shift">{{ formatShiftTime(item.shift.start_time) }} - {{ formatShiftTime(item.shift.end_time) }}</td>
                        <td v-else>-</td>

                        <td v-if="item.status">
                             <v-chip text-color="primary" color="#baecbc" v-if="item.status=='Approved'">
                                {{ toTitleCase(item.status).replace(/_/g, " ")}}
                            </v-chip>
                            <v-chip text-color="#ff5252" color="#f4433642" v-if="item.status=='Rejected'">
                                {{ toTitleCase(item.status).replace(/_/g, " ")}}
                            </v-chip>
                        </td>
                        <td v-else>
                            <v-chip text-color="amber" color="#ffe8a4">
                                {{ toTitleCase("Pending").replace(/_/g, " ")}}
                            </v-chip>
                        </td>
                        <!-- <td v-if="item.is_reconsile">
                            <v-icon>mdi-update</v-icon>
                        </td> -->
                        <!-- <td v-else>-</td> -->
                         <td v-if="!is_loading" style="padding: 0px">
                            <v-tooltip left><template v-slot:activator="{ on, attrs }">

                            <v-btn :disabled="checkedit(item)" text icon small v-bind="attrs" v-on="on"
                             
                             :to="{ name:'Attendance Edit',params: { id: item.id } }">
                                <v-icon large color="blue lighten-2">mdi-pencil</v-icon>
                            </v-btn>
                            </template><span>Reconcile Attendance</span></v-tooltip>

                            
                         </td>
                    </tr>
                </tbody>
            </template>
            <template slot="no-data-text">
                <v-layout row wrap justify-center class="text-xs-center" v-if="is_loading">
                    Loading... Please wait
                </v-layout>
                <v-layout row wrap justify-center class="text-xs-center" v-else>
                    No data available
                </v-layout>
            </template>
        </v-data-table>
    </base-material-card>
    <!-- filter dialog box -->
    <v-dialog v-model="filter_dialog_box" scrollable :overlay="false" max-width="450px" transition="dialog-transition" hide-overlay>
        <v-card style="height:100%">
            <v-form id="filterList" @submit.prevent="validateBeforeSubmit('filterList')" data-vv-scope="filterList" v-model="valid">
                <v-card-text>
                    <v-card-title primary-title>
                        <span class="headline">Filters</span>
                    </v-card-title>
                    <v-container fluid grid-list-md style="padding: 0 16px 16px 16px">
                        <v-layout row wrap class="field_wrapper">
                            <v-flex xs12 md12>
                                <label>Employee Name</label>
                                <br />
                                <v-text-field name="username" id="username" type="text" v-model="filters.username" placeholder="Enter Employee Name"></v-text-field>
                            </v-flex>
                            <v-flex xs12 md12>
                                <label>Emplpyee ID</label>
                                <br />
                                <v-text-field name="emplplpyee_id" type="text" v-model="filters.employee_id" placeholder="Enter Employee ID"></v-text-field>
                            </v-flex>

                            <v-flex xs12 md12>
                                <label>Date</label>
                                <br />
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field readonly v-bind="attrs" v-on="on" :value="filters.activity_date" placeholder="Enter Activity Date"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.activity_date" @input="menu = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="filter_dialog_box = !filter_dialog_box">Cancel</v-btn>
                    <v-btn small color="error" @click="resetFilters">Reset</v-btn>
                    <v-btn small color="primary" :disabled="!valid" type="submit">Apply
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <!-- Image dialog box -->
    <v-dialog v-model="image_dialog_box" max-width="290" max-height="290" hide-overlay color="white">
        <v-card elevation="0">
            <v-img :src="storage_path() + image_path"></v-img>
        </v-card>
    </v-dialog>
    <v-dialog v-model="changing_dialog_box" max-width="290" max-height="290" hide-overlay color="white">
        <v-card elevation="0">
            <v-card-text>
                <h4 style="color: black;"> Are you sure you want to {{ attendance_status.status }} this attandance</h4>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small @click="changing_dialog_box = !changing_dialog_box; is_changing=false">Cancel</v-btn>
                <v-btn small color="primary" :loading="is_changing" @click="changeStatus()">Change
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import axios from "axios";
import moment from 'moment';
export default {
    computed: {
        msg() {
            const rows = this.selected.length;
            if (rows) {
                return rows + " rows selected";
            } else {
                return " ";
            }

        }
    },
    watch: {
        options: {
            handler() {
                this.getList();
            }
        },
        toggle_one(val) {
            if (val == '0') {
                this.filters.status="Pending";
                if(this.checkPermission('update-attendance'))
                    this.show_select=true;
                else
                    this.show_select=false;
               
                this.getList();
            }
            if (val == '1') {
                this.show_select=false;
                this.filters.status="Approved";
                this.getList();
            }
            if (val == '2') {
                this.show_select=false;
                this.filters.status="Rejected";
                this.getList();
            }
        }

    },
    //----------------- DATA--------------
    data() {
        return {
            toggle_one: 0,
            selected: [],
            approve_attendance: false,
            snackbar: {
                message: "",
                display: false,
                color: "",
                top: true,
                right: true,
                bottom: false,
                left: false
            },

            // filter variable
            menu: "",
            filter_dialog_box: false,
            filters: {
                employee_id: "",
                username: "",
                activity_date: "",
                status:'Pending',
            },
            // Image
            image_dialog_box: false,
            image_path: "",

            // data table variables
            is_loading: false,
            attendance: [],
            headers: [
                { text: "Image", sortable: false },
                { text: "Employee", value: "name", align: "left", sortable: false ,width: "23%"},
                { text: "ID", value: "employee_id", sortable: false },
                { text: "Created At", value: "created_at", sortable: false,width:'10%'},
                { text: "In", value: "checkin", sortable: false },
                { text: "Out", value: "checkout", sortable: false },
                { text: "Hours", value: "hours", sortable: false },
                { text: "Shift", value: "shift_id", sortable: false },
                { text: "Status", value: "status", sortable: false },
                { text: "Action", sortable: false,}
            ],
            auth_user: {},
            total_items: 10,
            page: 0,
            options: {},
            valid: false,

            // Attendance status change
            is_changing: false,
            changing_dialog_box: false,
            attendance_status: {
                id: [],
                status: '',
            },
            show_select:true,
            export_loader:false,

        };
    },
    //----------------- Method--------------
    methods: {
        checkedit(item){
            if(this.checkPermission('update-attendance')==false){
                return true;
            }
            if(item.is_reconsile==1){
            return true;
            }
        },
        getList() {
            this.is_loading = true;
            this.options.filters = Object.assign({}, this.filters);
            axios
                .get(`${process.env.VUE_APP_API_URL}/api/attendance/show`, {
                    params: this.options
                })
                .then(response => {
                    if (response.data.status == 1) {
                        this.attendance = response.data.attendance.data;
                        this.page = response.data.attendance.page;
                        this.total_items = response.data.attendance.total;
                        this.is_loading = false;
                    } else {
                        this.is_loading = false;
                    }
                 }).catch((error) => {
                this.is_loading = false;
                // this.formErrors = error.response.data.error
                })
        },
        attendanceStatus(status) {
            this.attendance_status.status = status;
            this.changing_dialog_box = true;
        },

        changeStatus() {
            this.is_changing = true;
            this.attendance_status.id=[];
            this.selected.forEach(element => {
               this.attendance_status.id.push(element.id);
            });
            axios.post(`${process.env.VUE_APP_API_URL}/api/attendance/update-status`, this.attendance_status)
                .then(response => {
                    this.is_changing = false;
                    this.snackbar.message = response.data.message;
                    this.snackbar.display = true;
                    this.changing_dialog_box = false;
                    this.selected=[];
                    this.getList();
             }).catch((error) => {
                this.is_changing = false;
                this.changing_dialog_box = false;
                this.snackbar.message = "Error occured";
                    this.snackbar.display = true;
                this.getList();
            })
            
        },

        fullWidthImage(item_image) {
            this.image_dialog_box = true;
            this.image_path = item_image;
        },
        filterList() {
            let filter_values = Object.values(this.filters);
            if (filter_values.join("").length > 0) {
                this.getList();
                this.filter_dialog_box = false;
            } else {
                this.$router.replace({
                    name: "attendance"
                });
            }

        },
        resetFilters() {
            this.resetFiltersParameters();
            this.is_filtered = false;
            this.getList();
            this.filter_dialog_box = false;
        },
        resetFiltersParameters: function () {
            for (var key in this.filters) {
                this.filters[key] = "";
            }
        },

        clearFilter: function (filter, key) {
            this.filter_chips[key] = this.filters[key] = "";
            this.filterList();
        },
        applyFiltersOnLoad: function () {
            this.filters.username = this.$route.query.username;
            this.filters.employee_id = this.$route.query.showid;
            this.filters.activity_date = this.$route.query.activity_date;
            this.filters.status = this.$route.query.status;
            this.getList();
        },
        validateBeforeSubmit: function (scope) {
            this.$validator.validateAll(scope).then(result => {
                if (result) {
                    this[scope]();
                }
            });
        },
        
   checkPermission(permission, record = null) {
      let p_record = this.$store.getters.get_permissions.find((p) => p.name == permission);
      if(p_record !== undefined) {
        if(record == null) {
          return true;
        } else {
          switch (p_record.access_to) {
            case "created":
              if(record.user_id == this.$store.getters.get_user.id) {
                return true;
              } else {
                return false;
              }
             
            case "group":
              return true;
              
            case "all":
              return true;
              
            default:
              break;
          }
        }
      } else {
        return false;
      }
    },
  

   download(data) {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Attendance-Report' + Date.now() + '.csv');
            document.body.appendChild(link);
            link.click();
        },

     exportData() {
             this.export_loader = true;
            this.options.filters = Object.assign({}, this.filters);
            axios
                .get(`${process.env.VUE_APP_API_URL}/api/attendance/export`, {
                    params: this.options
                })
                .then(response => {
                     if(response.status==0){
                        this.snackbar.message = response.data.message;
                        this.snackbar.display = true;
                        this.export_loader = false;
                    }
                       this.download(response.data);
                       this.export_loader = false;
                })
                .catch(error => {
                    this.export_loader = false;
                    this.snackbar.message = "Error Occured";
                    this.snackbar.display = true;
                });
        },
    },
    //----------------- Mounted--------------
    mounted() {
        this.applyFiltersOnLoad();
        this.auth_user = this.$store.getters.get_user;
        this.show_select = this.checkPermission('update-attendance');
        
       
    }
};
</script>
