<template>
<v-container id="regular-tables" fluid tag="section" class="pa-1 full-width-div">
    <v-row>
        <v-flex xs12 class="buttons">
            <v-btn color="success" class="mx-1" to="addHoliday" v-if="checkPermission('create-holidays')">
                Add Holiday
            </v-btn>

            <v-btn color="success" class="mx-1" @click="openImportDialog()" v-if="checkPermission('create-holidays')">
                Import
            </v-btn>
        </v-flex>
    </v-row>
    <base-material-card icon="mdi-clipboard-text" :title="current_year_value" class="px-5 py-3">
        <v-row>
            <v-col cols="12" md="12" class="" style="margin-left: 250px; margin-top: -55px;">
                <v-chip-group active-class="white--text primary" row @change="getList" v-model="year">
                    <v-chip :value="current_year">
                        Current Year
                    </v-chip>
                    <v-chip :value="next_year">
                        Next Year
                    </v-chip>
                </v-chip-group>
            </v-col>
        </v-row>
        <v-data-table :headers="headers" :items="holidays" item-key="id" :sort-by="['created_at']" :sort-desc="[true]" :options.sync="options" :server-items-length="total_items" :loading="is_loading" no-results-text="Loading... Please wait">
            <template v-slot:progress>
                <v-progress-linear color="success" indeterminate></v-progress-linear>
            </template>
            <template v-slot:loading>
                <v-layout row wrap justify-center class="text-xs-center">
                    Loading... Please wait
                </v-layout>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" :key="item.id">

                        <td v-if="checkPermission('update-holidays')">
                            <router-link v-if="item.id" class="ml-1" :to="{ name: 'Holiday Detail', params: { id: item.id } }">
                                {{ item.name }}
                            </router-link>
                        </td>
                        <td v-else>
                            {{ item.name }}
                        </td>
                        <td>{{ item.type.toUpperCase() }}</td>
                        <td>{{ formatDate(item.date) }}</td>

                        <td>
                            <v-btn :disabled="!checkPermission('update-holidays')" text icon color="blue lighten-2" :to="`holidays/edit/${item.id}`">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn :disabled="!checkPermission('delete-holidays')" text icon color="red lighten-2" @click="
                    delete_holiday_box = !delete_holiday_box;
                    selected_holiday_id = item.id;
                  ">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>

                </tbody>
            </template>
            <template slot="no-data-text">
                <v-layout row wrap justify-center class="text-xs-center" v-if="is_loading">
                    Loading... Please wait
                </v-layout>
                <v-layout row wrap justify-center class="text-xs-center" v-else>
                    No data available
                </v-layout>
            </template>
        </v-data-table>
    </base-material-card>

    <!-- Filter Dialog Box -->

    <v-dialog v-model="filter_dialog_box" scrollable :overlay="false" max-width="450px" hide-overlay transition="dialog-transition">
        <v-card style="height:100%">
            <v-form id="filterList" @submit.prevent="validateBeforeSubmit('filterList')" data-vv-scope="filterList" v-model="valid">
                <v-card-text>
                    <v-card-title primary-title>
                        <span class="headline">Filters</span>
                    </v-card-title>
                    <v-container fluid grid-list-md style="padding: 0 16px 16px 16px">
                        <v-layout row wrap class="field_wrapper">
                            <v-flex xs12 md12>
                                <label>Name</label>
                                <br />
                                <v-autocomplete v-model="filters.holiday_id" :items="holiday_list" item-text="name" item-value="id" placeholder="Enter Name" class="purple-input">
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12 md12>
                                <label>Type</label>
                                <br />
                                <v-select :items="type_list" item-text="text" item-value="value" v-model="filters.type" placeholder="Select Type"></v-select>
                            </v-flex>

                            <v-flex xs12 md12>
                                <label>Date</label>
                                <br />
                                <v-text-field name="Date" type="date" v-model="filters.Date" placeholder="Enter Date"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="filter_dialog_box = !filter_dialog_box">Cancel</v-btn>
                    <v-btn color="error" @click="resetFilters">Reset</v-btn>
                    <v-btn color="primary" :disabled="!valid" type="submit">Apply Filter</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <!-- Delete Holiday Dialog Box -->
    <v-dialog v-model="delete_holiday_box" scrollable :overlay="false" max-width="450px" transition="dialog-transition" hide-overlay>
        <v-card>
            <v-card-title primary-title>
                <span>Are you sure you wish to delete this Holiday ?</span>
            </v-card-title>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small @click="delete_holiday_box = false">Cancel</v-btn>
                <v-btn small color="primary" @click="deleteHoliday">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="import_dialog_box" scrollable :overlay="false" max-width="650px" transition="dialog-transition" hide-overlay>
        <v-card>
            <v-form @submit.prevent="validateBeforeSubmit('importHolidays')" data-vv-scope="importHolidays" id="holidays" ref="import_holidays" enctype="multipart/form-data">
                <v-card-text>
                    <v-card-title class=" mb-4">
                        <span class="headline">Import Holidays</span>
                    </v-card-title>
                    <v-container fluid grid-list-md style="padding: 0 16px 16px 16px">
                        <v-layout row wrap class="field_wrapper">
                            <v-flex xs12 md7 lg7 sm7 class="mt-3">
                                <v-file-input label="Upload File" outlined dense v-model="file" name="file" id="file" ref="file" v-validate="'required|ext:xlsx,csv'" :error-messages="errors.collect('importHolidays.file')"></v-file-input>
                            </v-flex>
                            <v-flex xs12 md5 lg5 sm5 class="mt-3">
                                <v-chip-group active-class="white--text primary" row v-model="import_year">
                                    <v-chip :value="current_import_year">
                                        Current Year
                                    </v-chip>
                                    <v-chip :value="next_import_year">
                                        Next Year
                                    </v-chip>
                                </v-chip-group>
                            </v-flex>
                            <v-flex>
                                <v-col cols="12">
                                    <v-autocomplete v-model="location_values" :items="location_list" dense chips small-chips label="Select Location" item-text="name" item-value="id" multiple solo></v-autocomplete>
                                </v-col>
                            </v-flex>
                            <v-flex xs12>
                                <a href="/holidays_sample_template.xlsx" v-text="'Download Sample Template'" target="_blank" />
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn small @click="import_dialog_box = false">Cancel</v-btn>
                    <v-btn small color="primary" type="submit" :loading="import_loading" :disabled="import_loading">Import</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import moment from "moment";
export default {
    name: "Holidays",
    watch: {
        options: {
            handler() {
                this.getList();
            }
        }
    },
    data() {
        return {
            current_year_value: "Holidays - " + moment().format("YYYY"),
            year: "current_year",
            current_year: "current_year",
            next_year: "next_year",
            import_year: "current_year",
            current_import_year: "current_year",
            next_import_year: "next_year",
            filter_dialog_box: false,
            type_list: [],
            holiday_list: [],
            is_filtered: false,
            holidays: [],
            total_items: 10,
            page: 0,
            options: {},
            valid: false,
            file: "",
            filters: {
                holiday_id: "",
                type: "",
                date: ""
            },
            filter_chips: {
                holiday_id: "",
                type: "",
                date: ""
            },
            document: "",
            headers: [
                { text: "Name", value: "name", align: "left", sortable: false },
                { text: "Type", value: "type", align: "left" },
                { text: "Date", value: "date", align: "left", sortable: false },

                { text: "Action" }
            ],
            delete_holiday_box: false,
            selected_holiday_id: "",
            is_loading: false,
            import_dialog_box: false,
            import_loading: false,

            location_list: [],
            location_values: [],
            value: null,
        };
    },

    methods: {
        getList() {
            if (this.year == "next_year") {
                this.current_year_value =
                    "Holidays - " +
                    moment()
                    .add("years", 1)
                    .format("YYYY");
            } else {
                this.current_year_value = "Holidays - " + moment().format("YYYY");
            }
            this.is_loading = true;
            this.filters.year = this.year;

            this.options.filters = Object.assign({}, this.filters);

            axios
                .get(`${process.env.VUE_APP_API_URL}/api/holidays`, {
                    params: this.options
                })
                .then(response => {
                    if (response.data.status == 1) {
                        this.holidays = response.data.holidays.data;

                        this.total_items = response.data.holidays.total;
                        this.is_loading = false;
                    }
                })
                .catch(error => {});
        },
        deleteHoliday: function () {
            this.$axios
                .post(
                    `${process.env.VUE_APP_API_URL}/api/holidays/` +
                    this.selected_holiday_id +
                    `/delete`
                )
                .then(response => {
                    this.response_message = response.data.message;

                    if (response.data.status == 1) {
                        this.delete_holiday_box = false;
                        this.getList();
                    } else if (response.data.status == 2) {
                        this.delete_holiday_box = false;
                        this.getList();
                    }
                    // this.snackBar.message = response.data.message;
                    // this.snackBar.show = true;
                })
                .catch(error => {});
        },
        getHolidayList() {
            axios
                .get(`${process.env.VUE_APP_API_URL}/api/holidays/holidayList`)
                .then(response => {
                    if (response.data.status == 1) {
                        this.holiday_list = response.data.holidays;
                    }
                })
                .catch(error => {});
        },
        openImportDialog: function () {
            this.$validator.reset();
            // this.$refs.import_holiday.reset();

            this.import_dialog_box = true;
        },
        handleFileUpload() {
            this.file = this.$refs.file.file[0];
        },
        importHolidays: function () {
            // console.log('import');
            this.submit_loader = true;
            this.import_loading = true;

            let formData = new FormData();
            formData.append("file", this.file);
            formData.append('locations', this.location_values);
            formData.append("import_year", this.import_year);
            axios
                .post(
                    `${process.env.VUE_APP_API_URL}/api/holidays/importHolidays`,
                    formData
                )
                .then(response => {
                    if (response.data.status == 1) {
                        this.message = response.data.message;
                        // this.snackBar.message = response.data.message;
                        // this.snackBar.show = true;
                        this.import_dialog_box = false;
                        this.import_loading = false;
                        this.getList();
                    } else if (response.data.status == 0) {
                        this.message = response.data.message;
                        // this.snackBar.message = response.data.message;
                        // this.snackBar.show = true;
                        this.import_dialog_box = false;
                        this.import_loading = false;
                    }
                })
                .catch(error => {
                    this.formErrors = error.response.data.errors;
                })
                .finally(() => {
                    this.import_loading = false;
                });
        },
        filterList() {
            let filter_values = Object.values(this.filters);
            if (filter_values.join("").length > 0) {
                this.$router.replace({
                    name: "Holidays",
                    query: {
                        filters: true,
                        holiday_id: this.filters.holiday_id,
                        type: this.filters.type,
                        date: this.filters.date
                    }
                });
                this.displayFilterChips(this.filters);
                this.is_filtered = true;
            } else {
                this.$router.replace({
                    name: "Holidays"
                });
            }
            this.getList();
            this.filter_dialog_box = false;
        },
        resetFilters() {
            this.$router.replace({
                name: "Holidays",
                query: {}
            });
            this.resetFiltersParameters();
            this.is_filtered = false;
            this.getList();
            this.filter_dialog_box = false;
        },
        resetFiltersParameters: function () {
            for (var key in this.filters) {
                this.filters[key] = "";
            }
        },
        displayFilterChips: function (filters) {
            if (this.$route.query.filters) {
                this.filter_chips.holiday_id = this.filters.holiday_id;
                // this.filter_chips.assigned_to = this.filters.assigned_to;
                this.filter_chips.type = this.filters.type;

                this.filter_chips.date = this.filters.date;

                this.is_filtered = true;
            }
        },
        clearFilter: function (filter, key) {
            this.filter_chips[key] = this.filters[key] = "";
            this.filterList();
        },
        applyFiltersOnLoad: function () {
            this.filters.holiday_id = this.$route.query.userholiday_id;
            this.filters.type = this.$route.query.type;
            this.filters.date = this.$route.query.date;

            this.displayFilterChips(this.filters);
        },
        getLocationList() {

            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/locations/locationListForGroups`).then((response) => {
                if (response.data.status == 1) {
                    this.location_list = response.data.location_list;
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        validateBeforeSubmit: function (scope) {
            this.$validator.validateAll(scope).then(result => {
                if (result) {
                    this[scope]();
                }
            });
        }
    },
    mounted() {
        // this.applyFiltersOnLoad();
        this.getList();
        // this.getHolidayList();
        this.getLocationList();
    }
};
</script>
