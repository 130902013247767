<template>
<div>
    <v-snackbar v-model="snackbar.display" :color="snackbar.color" :top="snackbar.top" :bottom="snackbar.bottom" :left="snackbar.left" :right="snackbar.right">
        {{ snackbar.message }}
    </v-snackbar>
    <v-row justify="center">
        <v-col cols="12" md="10">
            <base-material-card>
              <v-progress-linear :indeterminate="data_loading" class="pt-0"></v-progress-linear>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                        Edit Attendance
                    </div>
                </template>
             <v-form 
             id="updateAttendance" 
             @submit.prevent="validateBeforeSubmit('updateAttendance')" 
             data-vv-scope="updateAttendance" >
                    <v-container class="pt-8">
                        <v-row>
                            <v-col cols="6">
                                <label>Name <span style="color:red">*</span></label>
                                 <v-text-field v-model="details.user.name" readonly ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <label>Date <span style="color:red">*</span></label>
                                <v-menu 
                                v-model="date_menu" 
                                :close-on-content-click="false"
                                transition="scale-transition" 
                                offset-y 
                                min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field 
                                        readonly 
                                        :value="details.date"
                                        v-bind="attrs" 
                                        v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker 
                                       v-model="details.date" 
                                       readonly
                                       :max="new Date().toISOString().substr(0, 10)" 
                                       @input="date_menu = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            
                            <v-col cols="6">
                                <label>Check-in Time <span style="color:red">*</span></label>
                                <v-menu 
                                  ref="menu1" 
                                  v-model="time_menu" 
                                  :close-on-content-click="false" 
                                  :nudge-right="40" 
                                  :return-value.sync="details.first_check_in" 
                                  transition="scale-transition" 
                                  offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field 
                                        v-model="details.first_check_in" 
                                        readonly 
                                        v-on="on" 
                                        name="Start Time"></v-text-field>
                                    </template>
                                    <v-time-picker v-if="time_menu" 
                                    v-model="details.first_check_in" 
                                    @click:minute="$refs.menu1.save(details.first_check_in)">
                                    </v-time-picker>
                                </v-menu>
                            </v-col>
                             <v-col cols="6">
                                <label>Check-out Time <span style="color:red">*</span></label>
                                <v-menu 
                                  ref="menu2" 
                                  v-model="time_menu2" 
                                  :close-on-content-click="false" 
                                  :nudge-right="40" 
                                  :return-value.sync="details.last_check_out" 
                                  transition="scale-transition" 
                                  offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field 
                                        v-model="details.last_check_out" 
                                        readonly 
                                        v-on="on" 
                                        name="Start Time"></v-text-field>
                                    </template>
                                    <v-time-picker v-if="time_menu2" 
                                    v-model="details.last_check_out" 
                                    :min="details.first_check_in"
                                    @click:minute="$refs.menu2.save(details.last_check_out)">
                                    </v-time-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" class="text-right">
                                <v-btn to="/attendance" color="#FB8C00">
                                    Back
                                </v-btn>
                                <v-btn color="primary" :loading="loading" :disabled="loading" type="submit">
                                    Add
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
             </v-form>
             
            </base-material-card>
        </v-col>
    </v-row>
</div>
</template>

<script>
import moment from 'moment';
export default {
    data() {
        return {
            snackbar: {
                message: "",
                display: false,
                top: true,
            },

            data_loading:false,
            loading:false,
            
            details:{ user:{}},
            attendance:{},

            date_menu:'',
            time_menu:false,
            time_menu2:false,
        }
    },
    methods:{
         getDetails() {
             this.data_loading= true;
            this.$axios
            .get(`${process.env.VUE_APP_API_URL}/api/attendance/`+this.$route.params.id)
            .then(response => {
                this.data_loading= false;
                    if (response.data.status == 1) {
                        this.attendance = response.data.attendance;  

                        this.details.id = this.attendance.id;
                        this.details.user.name = this.attendance.user.name;
                        this.details.date = this.attendance.date;

                        if(this.attendance.first_check_in==null){
                         this.details.first_check_in =moment('2020-02-07 00:00').format('HH:mm');
                        }else{
                            this.details.first_check_in =moment(this.attendance.first_check_in).format('HH:mm');
                        }
                        
                        if(this.attendance.last_check_out==null){
                          this.details.last_check_out =moment('2020-02-07 00:00').format('HH:mm');
                        }else{
                            this.details.last_check_out =moment(this.attendance.last_check_out).format('HH:mm');
                        }
                        
                    }
                })
                .catch(error => {
                this.data_loading= false;
                     this.snackbar.message="Error Occured.";
                     this.snackbar.display= true;
                });
        },
          validateBeforeSubmit: function (scope) {
            this.$validator.validateAll(scope).then(result => {
                if (result) {
                    this[scope]();
                }
            });
        },
        updateAttendance(){
             this.loading= true;
             this.$axios
            .post(`${process.env.VUE_APP_API_URL}/api/attendance/`+this.$route.params.id, this.details)
            .then(response => {
                this.oading= false;
                    if (response.data.status == 1) {
                       this.snackbar.message=response.data.message;
                       this.snackbar.display= true; 
                       this.$router.push({ name: 'Attendance' })
                    }
                     if (response.data.status == 0) {
                       this.snackbar.message=response.data.message;
                       this.snackbar.display= true; 
                    }
                })
                .catch(error => {
                this.loading= false;
                     this.snackbar.message="Error Occured.";
                     this.snackbar.display= true;
                });
        
        }
    },
    mounted(){
       this.getDetails();
    }
}
</script>
