<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
    class="pa-1 full-width-div"
  >
    <!-- <base-v-component
      heading="Simple Tables"
      link="components/simple-tables"
    /> -->
<!--     <v-btn
                    color="success"
                    class="mr-0"
                    to="/add/role"
                  >
                    Add Role
                  </v-btn> -->
    <base-material-card
      icon="mdi-clipboard-text"
      title="Roles"
      class="px-5 py-3"
    >
    <v-data-table
        :headers="headers"
        :items="roles"
        item-key="id"
        :sort-by="['updated_at']"
        :sort-desc="[true]"
       
        :options.sync="options" 
        :server-items-length="total_items" 
        
        no-results-text="Loading... Please wait"
        
      >
        <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="item in items"
            :key="item.name"
          >
          
            <td>{{ item.display_name }}</td>
            
            <td>{{item.description}}</td>
            <td>{{item.users_count}}</td>
           
            
<!--             <td><v-btn
              
              text
              icon
              large
              color="blue lighten-2"
              :to="`roles/edit/${item.id}`"
              >
              <v-icon large>mdi-pencil</v-icon>
            </v-btn>
          <v-btn
              
              text
              icon
              large
              color="red lighten-2"

              >
              <v-icon large>mdi-delete</v-icon>
            </v-btn></td> -->
            
          </tr>
        </tbody>
      </template>
      </v-data-table>
     
    </base-material-card>
  </v-container>
</template>
<script>
  import axios from 'axios'
  import { mapActions } from 'vuex'
  export default {
    name: 'Roles',
    watch: {
        options: {
            handler() {
                this.getList();
            }
        },

    },
    data() {
      return {
       
        roles: [],
        counter: 1,
        pagination: {
          current: 1,
          total: 0,
        },
        filters:{
          display_name:"", 
        },
        
        is_filtered:false,
        filter_chips:{
          display_name:"",
        },
        total_items:10,
        options:{},
        headers:[
        { text: "Display Name", value: "display_name", align: "left", sortable: false },
        { text: "Description", value: "description", align: "left", sortable: false },
        { text: "Users Count", value: "users_count", align: "left" },
        
        ],
        
      }
    },
    
    methods: {
      
      getList() {
        axios.get(`${process.env.VUE_APP_API_URL}/api/roles`).then((response) => {
          if(response.data.status == 1){
            this.roles = response.data.roles.data;
          
            this.total_items = response.data.roles.total;

          }
           
        })
      },
      filterList() {
      let filter_values = Object.values(this.filters);
            if (filter_values.join('').length > 0) {
                this.$router.replace({
                    name: "Roles",
                    query: {
                        filters: true,
                        display_name: this.filters.display_name,
                        

                    }
                });
                this.displayFilterChips(this.filters);
                this.is_filtered = true;
            } else {
                this.$router.replace({
                    name: "Roles"
                });
            }
            this.getList();
            this.filter_dialog_box = false;
    },
    resetFilters() {
      this.$router.replace({
        name: "Roles",
        query: {}
      });
      this.resetFiltersParameters();
      this.is_filtered =  false;
      this.getList();
      this.filter_dialog_box = false;
    },
    resetFiltersParameters: function() {
      for (var key in this.filters) {
        this.filters[key] = "";
      }
    },
    displayFilterChips:function(filters)
    {  
      
      if(this.$route.query.filters)
      {
       
        this.filter_chips.display_name = filters.display_name;
        
        this.is_filtered = true;
        
      }
    },
    
    clearFilter:function(filter,key)
    {
      this.filter_chips[key] = this.filters[key] = '';
      this.filterList();
    },
    applyFiltersOnLoad:function()
    {
        
        this.filters.display_name = this.$route.query.display_name;
        
        
        
        this.displayFilterChips(this.filters)
    },

    validateBeforeSubmit: function(scope) {
      
      this.$validator.validateAll(scope).then(result => {
        if (result) {
          
          this[scope]();
        }
      });
    },
      
    },
  };
</script>
