<template>
<v-container id="dashboard" fluid tag="section" class="pa-1 full-width-div">
    <v-row>

        <v-col cols="12" sm="6" lg="3">
            <router-link :to="{ name: 'Employees', query: { status: 'active'}}">
            <base-material-stats-card color="success" icon="mdi-account-multiple" title="Employees" :value="active_employee_count" sub-icon="mdi-account" sub-text="Active Employee" />
            <v-progress-linear :indeterminate="loader.employee_loader" style="margin-top: -19px;"></v-progress-linear>
            </router-link>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
            <router-link :to="{ name: 'Attendance', query: { activity_date: date}}">
            <base-material-stats-card color="orange" icon="mdi-map-marker-plus" title="Attendance" :value="todays_attendance_count" sub-icon="mdi-map-marker" sub-text="Todays Attendance" />
            <v-progress-linear :indeterminate="loader.attendance_loader" style="margin-top: -19px;"></v-progress-linear>
            </router-link>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
            <router-link :to="{ name: 'Activities', query: { activity_date: date, unauthorized:true}}">
                <base-material-stats-card color="orange darken-1" icon="mdi-map-marker-question" title="Activities" :value="unauthorized_activity_count" sub-icon="mdi-map-marker" sub-text="Unauthorized Activities" />
                <v-progress-linear :indeterminate="loader.activity_loader" style="margin-top: -19px;"></v-progress-linear>
            </router-link>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
            <router-link :to="{ name: 'Activities', query: { late: true , activity_date: date}}">
                <base-material-stats-card color="red darken-1" icon="mdi-account-clock" title="Late Check-in" :value="late_checkin_count" sub-icon="mdi-map-marker" sub-text="Todays Late CheckIn's" />
                <v-progress-linear :indeterminate="loader.late_check_in_loader" style="margin-top: -19px;"></v-progress-linear>
            </router-link>
        </v-col>
    </v-row>
    <v-row>
         <v-col cols="12" lg="6">
            <graph />
        </v-col>
        <v-col cols="12" lg="6">
            <employeegraph />
        </v-col>
        
    </v-row>
     <v-row>
         <v-col cols="12" lg="6">
            <averagegraph />
        </v-col>
        <v-col cols="12" lg="6">
            <attendancebyhour />
        </v-col>
        
    </v-row>
    <!-- <v-row>
        <v-col cols="12" lg="12">
            <base-material-chart-card :data="dailySalesChart.data" :options="dailySalesChart.options" color="success" hover-reveal type="Line" class="zero_padding">
                <h4 class="card-title font-weight-light mt-2 ml-2">
                    Monthly Attendance
                </h4>
            </base-material-chart-card>
        </v-col>
    </v-row> -->
    <v-row>
        <v-col cols="12" md="6">
            <base-material-card color="warning" class="px-5 py-3">
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                        Today's Activities
                    </div>

                </template>

                <v-data-table :headers="headers" :items="todays_activities" :items-per-page="itemsPerPage">
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr v-for="item in items" :key="item.name">
                                <td v-if="item.image">
                                    <v-avatar size="36">
                                        <img :src="storage_path()+item.image.image" @click="fullWidthImage(item.image.image)" @error="setAltImg">
                                    </v-avatar>
                                </td>
                                <td v-else>-</td>
                                <td> {{ toTitleCase(item.type).replace(/_/g, " ")}}</td>
                                <td>
                                    <router-link :to="{ name:'Employee Detail',params: { id: item.user.id } }">{{ item.user.name }}</router-link>
                                </td>
                                <td>{{item.location_status}}</td>

                                <td v-if="item.datetime">{{ formatTime(item.datetime)}}</td>
                                <td v-else>-</td>

                            </tr>
                        </tbody>
                    </template>
                </v-data-table>

            </base-material-card>
        </v-col>
        <v-col cols="12" md="6">
            <base-material-card color="success" class="px-5 py-3">
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                        Upcoming Leaves
                    </div>

                </template>

                <v-data-table :headers="leave_headers" :items="upcoming_leaves" :items-per-page="itemsPerPage">
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr v-for="item in items" :key="item.name">

                                <td v-if="item.user">
                                    <router-link :to="{ name:'Employee Detail',params: { id: item.user.id } }">{{ item.user.name }}</router-link>
                                </td>
                                <td v-else>-</td>
                                <td>
                                    <v-chip :text-color="getStatusColor(item.approval_status,leave_status_list)" :color="getStatusColor(item.approval_status,leave_status_list)">
                                        {{toTitleCase(item.approval_status)}}
                                    </v-chip>

                                </td>
                                <!-- <td v-if="item.duration_type =='half_day'">{{ toTitleCase(item.duration_type).replace(/_/g, " ")}} [{{toTitleCase(item.start_session).replace(/_/g, " ")}}]</td>
                                <td v-else>{{ toTitleCase(item.duration_type).replace(/_/g, " ")}}</td> -->

                                <td v-if="item.start_date">{{ formatDate(item.start_date)}}</td>
                                <td v-else>-</td>
                                <td v-if="item.end_date">{{ formatDate(item.start_date)}}</td>
                                <td v-else>-</td>

                            </tr>
                        </tbody>
                    </template>
                </v-data-table>

            </base-material-card>
        </v-col>
    </v-row>
    <v-dialog v-model="image_dialog_box" max-width="290" max-height="290" hide-overlay color="white">
        <v-card elevation="0">
            <v-img :src="storage_path()+image_path"></v-img>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import axios from 'axios';
import employeegraph from "../dashboard/dashboard/EmployeeGraph.vue";
import graph from "../dashboard/dashboard/Graph.vue";
import averagegraph from "../dashboard/dashboard/AverageGraph.vue";
import attendancebyhour from"../dashboard/dashboard/AttendanceByHour.vue";
export default {
    components: {
        employeegraph,
        graph,
        averagegraph,
        attendancebyhour

    },
    name: 'DashboardDashboard',

    data() {
        return {
            dailySalesChart: {
                data: {
                    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
                    series: [
                        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    ],
                },
                options: {
                    lineSmooth: this.$chartist.Interpolation.cardinal({
                        tension: 0,
                    }),

                    chartPadding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },

                    chartHover: {
                        mode: 'nearest',
                        intersect: true,
                    },
                    // plugins: [
                    //     this.$chartist.plugins.tooltip({
                    //         anchorToPoint: true,
                    //         appendToBody: true
                    //     })
                    // ]
                },
            },
            itemsPerPage: 5,
            date:new Date().toJSON().slice(0,10).replace(/-/g,'/'),
            headers: [{
                    sortable: false,
                    text: 'Image',
                    value: 'image',
                },
                {
                    sortable: false,
                    text: 'Activity',
                    value: 'type',
                },
                {
                    sortable: false,
                    text: 'Employee',
                    value: 'employee_name',

                },
                {
                    sortable: false,
                    text: 'Location',
                    value: 'location',

                },
                {
                    sortable: false,
                    text: 'Time',
                    value: 'datetime',

                },
            ],
            leave_headers: [{
                    sortable: false,
                    text: 'Employee',
                    value: 'employee_name',
                },
                {
                    sortable: false,
                    text: 'Status',
                    value: 'approval_status',
                },
                // {
                //     sortable: false,
                //     text: 'Duration',
                //     value: 'duration_type',
                // },
                {
                    sortable: false,
                    text: 'Start Date',
                    value: 'start_date',

                },
                {
                    sortable: false,
                    text: 'End Date',
                    value: 'end_date',

                },

            ],

            tabs: 0,

            list: {
                0: false,
                1: false,
                2: false,
            },
            todays_attendance_count: 0,
            todays_activities: [],
            active_employee_count: 0,
            upcoming_leaves: [],
            unauthorized_activity_count: 0,
            late_checkin_count: 0,
            monthly_attendance: [],
            show_chart: false,
            leave_status_list: [],
            loader: {
                activity_loader: false,
                employee_loader: false,
                leave_loader: false,
                graph_loader: false,
                late_check_in_loader: false,
                unauthorized_activity_loader: false,
                attendance_loader: false,
            },
            image_dialog_box: false,
            image_path: "",

        }
    },

    methods: {

        fetchData() {
            const user = JSON.parse(localStorage.getItem('user'))
            // if (user === null) {
            //   window.location.href = '/#/login'
            // }
        },
        formatDuration(duration, session) {
            if (duration == 'half_day') {
                return "Half Day" + "[" + session + "]";
            } else {
                return duration;
            }
        },
        fullWidthImage(item_image) {

            this.image_dialog_box = true;
            this.image_path = item_image;

        },
        getTodaysAttendaceCount() {
            this.loader.attendance_loader = true;
            axios.get(`${process.env.VUE_APP_API_URL}/api/dashboard/todaysattendancecount`).then((response) => {
                if (response.data.status == 1) {
                    this.todays_attendance_count = response.data.todays_attendance_count;
                    this.loader.attendance_loader = false;

                }

            })
        },
        getMonthlyAttendance() {
            this.loader.graph_loader = true;
            this.show_chart = false;
            axios.get(`${process.env.VUE_APP_API_URL}/api/dashboard/monthlyAttendance`).then((response) => {
                if (response.data.status == 1) {

                    this.dailySalesChart.data.labels = response.data.monthly_attendance.labels;
                    this.dailySalesChart.data.series[0] = response.data.monthly_attendance.attendance;

                    this.show_chart = true;
                    this.loader.graph_loader = false;

                }

            })
        },
        getUpcomingLeaves() {
            this.loader.leave_loader = true;
            axios.get(`${process.env.VUE_APP_API_URL}/api/dashboard/upcomingLeaves`).then((response) => {
                if (response.data.status == 1) {
                    this.upcoming_leaves = response.data.upcoming_leaves;
                    this.loader.leave_loader = false;

                }

            })
        },
        getActiveEmployeeCount() {
            this.loader.employee_loader = true;

            axios.get(`${process.env.VUE_APP_API_URL}/api/dashboard/activeemployeecount`).then((response) => {
                if (response.data.status == 1) {
                    this.active_employee_count = response.data.active_employee_count;
                    this.loader.employee_loader = false;

                }

            })
        },
        getTodayLateCheckinCount() {
            this.loader.late_check_in_loader = true;
            axios.get(`${process.env.VUE_APP_API_URL}/api/dashboard/todayslatecheckincount`).then((response) => {
                if (response.data.status == 1) {
                    this.late_checkin_count = response.data.late_checkin_count;
                    this.loader.late_check_in_loader = false;

                }

            })
        },
        getTodaysActivities() {
            this.loader.activity_loader = true;
            axios.get(`${process.env.VUE_APP_API_URL}/api/dashboard/todaysactivities`).then((response) => {
                if (response.data.status == 1) {
                    this.todays_activities = response.data.todays_activities;
                    this.loader.activity_loader = false;

                }

            })
        },
        getLeaveStatusList() {
            axios.get(`${process.env.VUE_APP_API_URL}/api/configurations/getLeaveStatus`).then((response) => {
                if (response.data.status == 1) {
                    this.leave_status_list = response.data.leave_status_list;

                }
            }).catch((error) => {

                console.log(error)
            })
        },
        getTodaysUnauthorizedActivityCount() {
            this.loader.unauthorized_activity_loader = true;
            axios.get(`${process.env.VUE_APP_API_URL}/api/dashboard/todaysunauthorizedactivitycount`).then((response) => {
                if (response.data.status == 1) {
                    this.unauthorized_activity_count = response.data.unauthorized_activity_count;
                    this.loader.activity_loader = false;

                }

            })
        },
        complete(index) {
            this.list[index] = !this.list[index]
        },
    },
    mounted() {
        this.getLeaveStatusList();
        this.getTodaysAttendaceCount();
        this.getTodaysUnauthorizedActivityCount();
        this.getTodaysActivities();
        this.getActiveEmployeeCount();
        this.getTodayLateCheckinCount();
        this.getUpcomingLeaves();
        this.getMonthlyAttendance();
    }
};
</script>

<style>
.ct-label {
    font-size: 10px !important;
}

.zero_padding .text-start {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
</style>
