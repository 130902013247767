<template>
<div>
    <div v-if="loader" style="position:absolute; top:0; right:0; bottom:0; left:0; padding-top: 20%; padding-left: 45%;background-color:rgba(255,255,255,0.31)">
        <div class="text-xs-center">
            <v-progress-circular color="success" class="text-xs-center my-5" :size="100" :width="10" indeterminate></v-progress-circular>

        </div>
    </div>

    <v-container id="user-profile" fluid tag="section" class="pa-1" v-if="loader==false">
        <v-row>
            <v-col cols="12" md="6">
                <base-material-card>
                    <template v-slot:heading>
                        <v-row>
                            <v-col cols="4">
                                <div class="display-2 font-weight-light">
                                    Leave Detail
                                </div>
                            </v-col>
                        </v-row>
                    </template>

                    <v-card-text class="py-10">
                        <v-row>
                            <v-col cols="3" class="pt-0 pb-0">
                                <p class="font-weight-light grey--text">Type:</p>
                            </v-col>
                            <v-col cols="3" class="pt-0 pb-0" v-if="details.leave_type">
                                <p>{{details.leave_type.value}}</p>
                            </v-col>
                            <v-col cols="3" class="pt-0 pb-0" v-else>-</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3" class="pt-0 pb-0">
                                <p class="font-weight-light grey--text">Status:</p>
                            </v-col>
                            <v-col cols="3" class="pt-0 pb-0" v-if="details.approval_status">
                                <p>{{toTitleCase(details.approval_status)}}</p>
                            </v-col>
                            <v-col cols="3" class="pt-0 pb-0" v-else>-</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3" class="pt-0 pb-0">
                                <p class="font-weight-light grey--text">Leave Date:</p>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0">
                                <p>{{formatDate(details.start_date)}} ------ {{formatDate(details.end_date)}}</p>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="3" class="pt-0 pb-0">
                                <p class="font-weight-light grey--text">Duration:</p>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0" v-if="details.duration_type">
                                <p>{{ toTitleCase(details.duration_type).replace(/_/g, " ")}}</p>
                            </v-col>
                            <v-col cols="3" class="pt-0 pb-0" v-else>-</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3" class="pt-0 pb-0">
                                <p class="font-weight-light grey--text">Applied On:</p>
                            </v-col>
                            <v-col cols="9" class="pt-0 pb-0">
                                <p>{{formatDateTime(details.created_at)}}</p>
                            </v-col>
                            <!-- <v-col cols="3" class="pt-0 pb-0" >-</v-col> -->
                        </v-row>
                        <v-row>

                            <v-col cols="3" class="pt-0 pb-0">
                                <p class="font-weight-light grey--text">Reason:</p>
                            </v-col>
                            <v-col cols="9" class="pt-0 pb-0">
                                <p>{{details.reason}}</p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </base-material-card>
                <v-card>
                    <v-card-text class="py-10">
                        <v-row>
                            <v-col cols="3" class="pt-0 pb-0">
                                <p class="font-weight-light grey--text">Employee Name:</p>
                            </v-col>
                            <v-col cols="3" class="pt-0 pb-0" v-if="details.user">
                                <p>{{details.user.name}}</p>
                            </v-col>
                            <v-col cols="3" class="pt-0 pb-0" v-else>-</v-col>

                            <v-col cols="3" class="pt-0 pb-0">
                                <p class="font-weight-light grey--text">Probation End:</p>
                            </v-col>
                            <v-col cols="3" class="pt-0 pb-0" v-if="details.user">
                                <p v-if="details.user.probation_end_date!==null">{{formatDate(details.user.probation_end_date)}}</p>
                                <p v-else>-</p>
                           
                            </v-col>

                            <v-col cols="3" class="pt-0 pb-0" v-else>-</v-col>

                            <!-- <v-col cols="3" class="pt-0 pb-0"><p class="font-weight-light grey--text" >Leave available:</p></v-col>
                  <v-col cols="3" class="pt-0 pb-0" ><p>{{leave_count}}</p></v-col> -->
                            <!-- <v-col cols="3" class="pt-0 pb-0" >-</v-col> -->

                            <!-- <v-col cols="3" class="pt-0 pb-0"><p class="font-weight-light grey--text" >Comp Off:</p></v-col>
                  <v-col cols="3" class="pt-0 pb-0" ><p>-</p></v-col> -->

                        </v-row>
                    </v-card-text>
                </v-card>
                <v-row class="text-right">
                    <v-col cols="12">
                        <div class="display-2 ">
                            <v-btn class="text-right ml-5" color="#FB8C00 " @click="cancelLeave()" 
                            :loading="cancleloade"
                            :disabled="details.approval_status =='cancelled'||  details.approval_status =='approved' || details.approval_status =='rejected'">
                                Cancel Leave
                            </v-btn>
                            <v-btn class="text-right  " color="blue lighten-1" :to="{ name:'Leave Detail',params: { id: details.id } }" :disabled="details.approval_status =='cancelled' || details.approval_status =='approved' || details.approval_status =='rejected'">
                                Edit Leave
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <base-material-card>
                    <template v-slot:heading>
                        <v-row>
                            <v-col cols="4">
                                <div class="display-2 font-weight-light">
                                    Leave Approval
                                </div>
                            </v-col>
                        </v-row>
                    </template>

                    <v-card-text class="py-10">

                        <v-timeline dense clipped v-for="app in approver" :key="app.id">

                            <v-timeline-item class="mb-4" :color="getcolor(app)" icon-color="grey lighten-2" small>
                                <v-row justify="space-between">
                                    <v-col class="mt-6" cols="2">
                                        <v-avatar size="36" v-if="app.approver.image">
                                            <img :src="storage_path() + app.approver.image.image" />
                                        </v-avatar>
                                        <v-avatar size="36" v-else>
                                            <img src="@/assets/default.png" />
                                        </v-avatar>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <p>Approver Level -{{ app.approver_level}}</p>{{app.approver.name}}
                                                </v-list-item-title>
                                                <v-list-item-subtitle class="pt-3">{{app.approver.employee_id}}</v-list-item-subtitle>

                                                <v-list-item-title class="pt-3 mt-4" v-if="app.status!='pending'">Updated on:</v-list-item-title>
                                                <v-list-item-title class="pt-3 mt-4" v-if="app.status!='pending'">{{formatDateTime(app.updated_at)}}</v-list-item-title>

                                                <v-list-item-subtitle class="pt-3" v-if="app.status!='pending' && app.approved_by_a!==null">
                                                    By: {{app.approved_by_a.name}}
                                                    </v-list-item-subtitle>

                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col class="text-right" cols="4">
                                        <v-chip class="ma-2 pa-4" :color="getcolor(app)" :text-color="gettextcolor(app)">
                                            {{ toTitleCase(app.status)}}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                            </v-timeline-item>

                        </v-timeline>

                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
export default {
    name: 'Leave Detail',

    data() {
        return {
            details: {
                user: {}
            },
            formErrors: {},
            auth_user: {},
            auth_user_role: "",
            leave_count: null,
            approver: [],
            loader: false,
            cancleloade:false,

        }
    },
    methods: {
        ...mapActions(['userAuth']),

        validateBeforeSubmit: function (scope) {

            this.$validator.validateAll(scope).then(result => {
                if (result) {

                    this[scope]();
                }
            });
        },

        getcolor(app) {
            if (app.status == 'pending') { return '#f39e337a' } else if (app.status == 'approved') { return 'green' } else if (app.status == 'rejected') { return 'red' } else { return '#ff980075' }
        },

        gettextcolor(app) {
            if (app.status == 'pending') { return '#a76818f7' } else if (app.status == 'approved') { return 'green' } else if (app.status == 'rejected') { return 'red' } else { return 'orange' }
        },
        getDetails() {
            this.loader = true;
            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/leaves/` + this.$route.params.id)
                .then(response => {
                    if (response.data.status == 1) {
                        this.loader = false;
                        this.details = response.data.leave;
                        this.leave_count = response.data.leave_count;
                    }
                })
                .catch(error => {
                    this.loader = false;
                });
        },
        approveLeave() {
            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/leaves/approveLeave`, { id: this.$route.params.id })
                .then(response => {
                    if (response.data.status == 1) {

                        this.getDetails();

                    }
                })
                .catch(error => {

                });
        },

        rejectLeave() {
            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/leaves/rejectLeave`, { id: this.$route.params.id })
                .then(response => {
                    if (response.data.status == 1) {

                        this.getDetails();

                    }
                })
                .catch(error => {

                });
        },
        cancelLeave() {
            this.cancleloade=true;
            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/leaves/cancelLeave`, { id: this.$route.params.id })
                .then(response => {
                    if (response.data.status == 1) {
                        this.cancleloade=false;

                        this.getDetails();
                        this.getApprover();
                    }
                })
                .catch(error => {
                        this.cancleloade=false;
                   
                });
        },
        getApprover() {
            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/leaves/approver/` + this.$route.params.id)
                .then(response => {
                    if (response.data.status == 1) {
                        this.approver = response.data.approver;
                    }
                })
                .catch(error => {

                });
        }
    },
    mounted() {
        this.getDetails();
        this.getApprover();
        this.auth_user = this.$store.getters.get_user;

        if (this.auth_user) {
            this.auth_user_role = this.auth_user.roles[0].name

        }
    }
};
</script>
