<template>
<v-container id="user-profile" fluid tag="section" class="ma-0 pa-0">
    <v-row>
        <v-col cols="12" md="12">
            <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                        Add Employee
                    </div>
                </template>

                <v-form id="addUser" @submit.prevent="validateBeforeSubmit('addUser')" data-vv-scope="addUser" ref="addUser" v-model="valid" class="pt-8">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="4">
                                <label>Name <span style="color:red">*</span></label>
                                <v-text-field v-model="user.name" class="purple-input" name="Name" v-validate="'required'" :error-messages="errors.collect('addUser.Name')" />
                                <span class="red--text" v-text="formErrors.name" />
                            </v-col>

                            <v-col cols="12" md="4">
                                <label>Employee ID <span style="color:red">*</span></label>
                                <v-text-field v-model="user.employee_id" class="purple-input" name="Employee ID" v-validate="'required'" :error-messages="errors.collect('addUser.Employee ID')" />
                                <span class="red--text" v-text="formErrors.employee_id" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <label>Official Email <span style="color:red">*</span></label>
                                <v-text-field v-model="user.official_email" class="purple-input" :rules="emailRules" name="Official Email" v-validate="'required'" :error-messages="errors.collect('addUser.Official Email')" />
                                <span class="red--text" v-text="formErrors.email" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <label>Secondary Email </label>
                                <v-text-field v-model="user.secondary_email" class="purple-input" />
                                <span class="red--text" v-text="formErrors.secondary_email" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <label>Office Phone <span style="color:red">*</span></label>
                                <v-text-field v-model="user.primary_contact" type="number" class="purple-input" :rules="mobileRules" name="Office Phone" v-validate="'required'" :error-messages="errors.collect('addUser.Office Phone')" />
                                <span class="red--text" v-text="formErrors.primary_contact" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <label>Personal Phone </label>
                                <v-text-field v-model="user.secondary_contact" type="number" class="purple-input" />
                                <span class="red--text" v-text="formErrors.secondary_contact" />
                            </v-col>

                            <v-col cols="12" md="12">
                                <label>Gender <span style="color:red">*</span></label>
                                <v-radio-group v-model="user.gender" row name="Gender" v-validate="'required'" :error-messages="errors.collect('addUser.Gender')">
                                    <v-radio name="active" label="Male" value="Male" />
                                    <v-radio name="active" label="Female" value="Female" />
                                </v-radio-group>
                                <span class="red--text" v-text="formErrors.gender" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <label> Division</label>
                                <v-combobox :items="department_list" v-model="user.department_id"
                                 item-text="name" item-value="id" class="purple-input" name="Department" 
                                 :error-messages="errors.collect('addUser.Department')"></v-combobox>
                                <span class="red--text" v-text="formErrors.department_id"></span>
                            </v-col>
                            <v-col cols="12" md="4">
                                <label>Designation</label>
                                <v-combobox :items="designation_list" v-model="user.designation_id" 
                                item-text="name" item-value="id" class="purple-input" name="Designation"
                                 :error-messages="errors.collect('addUser.Designation')"></v-combobox>

                                <span class="red--text" v-text="formErrors.designation" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <label>Roles <span style="color:red">*</span></label>
                                <v-select :items="role_list" v-model="user.role_id" item-text="display_name" item-value="id" class="purple-input" name="Role" v-validate="'required'" :error-messages="errors.collect('addUser.Role')"></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label>Base Location <span style="color:red">*</span></label>
                                <v-select :items="location_list" v-model="user.base_location_id" item-text="name" item-value="id" class="purple-input" name="Base Location" v-validate="'required'" :error-messages="errors.collect('addUser.Base Location')"></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label>Date of Birth <span style="color:red">*</span></label>
                                <v-menu ref="birthdate_menu" v-model="birthdate_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field ref="picker" :value="formatBirthdayDate" readonly v-bind="attrs" v-on="on" v-validate="'required'" name="Date of Birth" :error-messages="
                          errors.collect('addUser.Date of Birth')
                        "></v-text-field>
                                    </template>
                                    <v-date-picker v-model="user.date_of_birth" :max="new Date().toISOString().substr(0, 10)" min="1950-01-01" @input="birthdate_menu = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" md="6">
                                <label>Date of Joining <span style="color:red">*</span></label>
                                <v-menu v-model="date_joining_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :value="formatJoiningDate" readonly v-bind="attrs" v-on="on" v-validate="'required'" name="Date of Joining" :error-messages="
                          errors.collect('addUser.Date of Joining')
                        "></v-text-field>
                                    </template>
                                    <v-date-picker v-model="user.date_of_joining" @input="date_joining_menu = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label>Probation End Date <span style="color:red">*</span></label>
                                <v-menu v-model="probation_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field readonly v-bind="attrs" v-on="on" :value="formatProbationDate" v-validate="'required'" name="Probation End Date" :error-messages="
                                        errors.collect('addUser.Probation End Date')
                                        "></v-text-field>
                                    </template>
                                    <v-date-picker v-model="user.probation_end_date" :min="user.date_of_joining" @input="probation_menu = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label>Date of Exit</label>
                                <v-menu v-model="dateofexit_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field readonly v-bind="attrs" v-on="on" :value="formatdateofexit" name="Date of Exit" :error-messages="
                                        errors.collect('addUser.Date of Exit')
                                        "></v-text-field>
                                    </template>
                                    <v-date-picker v-model="user.date_of_exit" :min="user.date_of_joining" @input="dateofexit_menu = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="4" v-if="role=='superadmin'">
                                <label> Organization</label>
                                <v-select :items="organization_list" v-model="user.organization_id" item-text="name" item-value="id" class="purple-input" name="Organization"></v-select>
                                <span class="red--text" v-text="formErrors.organization_id" />
                            </v-col>

                            <v-col cols="12" class="text-right">
                                <v-btn color="#FB8C00" to="/employees">
                                    Back
                                </v-btn>
                                <v-btn color="success" type="submit" :loading="loading" :disabled="loading">
                                    Add
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </base-material-card>
        </v-col>
    </v-row>

    <v-snackbar v-model="snackbar.display" :color="snackbar.color" :top="snackbar.top" :bottom="snackbar.bottom" :left="snackbar.left" :right="snackbar.right">
        {{ snackbar.message }}
    </v-snackbar>
</v-container>
</template>

<script>
/* eslint-disable */
import { mapActions } from "vuex";
import moment from "moment";
export default {
    name: "AddEmployee",

    watch: {
        birthdate_menu(val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
        }
    },
    computed: {
        formatdateofexit() {
            return this.user.date_of_exit ?
                moment(this.user.date_of_exit).format("DD-MMM-YY") : "";
        },
        formatProbationDate() {
            return this.user.probation_end_date ?
                moment(this.user.probation_end_date).format("DD-MMM-YY") : "";
        },
        formatBirthdayDate() {
            return this.user.date_of_birth ?
                moment(this.user.date_of_birth).format("DD-MMM-YY") : "";
        },
        formatJoiningDate() {
            return this.user.date_of_joining ?
                moment(this.user.date_of_joining).format("DD-MMM-YY") : "";
        }
    },
    data() {
        return {
            user: {
                name: "",
                official_email: "",
                secondary_email: "",
                employee_id: "",
                designation: "",
                primary_contact: "",
                secondary_contact: "",
                date_of_birth: "",
                gender: "",
                date_of_joining: "",
                probation_end_date: "",
                is_active: "Active",
                role_id: "",
                date_of_exit: '',
            },
            role_list: [],
            designation_list: [],
            department_list: [],
            location_list: [],
            organization_list: [],
            role: '',
            valid: false,
            loading: false,
            formErrors: {},
            mobileRules: [
                v => /^[0-9]+$/.test(v) || "Only Numbers are allowed",
                v => (v && v.length == 10) || "Mobile No must be of 10 digits "
            ],
            snackbar: {
                message: "",
                display: false,
                color: "",
                top: true,
                right: true,
                bottom: false,
                left: false
            },
            emailRules: [
                v =>
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                "E-mail must be valid"
            ],
            birthdate_menu: false,
            date_joining_menu: false,
            probation_menu: false,
            dateofexit_menu: false,
        };
    },
    methods: {
        addUser() {
            this.loading = true;
            this.$axios
                .post(`${process.env.VUE_APP_API_URL}/api/employees`, this.user)
                .then(response => {
                    this.loading = false;
                    if (response.data.status === 1) {
                        this.snackbar.message = response.data.message;
                        this.snackbar.display = true;
                        this.$router.push({
                            name: "Employees",
                            params: { success_message: "Employee Added Successfully" }
                        });
                    } else if (response.data.status === 2) {
                        this.snackbar.message = response.data.message;
                        this.snackbar.display = true;
                    }
                })
                .catch(error => {
                    // console.log(error.response.data.error)
                    // this.formErrors = error.response.data.error
                    this.snackbar.message = "Error Occured";
                    this.snackbar.display = true;
                    this.loading = false;
                });
        },
        getLocationList() {
            this.$axios
                .get(`${process.env.VUE_APP_API_URL}/api/locations/locationlist`)
                .then(response => {
                    if (response.data.status == 1) {
                        this.location_list = response.data.location_list;
                    }
                })
                .catch(error => {});
        },
        getOrganizationList() {
            this.$axios
                .get(`${process.env.VUE_APP_API_URL}/api/organization`)
                .then(response => {
                    if (response.data.status == 1) {
                        this.organization_list = response.data.organization.data;
                    }
                })
                .catch(error => {});
        },
        getDesignationList() {
            this.$axios
                .get(`${process.env.VUE_APP_API_URL}/api/designations/designationList`)
                .then(response => {
                    if (response.data.status == 1) {
                        this.designation_list = response.data.designation_list;
                    }
                })
                .catch(error => {});
        },
        getDepartmentList() {
            this.$axios
                .get(`${process.env.VUE_APP_API_URL}/api/departments/departmentList`)
                .then(response => {
                    if (response.data.status == 1) {
                        this.department_list = response.data.department_list;
                    }
                })
                .catch(error => {});
        },
        getRoleList() {
            this.$axios
                .get(`${process.env.VUE_APP_API_URL}/api/roles/rolelist`)
                .then(response => {
                    if (response.data.status == 1) {

                        if (this.role == 'superadmin') {
                            this.role_list = response.data.role_list;
                        } else {
                            response.data.role_list.forEach(element => {
                                if (element.name == 'superadmin') {} else if (element.name == 'hr') {} else {
                                    this.role_list.push(element);
                                }
                            });
                        }

                    }
                })
                .catch(error => {});
        },
        validateBeforeSubmit: function (scope) {
            this.$validator.validateAll(scope).then(result => {
                if (result) {
                    this[scope]();
                }
            });
        }
    },
    mounted() {
        this.getRoleList();
        this.getLocationList();
        this.getDesignationList();
        this.getDepartmentList();
        this.getOrganizationList();

        let auth_user = this.$store.getters.get_user;
        this.role = auth_user.roles[0].name;

    }
};
</script>
