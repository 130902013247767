<template>
<v-container id="user-profile" fluid tag="section" class="pa-1 full-width-div">
    <v-snackbar v-model="snackbar.display" :color="snackbar.color" :top="snackbar.top" :bottom="snackbar.bottom" :left="snackbar.left" :right="snackbar.right">
        {{ snackbar.message }}
    </v-snackbar>
    <v-row justify="center">
        <v-col cols="12" md="8">
            <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                        Edit Holiday
                    </div>
                    <div class="subtitle-1 font-weight-light">
                        Edit Holiday Here
                    </div>
                </template>

                <v-form id="updateHoliday" @submit.prevent="validateBeforeSubmit('updateHoliday')" data-vv-scope="updateHoliday" v-model="valid">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <label>Name <span style="color:red">*</span></label>
                                <v-text-field v-model="holiday.name" class="purple-input" name="Name" v-validate="'required'" :error-messages="errors.collect('updateHoliday.Name')" />
                                <span class="red--text" v-text="formErrors.date" />
                            </v-col>

                            <v-col cols="12" md="6">
                                <label>Date <span style="color:red">*</span></label>
                                <v-menu v-model="date_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :value="formatDate" readonly v-bind="attrs" v-on="on" v-validate="'required'" :error-messages="errors.collect('updateLeave.Date')"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="holiday.date" @input="date_menu = false">

                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" md="6">
                                <label>Type <span style="color:red">*</span></label>
                                <v-autocomplete v-model="holiday.type" :items="holiday_type_list" item-text="value" item-value="key" class="purple-input" name="Type" v-validate="'required'" :error-messages="errors.collect('updateHoliday.Type')">
                                </v-autocomplete>
                                <span class="red--text" v-text="formErrors.type" />
                            </v-col>

                            <v-col cols="12" class="text-right">
                                <v-btn to="/holidays" color="#FB8C00">
                                    Back
                                </v-btn>
                                <v-btn color="success" type="submit">
                                    Update
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </base-material-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
export default {
    name: 'Holiday',
    computed: {

        formatDate() {
            return this.holiday.date ? moment(this.holiday.date).format('DD-MMM-YY') : ''
        },

    },
    data() {
        return {
            date_menu: false,
            holiday: {
                name: '',
                date: '',
                type: '',
            },
            formErrors: {},
            holiday_type_list: [
                { key: 'optional', value: 'Optional' },
                { key: 'public', value: 'Public' }
            ],
            valid: false,
            snackbar: {
                message: "",
                display: false,
                color: "",
                top: true,
                right: true,
                bottom: false,
                left: false
            },

        }
    },
    created() {

        this.$axios.get(`${process.env.VUE_APP_API_URL}/api/holidays/` +
            this.$route.params.id + `/edit`
        ).then((response) => {
            if (response.data.status == 1) {
                this.holiday = response.data.holiday
            }

        }).catch((error) => {
            console.log(error.response.data.error)

        })
    },
    methods: {

        updateHoliday() {

            this.$axios.patch(`${process.env.VUE_APP_API_URL}/api/holidays/` + this.$route.params.id, this.holiday).then((response) => {
                this.holiday = response.data.holiday
                if (response.data.status == 1) {

                    this.$router.push({ name: 'Holidays' })
                }else{
                    this.snackbar.message = response.data.message;
                    this.snackbar.display = true;
                    this.loading = false;
                }
            }).catch((error) => {
                  this.snackbar.message = "Error Occured";
                    this.snackbar.display = true;
                    this.loading = false;

            })
        },
        validateBeforeSubmit: function (scope) {

            this.$validator.validateAll(scope).then(result => {
                if (result) {

                    this[scope]();
                }
            });
        },
    },
};
</script>
