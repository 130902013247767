<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
    class="pa-1 full-width-div"
  >
  <v-form id="addNotification"  @submit.prevent="validateBeforeSubmit('addNotification')"  data-vv-scope="addNotification" v-model="valid">
    <v-row >
      
      <v-col
        cols="12"
        md="7"
      >
      
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Add Notification 
            </div>
            <div class="subtitle-1 font-weight-light">
              Add Notification Here
            </div>
          </template>

          
            <v-container class="py-0">
              <v-row>
                 <v-col
                  cols="12"
                  md="12"
                  class="py-0"
                >
                <label>Title <span style="color:red">*</span></label>
                  <v-text-field
                    v-model="notification.title"
                    
                    class="purple-input"
                    name="Title"
                    v-validate="'required'"
                    :error-messages="errors.collect('addNotification.Title')"
                  />
                  <span
                    class="red--text"
                    v-text="formErrors.day"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="py-0"
                >
                <label>Description <span style="color:red">*</span></label>
                  <v-text-field
                    v-model="notification.description"
                    class="purple-input"
                    
                    name="Description"
                    v-validate="'required'"
                    :error-messages="errors.collect('addNotification.description')"
                  />
                  <span
                    class="red--text"
                    v-text="formErrors.date"
                  />
                </v-col>
                 <v-col
                cols="12"
                  md="12"
                  class="pb-0 pt-0">
                  <v-checkbox v-model="notification.send_to_all_users" class="mx-2" label="Send to all users"></v-checkbox>
                </v-col>
                <v-col
                cols="12"
                md="12"
                class="py-0"
                v-if="!notification.send_to_all_users">
                  <v-text-field
                          v-model="search"
                          class="purple-input"
                          label="Search username"
                          ></v-text-field>
                        <v-data-table hide-default-headers :items="filteredItems" :items-per-page="itemsPerPage">
                        <template v-slot:body="{ items }">
                        <tbody>
                          <tr
                            v-for="item in items"
                            :key="item.name"
                          >
                            <td>{{item.name }}</td>
                            
                            
                            <td class="text-xs-right">
                              <v-btn v-if="contains(selected_list, item)" v-bind:class="{added: contains(selected_list, item)}" outlined rounded>Added</v-btn>
                              <v-btn v-else :id="item.id" @click="addList(item)" outlined rounded>Add</v-btn>
                            </td>
                            
                          </tr>
                        </tbody>
                      </template>
                          
                        </v-data-table>
                </v-col>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="#FB8C00"
                    to="/notifications"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    color="success"
                    type="submit"
                    :loading="loading"
                    :disabled="loading"
                  >
                    Send
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
        </base-material-card>
      </v-col>
      <v-col 
      cols="12"
        md="5">
      <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
               Selected Users 
            </div>
            
          </template>
          <v-row>
            <v-col
                cols="12"
                  md="12"
                  v-if="!notification.send_to_all_users">
                <v-data-table   hide-default-footer hide-default-headers :items="selected_list">
                  <template hide-headers v-slot:no-data>
                      <div style="text-align:center;font-size: 14px;">
                        <span v-if="notification.send_to_all_user == 'true'">All Users Selected</span>
                        <span v-else >Please select User </span>
                      </div>
                    </template>
                      <template v-slot:body="{ items }">
                        <tbody>
                          <tr
                            v-for="item in items"
                            :key="item.name"
                          >
                            <td>{{item.name }}</td>
                            
                            
                            <td style ="padding:0px;" class="text-xs-right"><v-btn @click="removeList(item)" icon ripple><v-icon color="red lighten-1">mdi-close</v-icon></v-btn></td>
                            
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </v-col>
                    <v-col
                    cols="12"
                      md="12" v-else>
                      
                      <span >All Users Selected</span>
                    
                </v-col>
          </v-row>
        </base-material-card>
       </v-col>
     
    
    </v-row>
    </v-form>
  </v-container>
</template>

<script>
/* eslint-disable */
  import axios from 'axios'
  import { mapActions } from 'vuex'
  export default {
    name: 'AddNotification',

    data() {
      return {
        notification: {
          title: '',
          description: '',
          send_to_all_users:true,
          selected_user_id:[],
          
        },
        loading:false,
        itemsPerPage:5,
        add_user_valid:false,
          selected_user_list:[],
          add_user_loader:false,
          user_search_loader:false,
          search_user_list:[],
          user_search_query:"",
          user_search_select:[],
        formErrors: {},
        valid:false,
        selected_list:[],
        search:"",
        registered_employee_list:[],


        
      }
    },
    
    computed:{  
      filteredItems() {
            return this.registered_employee_list.filter(item => {
              if(!this.search) return this.registered_employee_list;
                return (item.name.toLowerCase().includes(this.search.toLowerCase()));
              });
          },
    },
    watch:{
      search(val){
        this.getRegisteredEmployeeList(val);
      },
    },
    methods: {
      contains: function(arr, item)
      {  
            return arr.indexOf(item) != -1;
        },
      clearSearch () {
            this.search="";
         },
         removeList:function(data){
          const index = this.selected_list.indexOf(data);
          if (index >= 0) 
           {  this.selected_list.splice(index, 1)
              this.notification.selected_user_id.splice(index,1);
           }
          
      },
      addList:function(data){
        const found = this.selected_list.some(el => el.id === data.id);
        if(!found){
          this.selected_list.push(data);
          this.notification.selected_user_id.push(data.id);
        }
      },
    validateBeforeSubmit: function(scope) {
      
      this.$validator.validateAll(scope).then(result => {
        if (result) {
          
          this[scope]();
        }
      });
    },
    getRegisteredEmployeeList()
      {
         this.$axios.get(`${process.env.VUE_APP_API_URL}/api/employees/registeredEmployeeList`).then((response) => {
          
          if (response.data.status === 1) {
            this.registered_employee_list = response.data.registered_employee_list;
            

          }
        }).catch((error) => {
          
        })
      },
      addNotification() {
        this.loading =  true;
        this.$axios.post(`${process.env.VUE_APP_API_URL}/api/notifications`, this.notification).then((response) => {
          
          if (response.data.status === 1) {
           
            this.$router.push({ name: 'Notifications' })
          }
        }).catch((error) => {
         
        })
      },
    },
    mounted()
    {
      this.getRegisteredEmployeeList();
    }
  };
</script>
