<template>
<v-container id="regular-tables" fluid tag="section" class="pa-1 full-width-div">

    <base-material-card icon="mdi-clipboard-text" title="Muster Report" class="px-5 py-3">
        <v-form id="musterReport" @submit.prevent="validateBeforeSubmit('musterReport')" data-vv-scope="musterReport" ref="musterReport">
            <v-row>
                <v-col>
                    <label>Start Date</label>
                    <br>
                    <v-menu ref="start_menu" v-model="start_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :value="formatStartDate" readonly v-bind="attrs" v-on="on" placeholder='Enter Start Date'></v-text-field>
                        </template>
                        <v-date-picker v-model="filters.start_date" @input="start_menu = false">

                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col>
                    <label>End Date</label>
                    <br>
                    <v-menu ref="end_menu" v-model="end_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :value="formatEndDate" readonly v-bind="attrs" v-on="on" placeholder='Enter End Date'></v-text-field>
                        </template>
                        <v-date-picker v-model="filters.end_date" :max="max_date" @input="end_menu = false">

                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col>
                    <label>Employees</label>
                    <v-autocomplete v-model="filters.user_id" 
                    autocomplete="off" :items="registered_employee_list" item-text="name" item-value="id" class="purple-input" name="Name">
                    </v-autocomplete>
                </v-col>
                <v-col>
                    <v-btn type="submit" color="success" class="mt-5" :loading="export_loader" :disabled="!filters.start_date || !filters.end_date">
                        Export
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

    </base-material-card>

</v-container>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import { mapActions } from 'vuex'
import moment from 'moment'
export default {
    computed: {
        formatStartDate() {
            return this.filters.start_date ? moment(this.filters.start_date).format('DD-MMM-YY') : ''
        },
        formatEndDate() {
            return this.filters.end_date ? moment(this.filters.end_date).format('DD-MMM-YY') : ''
        },

    },
    watch: {
        "filters.start_date": {
            handler(val) {
                if (val) {
                    console.log(val)
                    let max = moment(val).add(31, 'days');
                    this.max_date = moment(max).format('Y-MM-DD')

                }
            }
        },
    },
    methods: {
        download(data) {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'muster_report' + Date.now() + '.csv');
            document.body.appendChild(link);
            link.click();
        },
        musterReport() {
            this.export_loader = true;
            axios
                .get(`${process.env.VUE_APP_API_URL}/api/musterReport`, {
                    params: { filters: this.filters, responseType: 'blob' }
                })
                .then(response => {
                    this.download(response.data);
                    // this.export_dialog = false;
                    this.export_loader = false;
                })
                .catch(error => {
                    console.log(error)
                    this.export_loader = false;
                });
        },
        getRegisteredEmployeeList() {

            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/employees/registeredEmployeeList`).then((response) => {

                if (response.data.status === 1) {
                    
                    this.registered_employee_list.push({ id: "all", name: "All" });
                    response.data.registered_employee_list.forEach(element => {
                         this.registered_employee_list.push({ id: element.id, name: element.name });
                    });
                    

                }
            }).catch((error) => {

            })
        },
        validateBeforeSubmit: function (scope) {
            this.$validator.validateAll(scope).then(result => {
                if (result) {
                    this[scope]();
                }
            });
        },
    },
    data() {
        return {
            filters: {
                start_date: "",
                end_date: "",
                user_id: "",
            },
            max_date: "",
            is_loading: false,
            headers: [],
            report: [],
            start_menu: false,
            end_menu: false,
            total_items: 10,
            page: 0,
            options: {},
            export_loader: false,
            registered_employee_list: [],
            auth_user: {},
            auth_user_role: '',
        }
    },
    mounted() {
        this.auth_user = this.$store.getters.get_user;

        if (this.auth_user) {
            this.auth_user_role = this.auth_user.roles[0].name
        }
        if (this.auth_user_role == 'employee') {
            this.registered_employee_list.push(this.auth_user);
        } else {

            this.getRegisteredEmployeeList();
        }

    }
};
</script>
