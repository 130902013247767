<template>
<v-container id="regular-tables" fluid tag="section" class="pa-1 full-width-div">
    <v-snackbar v-model="snackbar.display" :color="snackbar.color" :top="snackbar.top" :bottom="snackbar.bottom" :left="snackbar.left" :right="snackbar.right">
        {{ snackbar.message }}
    </v-snackbar>
    <base-material-card icon="mdi-clipboard-text" title="Work Hour Report" class="px-5 py-3">
        <v-form id="workHourReport" @submit.prevent="validateBeforeSubmit('workHourReport')" data-vv-scope="workHourReport" ref="workHourReport" class="mt-10">
            <v-row>
                <v-col>
                    <label>Start Date</label>
                    <br>
                    <v-menu ref="start_menu" v-model="start_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :value="formatStartDate" readonly v-bind="attrs" v-on="on" placeholder='Enter Start Date'></v-text-field>
                        </template>
                        <v-date-picker v-model="filters.start_date" @input="start_menu = false">

                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col>
                    <label>End Date</label>
                    <br>
                    <v-menu ref="end_menu" v-model="end_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :value="formatEndDate" readonly v-bind="attrs" v-on="on" placeholder='Enter End Date'></v-text-field>
                        </template>
                        <v-date-picker v-model="filters.end_date" :max="max_date" @input="end_menu = false">

                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col>
                    <label>Employees</label>
                    <v-autocomplete v-model="filters.user_id" :items="registered_employee_list" autocomplete="off" item-text="name" item-value="id" class="purple-input" name="Name">
                    </v-autocomplete>
                </v-col>
                <v-col>
                    <v-btn type="submit" color="success" class="mt-5" :loading="export_loader" :disabled="!filters.start_date || !filters.end_date">
                        Export
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <p>Include details</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="3">
                    <v-checkbox v-model="filters.requiredData.attendance" disabled label="Attendance"></v-checkbox>
                    <v-checkbox v-model="filters.requiredData.shiftStartTime" label="Shift Start Time"></v-checkbox>
                    <v-checkbox v-model="filters.requiredData.shiftEndTime" label="Shift End Time"></v-checkbox>
                </v-col>
                <v-col cols="12" md="3">
                    <v-checkbox v-model="filters.requiredData.inTime" disabled label="In Time"></v-checkbox>
                    <v-checkbox v-model="filters.requiredData.workingHour" label="Working Hour"></v-checkbox>
                </v-col>
                <v-col cols="12" md="3">
                    <v-checkbox v-model="filters.requiredData.outTime" disabled label="Out Time"></v-checkbox>
                    <v-checkbox v-model="filters.requiredData.lateHour" label="Late Hour"></v-checkbox>
                    <v-checkbox v-model="filters.requiredData.extraHour" label="Extra Hour"></v-checkbox>
                </v-col>
            </v-row>
        </v-form>
    </base-material-card>
</v-container>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import LateCheckInReportVue from './LateCheckInReport.vue';
export default {
    computed: {
        formatStartDate() {
            return this.filters.start_date ? moment(this.filters.start_date).format('DD-MMM-YY') : ''
        },
        formatEndDate() {
            return this.filters.end_date ? moment(this.filters.end_date).format('DD-MMM-YY') : ''
        },

    },
    watch: {
        "filters.start_date": {
            handler(val) {
                if (val) {
                    let max = moment(val).add(31, 'days');
                    this.max_date = moment(max).format('Y-MM-DD')

                }
            }
        },
    },
    data() {
        return {
            requiredData: {
                shiftStartTime: true,
                shiftEndTime: true,
                attendance: true,
                inTime: true,
                outTime: true,
                lateHour: true,
                extraHour: true,
                workingHour: true
            },
            filters: {
                start_date: "",
                end_date: "",
                month: '',
                user_id: '',
                requiredData: {
                    shiftStartTime: true,
                    shiftEndTime: true,
                    attendance: true,
                    inTime: true,
                    outTime: true,
                    lateHour: true,
                    extraHour: true,
                    workingHour: true
                },
            },
            export_loader: false,

            registered_employee_list: [],
            auth_user: {},
            auth_user_role: '',

            start_menu: false,
            end_menu: false,
            max_date: "",

            snackbar: {
                message: "",
                display: false,
                color: "",
                top: true,
                right: true,
                bottom: false,
                left: false
            },
        }
    },

    methods: {
        download(data) {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'workhour_report' + Date.now() + '.csv');
            document.body.appendChild(link);
            link.click();
        },
        workHourReport() {
            this.export_loader = true;
            axios
                .get(`${process.env.VUE_APP_API_URL}/api/employee-workhour-export`, {
                    params: { filters: this.filters, responseType: 'blob' }
                })
                .then(response => {

                    if (response.status == 0) {
                        this.snackbar.message = response.data.message;
                        this.snackbar.display = true;
                        this.export_loader = false;
                    }

                    this.download(response.data);
                    this.export_loader = false;

                })
                .catch(error => {
                    console.log(error)
                    this.export_loader = false;
                    this.snackbar.message = "Error Occured";
                    this.snackbar.display = true;
                });
        },
        getRegisteredEmployeeList() {

            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/employees/registeredEmployeeList`).then((response) => {

                if (response.data.status === 1) {

                    this.registered_employee_list.push({ id: "all", name: "All" });
                    response.data.registered_employee_list.forEach(element => {
                        this.registered_employee_list.push({ id: element.id, name: element.name });
                    });

                }
            }).catch((error) => {

            })
        },
        validateBeforeSubmit: function (scope) {
            this.$validator.validateAll(scope).then(result => {
                if (result) {
                    this[scope]();
                }
            });
        },
    },
    mounted() {
        this.auth_user = this.$store.getters.get_user;

        if (this.auth_user) {
            this.auth_user_role = this.auth_user.roles[0].name
        }
        if (this.auth_user_role == 'employee') {
            this.registered_employee_list.push(this.auth_user);
        } else {

            this.getRegisteredEmployeeList();
        }
    }
}
</script>

<style scoped>
.v-label.v-label,
.v-alert.v-alert {
    font-size: 14px;
    color: black;
}

.v-input--selection-controls {
    margin-top: 0px;
    padding-top: 0px;
}
</style>
