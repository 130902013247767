<template>
  <v-container fluid tag="section" class="ma-0 pa-0">
    <v-row>
      <v-flex xs12 class="buttons">
        <v-btn
          color="success"
          class="ml-3"
          @click="shift_dialog_box = !shift_dialog_box"
        >
          Add Shift
        </v-btn>
      </v-flex>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <base-material-card>
          <template v-slot:heading>
            <v-row>
              <v-col cols="10">
                <div class="display-2 font-weight-light">
                  Shift Details
                </div>
              </v-col>
            </v-row>
          </template>
          <v-card flat>
            <v-data-table
              class="mt-5"
              :headers="headers2"
              :items="shifts"
              item-key="id"
              :sort-by="['date']"
              :sort-desc="[false]"
              :hide-default-footer="true"
              :loading="is_loading"
              no-results-text="Loading... Please wait"
            >
              <template v-slot:progress>
                <v-progress-linear
                  color="success"
                  indeterminate
                ></v-progress-linear>
              </template>
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td>{{ item.start_time }}</td>
                    <td>{{ item.end_time }}</td>
                    <td>
                      <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                      </v-icon>
                      <v-icon small @click="deleteItem(item)">
                        mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card>
        </base-material-card>
      </v-col>
    </v-row>
    <!-- Edit config box -->
    <v-dialog
      v-model="shift_edit_box"
      scrollable
      :overlay="false"
      max-width="450px"
      transition="dialog-transition"
      hide-overlay
    >
      <v-card style="height:100%">
        <v-form
          id="editConfig"
          @submit.prevent="validateBeforeSubmit('editConfig')"
          data-vv-scope="editConfig"
          v-model="valid"
        >
          <v-card-text>
            <v-card-title primary-title class="mb-8">
              <span class="headline">Update Shift</span>
            </v-card-title>
            <v-container fluid grid-list-md style="padding: 0 16px 16px 16px">
              <v-layout row wrap class="field_wrapper">
                <v-col cols="12" class="pt-0 pb-0">
                  <label>Name <span style="color:red">*</span></label>
                  <v-text-field
                    name="Name"
                    lable="Name"
                    v-model="shift_edit.name"
                    autocomplete="off"
                    v-validate="'required'"
                    
                    :error-messages="errors.collect('editConfig.Name')"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <label>Start Time <span style="color:red">*</span></label>
                  <v-menu
                    ref="menu1"
                    v-model="time_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="shift_edit.start_time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="shift_edit.start_time"
                        readonly
                        v-on="on"
                        name="Start Time"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="time_menu"
                      v-model="shift_edit.start_time"
                      format="24hr"
                      @click:minute="$refs.menu1.save(shift_edit.start_time)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <label>End Time <span style="color:red">*</span></label>
                  <v-menu
                    ref="menu2"
                    v-model="time_menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="shift_edit.end_time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="shift_edit.end_time"
                        readonly
                        v-on="on"
                        name="Start Time"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="time_menu2"
                      format="24hr"
                      :min="shift_edit.start_time"
                      v-model="shift_edit.end_time"
                      @click:minute="$refs.menu2.save(shift_edit.end_time)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              @click="
                shift_edit_box = !shift_edit_box;
                shift_edit_loader = fasle;
              "
              >Cancel</v-btn
            >
            <v-btn
              small
              color="primary"
              :loading="shift_edit_loader"
              type="submit"
              >Update
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="delete_shift_box"
      scrollable
      :overlay="false"
      max-width="480px"
      transition="dialog-transition"
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title primary-title>
          <span>Are you sure you wish to delete this Shift ?</span>
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            @click="
              delete_shift_box = false;
              delete_shift_loader = false;
            "
            >Cancel</v-btn
          >
          <v-btn
            small
            color="primary"
            @click="deleteConfig"
            :loading="delete_shift_loader"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackBar.display"
      :color="snackBar.color"
      :top="snackBar.top"
      :bottom="snackBar.bottom"
      :left="snackBar.left"
      :right="snackBar.right"
    >
      {{ snackBar.message }}
    </v-snackbar>
    <v-dialog
      v-model="shift_dialog_box"
      max-width="44%"
      max-height="290"
      hide-overlay
      color="white"
    >
      <v-card elevation="0">
        <v-form
          id="createshift"
          @submit.prevent="validateBeforeSubmit('createshift')"
          data-vv-scope="createshift"
        >
          <v-card-text>
            <v-card-title primary-title class="mb-8">
              <span class="headline">Create Shift</span>
            </v-card-title>
            <v-container fluid grid-list-md style="padding: 0 16px 16px 16px">
              <v-layout row wrap class="field_wrapper">
                <v-col cols="12" class="pt-0 pb-0">
                  <label>Shift Name <span style="color:red">*</span></label>
                  <v-text-field
                    name="Shift Name"
                    v-model="shift.name"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <label>Check-in Time <span style="color:red">*</span></label>
                  <v-menu
                    ref="menu1"
                    v-model="time_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="shift.in_time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="shift.in_time"
                        readonly
                        v-on="on"
                        name="Start Time"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="time_menu"
                      v-model="shift.in_time"
                      format="24hr"
                      @click:minute="$refs.menu1.save(shift.in_time)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <label>Check-out Time <span style="color:red">*</span></label>
                  <v-menu
                    ref="menu2"
                    v-model="time_menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="shift.out_time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="shift.out_time"
                        readonly
                        v-on="on"
                        name="Start Time"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="time_menu2"
                      format="24hr"
                      :min="shift.in_time"
                      v-model="shift.out_time"
                      @click:minute="$refs.menu2.save(shift.out_time)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small @click="shift_dialog_box = !shift_dialog_box"
              >Cancel</v-btn
            >
            <v-btn small color="primary" :loading="create_loader" type="submit"
              >Create
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  name: "AddEmployee",
  data() {
    return {
      organization: {
        name: "",
        code: "",
        backend_url: "",
        frontend_url: "",
        license_user_count: "",
        organization_domain: ""
      },
      config_loading: false,
      config: {},
      valid: false,
      loading: false,
      formErrors: {},

      shifts: [],
      configuration: [],
      is_loading: false,
      headers2: [
        { text: "Name", value: "name", align: "left", sortable: false },
        {
          text: "Start Time",
          value: "start_time",
          align: "left",
          sortable: false
        },
        { text: "End Time", value: "end_time", align: "left", sortable: false },
        { text: "Action", value: "value", align: "left", sortable: false }
      ],
      total_items: 0,

      snackBar: {
        message: "",
        display: false,
        color: "balck",
        top: true,
        right: true,
        bottom: false,
        left: false
      },

      shift_edit: {
        name: "",
        start_time: "",
        end_time: ""
      },
      shift_edit_box: false,
      shift_edit_loader: false,

      delete_shift: { id: "" },
      delete_shift_loader: false,
      delete_shift_box: false,

      current_time: moment().format("hh:mm"),
      time_menu: false,
      time_menu2: false,
      date_menu: false,

      shift_dialog_box: false,
      shift: {
        name: "",
        in_time: "",
        out_time: ""
      },
      create_loader: false
    };
  },
  methods: {
    editItem(item) {
      this.shift_edit.name = item.name;
      this.shift_edit.start_time = item.start_time;
      this.shift_edit.end_time = item.end_time;
      this.shift_edit.id = item.id;
      this.shift_edit_box = true;
    },
    deleteItem(item) {
      this.delete_shift_box = true;
      this.delete_shift.id = item.id;
    },
    getdetail() {
      this.$axios
        .get(`${process.env.VUE_APP_API_URL}/api/shift/details`)
        .then(response => {
          if (response.data.status == 1) {
            this.shifts = response.data.shifts;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    editConfig() {
      this.shift_edit_loader = true;
      this.$axios
        .post(`${process.env.VUE_APP_API_URL}/api/shift/edit`, this.shift_edit)
        .then(response => {
          if (response.data.status == 1) {
            this.snackBar.message = response.data.message;
            this.snackBar.display = true;
            this.shift_edit_box = false;
          }

          if (response.data.status == 0) {
            this.snackBar.message = response.data.message;
            this.snackBar.display = true;
          }
          this.shift_edit_loader = false;
          this.shift_edit_box = false;
          this.getdetail();
        })
        .catch(error => {
          this.snackBar.message = "Error Occured";
          this.snackBar.display = true;
          this.shift_edit_box = false;
          this.shift_edit = {
            name: "",
            start_time: "",
            end_time: "",
            id: ""
          };
          this.shift_edit_loader = false;
        });
    },

    deleteConfig() {
      this.delete_shift_loader = true;
      this.$axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/shift/delete/` +
            this.delete_shift.id
        )
        .then(response => {
          this.delete_shift_box = false;
          this.snackBar.message = response.data.message;
          this.snackBar.display = true;
          this.delete_shift.id = "";
          this.delete_shift_loader = false;
          this.getdetail();
        })
        .catch(error => {
          this.snackBar.message = "Error Occured";
          this.snackBar.display = true;
          this.delete_shift_box = false;
          this.delete_shift_loader = false;
          this.delete_shift.id = "";
        });
    },

    validateBeforeSubmit: function(scope) {
      this.$validator.validateAll(scope).then(result => {
        if (result) {
          this[scope]();
        }
      });
    },
    createshift() {
      this.create_loader = true;
      this.$axios
        .post(`${process.env.VUE_APP_API_URL}/api/shift`, this.shift)
        .then(response => {
          this.create_loader = false;
          if (response.data.status == 1) {
            this.snackBar.message = response.data.message;
            this.snackBar.display = true;
            this.shift_dialog_box = false;
            this.shift = {};
            this.getdetail();
          } else {
            this.shift_dialog_box = false;
            this.snackBar.message = response.data.message;
            this.snackBar.display = true;
          }
          this.getdetail();
        })
        .catch(error => {
          this.shift_dialog_box = false;
          this.create_loader = false;
          this.snackBar.message = "Error Occured";
          this.snackBar.display = true;
        });
    }
  },
  mounted() {
    this.getdetail();
  }
};
</script>

<style scoped>
h5 {
  color: black;
}
</style>
