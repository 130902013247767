<template>
<div>
    <v-card class="white">
        <v-progress-linear :active="is_loading" :indeterminate="is_loading" absolute></v-progress-linear>
        <v-card-text style="-webkit-box-shadow: 2px 3px 13px 2px rgb(173 187 210 / 54%);">
            <v-layout row>
                <v-flex xs12 style="background-color: whitesmoke;">
                    <v-list-item>
                        <v-list-item-content> 
                            <v-list-item-title class="text-subtitle-1 bold">Employee Activity Today</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-flex>
                <v-flex xs12 class="pa-2 pt-4" v-if="length>0">
                    <canvas ref="chart" id='chart5'></canvas>
                </v-flex>
                <v-flex xs12 class="pa-2 pt-4" v-else style="text-align: center;">
                    <span> No data to display</span>
                    <canvas ref="chart" id='chart5'></canvas>
                </v-flex>
            </v-layout>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import axios from 'axios';
import Chart from 'chart.js';
export default {
    data: () => {
        return {
            is_loading: false,
            total_checkin: 0,
            length: 0,
            myChart: "",
        }
    },

    methods: {
        chart() {
            this.is_loading = true;
            axios.get(`${process.env.VUE_APP_API_URL}/api/dashboard/ActivityByTime`)
                .then((response) => {
                    if (response.data.status == 1) {
                        this.length=1;
                        let keys = response.data.label;
                        let values = response.data.data;
                        
                        if (this.myChart != "") {
                            this.myChart.destroy();
                        }
                        var ctx = document.getElementById("chart5").getContext('2d');
                        this.myChart = new Chart(ctx, {
                            type: 'horizontalBar',
                            data: {
                                labels: keys,
                                datasets: [{
                                    backgroundColor: [
                                        "#9dc1e3",
                                        "#dbaaf1",
                                        "#ff889f",
                                        "#ffa600",

                                    ],
                                    data: values,
                                    datalabels: {
                                        color: '#ffffff'
                                    }
                                }]
                            },
                            options: {
                                responsive: true,
                                legend: {
                                    position: 'bottom',
                                    labels: {
                                        boxWidth: 20,
                                        padding: 20,
                                        generateLabels: function (chart) {
                                            var data = chart.data;
                                            if (data.labels.length && data.datasets.length) {
                                                return data.labels.map(function (label, i) {
                                                    var meta = chart.getDatasetMeta(0);
                                                    var ds = data.datasets[0];
                                                    var arc = meta.data[i];
                                                    var custom = arc && arc.custom || {};
                                                    var getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
                                                    var arcOpts = chart.options.elements.arc;
                                                    var fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                                                    var stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                                                    var bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

                                                    // We get the value of the current label
                                                    var value =0;
                                                    if (chart.config.data.datasets[arc._datasetIndex].data[arc._index] == null) {
                                                        value = 0;
                                                    } else {
                                                         value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];
                                                    }
                                                    return {
                                                        // Instead of `text: label,`
                                                        // We add the value to the string
                                                        text: label + " : " + value,
                                                        fillStyle: fill,
                                                        strokeStyle: stroke,
                                                        lineWidth: bw,
                                                        hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                                                        index: i
                                                    };
                                                });
                                            } else {
                                                return [];
                                            }
                                        }
                                    },
                                    
                                }
                            }
                        });

                        this.is_loading = false;
                    } else {
                        this.is_loading = false;
                    }

                }).catch(error => {
                    console.log(error)
                });
        }
    },
    mounted() {
        this.chart();
    }
}
</script>
