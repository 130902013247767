<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
    class="pa-1 full-width-div"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Create Notification
            </div>

            <div class="subtitle-1 font-weight-light">
              Create Notification
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="notification.title"
                    class="purple-input"
                    label="Title"
                  />
                  <span
                    class="red--text"
                    v-text="formErrors.title"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="notification.body"
                    label="Body"
                    class="purple-input"
                  />
                  <span
                    class="red--text"
                    v-text="formErrors.body"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="notification.description"
                    label="Description"
                    class="purple-input"
                  />
                  <span
                    class="red--text"
                    v-text="formErrors.description"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <a :href="notification.attachment_url">Attachment</a>
                  <v-file-input
                    v-model="notification.attachment"
                    label="Attachment"
                    class="purple-input"
                  />
                  <span
                    class="red--text"
                    v-text="formErrors.attachment"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    href="#/notifications"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    color="success"
                    @click="submit()"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { mapActions } from 'vuex'
  import axios from 'axios'
  export default {
    name: 'UpdateLocation',

    data() {
      return {
        notificationId: this.$route.params.notificationId,
        notification: {},
        formErrors: {},
      }
    },
    created() {
      this.userAuth()
      const uri = 'http://localhost/cdp-eas-2/public/api'
      axios.get(uri + '/notifications/' + this.notificationId).then((response) => {
        console.log(response.data)
        this.notification = response.data.notification
      })
    },
    methods: {
      ...mapActions(['userAuth']),
      submit() {
        const uri = 'http://localhost/cdp-eas-2/public/api'
        axios.patch(uri + '/notifications/' + this.notificationId, { title: this.notification.title, body: this.notification.body, description: this.notification.description, attachment: this.notification.attachment }).then((response) => {
          this.notification = response.data.notification
          if (response.data.status === 1) {
            this.$toasted.show(response.data.message, {
              theme: 'bubble',
              position: 'top-right',
              duration: 5000,
              fullWidth: false,
              fitToScreen: false,
              type: 'success',
            })
            this.$router.push({ name: 'Notifications' })
          }
        }).catch((error) => {
          console.log(error.response.data.error)
          this.formErrors = error.response.data.error
        })
      },
    },
  }
</script>
