<template>
  <div />
</template>
<script>
  import axios from 'axios'
  import { mapActions } from 'vuex'
  // import AuthHeader from '../auth-header/AuthHeader'

  export default {
    name: 'Logout',
    data() {
      return {
        location: {
          name: '',
          lat: '',
          long: '',
          address: '',
          radius: '',
        },
        formErrors: {},
      }
    },
    creted() {
      console.log('jhh')
      this.userAuth()
      this.submit()
    },
    methods: {
      ...mapActions(['userAuth']),
      submit() {
        this.userAuth()
        const uri = 'http://localhost/cdp-eas-2/public/api'
        axios.post(uri + '/locations', { name: this.location.name, lat: this.location.lat, long: this.location.long, address: this.location.address, radius: this.location.radius }).then((response) => {
          this.location = response.data
          if (response.data.status === 1) {
            this.$toasted.show(response.data.message, {
              theme: 'bubble',
              position: 'top-right',
              duration: 5000,
              fullWidth: false,
              fitToScreen: false,
              type: 'success',
            })
            this.$router.push({ name: 'Locations' })
          }
        }).catch((error) => {
          console.log(error.response.data.error)
          this.formErrors = error.response.data.error
        })
      },
    },
  }
</script>
