<template>
<v-container id="regular-tables" fluid tag="section" class="pa-1 full-width-div">
    <v-row>
        <v-flex xs12 class="buttons">
            <v-btn color="success" @click="filter_dialog_box = !filter_dialog_box">
                Filters
            </v-btn>
        </v-flex>
    </v-row>
    <base-material-card icon="mdi-clipboard-text" title="Registrations" class="px-5 py-3">
        <v-data-table :headers="headers" :items="registrations" item-key="id" :sort-by="['updated_at']" 
        :sort-desc="[true]" :options.sync="options" :server-items-length="total_items" :loading="loader" 
        loading-text="Loading... Please wait">
            <template v-slot:progress>
                <v-progress-linear color="success" indeterminate></v-progress-linear>
            </template>
            <template v-slot:loading>
                <v-layout row wrap justify-center class="text-xs-center">
                    Loading... Please wait
                </v-layout>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" :key="item.name">
                        <td v-if="item.image">
                            <v-avatar size="36">
                                <img :src="storage_path() + item.image.image" 
                                @click="fullWidthImage(item.image.image)"
                                @error="setAltImg" />
                            </v-avatar>
                        </td>
                        <td v-else>-</td>
                        <td v-if="item.employee">{{ item.employee.employee_id }}</td>
                        <td v-else>-</td>
                        <td v-if="item.employee">{{ item.employee.name }}</td>
                        <td v-else>-</td>

                        <td>
                            <v-chip :text-color="getStatusColor(item.approval_status, status_list)" :color="getStatusColor(item.approval_status, status_list)">
                                {{ getStatusValue(item.approval_status, status_list) }}
                            </v-chip>
                        </td>
                        <td v-if="item.created_at != null">
                            {{ formatDateTime(item.created_at) }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="item.updated_at != null">
                            <v-btn text color="primary" small @click="getTimeline(item.id)">
                                {{ formatDateTime(item.updated_at) }}
                            </v-btn>
                        </td>
                        <td v-else>-</td>
                        <td style="width: 22%;">

                        <v-btn v-if="checkPermission('update-registrations')"
                         :disabled="item.approval_status != 'awaiting_approval'" color="green lighten-2" 
                         class="mx-2" small @click="
                         registration_confirmation_box = !registration_confirmation_box;
                         (status = 'approved'), (selected_registration_id = item.id);">
                                Approve
                        </v-btn>
                        <v-btn v-else :disabled="true" class="mx-2" small>
                                Approve
                        </v-btn>

                        <v-btn v-if="checkPermission('update-registrations')" 
                        :disabled="item.approval_status != 'awaiting_approval'" class="mx-2" 
                        small color="red lighten-2" 
                        @click="registration_confirmation_box = !registration_confirmation_box; status = 'rejected';
                        selected_registration_id = item.id; ">
                                Reject
                        </v-btn>
                        <v-btn v-else :disabled="true" class="mx-2" small>
                                Reject
                        </v-btn>
                        <v-btn v-if="checkPermission('delete-registrations')" 
                        text icon small color="red lighten-2" @click="
                       delete_registration_box = !delete_registration_box;
                        selected_registration_id = item.id;">
                                <v-icon small>mdi-delete</v-icon>
                            </v-btn>
                        <v-btn v-else :disabled="true" class="mx-2" text icon small>
                            <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>

        <!-- <v-pagination
        v-if="registrations.length!==0"
        v-model="pagination.current"
        :length="pagination.total"
        @input="onPageChange"
      /> -->
    </base-material-card>
    <v-dialog v-model="image_dialog_box" max-width="290" max-height="290" hide-overlay>
        <v-card>
            <v-img :src="storage_path() + image_path"></v-img>
        </v-card>
    </v-dialog>
    <!-- filter dialog box -->
    <v-dialog v-model="filter_dialog_box" scrollable :overlay="false" max-width="450px" hide-overlay>
        <v-card style="height:100%" elevation="0">
            <v-form id="filterList" @submit.prevent="validateBeforeSubmit('filterList')" data-vv-scope="filterList" v-model="valid">
                <v-card-text>
                    <v-card-title primary-title>
                        <span class="headline">Filters</span>
                    </v-card-title>
                    <v-container fluid grid-list-md style="padding: 0 16px 16px 16px">
                        <v-layout row wrap class="field_wrapper">
                            <v-flex xs12 md12>
                                <label>Employee Name</label>
                                <br />
                                <v-autocomplete v-model="filters.user_id" :items="registered_employee_list" item-text="name" item-value="id" placeholder="Enter Employee Name" class="purple-input">
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12 md12>
                                <label>Emplpyee ID</label>
                                <br />
                                <v-text-field name="employee_id" type="text" v-model="filters.employee_id" placeholder="Enter Employee ID"></v-text-field>
                            </v-flex>

                            <v-flex xs12 md12>
                                <label>Approval Status</label>
                                <br />
                                <v-select :items="status_list" item-text="value" item-value="value" v-model="filters.approval_status" placeholder="Select Approval Status"></v-select>
                            </v-flex>
                            <v-flex xs12 md12>
                                <label>Registered At</label>
                                <br />
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :value="formatDate" readonly v-bind="attrs" v-on="on" placeholder="Enter Registered Date"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.registered_at" @input="menu = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="filter_dialog_box = !filter_dialog_box">Cancel</v-btn>
                    <v-btn small color="error" @click="resetFilters">Reset</v-btn>
                    <v-btn small color="primary" :disabled="!valid" type="submit">Apply</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <!-- Delete Registration Dialog Box -->
    <v-dialog v-model="delete_registration_box" scrollable :overlay="false" max-width="500px" transition="dialog-transition" hide-overlay>
        <v-card>
            <v-form>
                <v-card-title primary-title>
                    <span>Are you sure you wish to delete this Registration ?</span>
                </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="delete_registration_box = false">Cancel</v-btn>
                    <v-btn small color="primary" @click="deleteRegistration" :loading="delete_loading">Delete</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <!-- TimeLine Dialog Box -->
    <v-dialog v-model="timeline_box" scrollable :overlay="false" max-width="500px" transition="dialog-transition" hide-overlay>
        <v-card>
            <v-card-text>
                <v-timeline dense>
                    <v-timeline-item fill-dot small color="green">
                        <v-row>
                            <v-col cols="6" v-text="registration_timeline_last_record.title" class="py-0"></v-col>
                            <v-col cols="6" class="py-0">{{
                  formatDateTime(registration_timeline_last_record.created_at)
                }}</v-col>
                        </v-row>
                    </v-timeline-item>
                    <v-timeline-item v-for="(item, i) in registration_all_timeline_list" :key="i" fill-dot small color="grey">
                        <v-row>
                            <v-col cols="6" v-text="item.title" class="py-0"></v-col>
                            <v-col cols="6" class="py-0">{{
                  formatDateTime(item.created_at)
                }}</v-col>
                        </v-row>
                    </v-timeline-item>
                </v-timeline>
            </v-card-text>
        </v-card>
    </v-dialog>

    <!--  Registration Confirmation Dialog Box -->
    <v-dialog v-model="registration_confirmation_box" scrollable :overlay="false" max-width="500px" transition="dialog-transition" hide-overlay persistent>
        <v-card>
            <v-form>
                <v-card-title primary-title>
                    <span v-if="status == 'approved'">Are you sure you wish to Approve this Registration ?</span>
                    <span v-else>Are you sure you wish to Reject this Registration ?</span>
                </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="registration_confirmation_box = false">Cancel</v-btn>
                    <v-btn small color="primary" @click="updateRegistration">OK</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import moment from "moment";
export default {
    watch: {
        options: {
            handler() {
                this.getList();
            }
        }
    },
    data() {
        return {
            delete_loading: false,
            registrations: [],
            menu: false,
            employeeImage: "",
            waitingApproval: "awating approval",
            image_dialog_box: false,
            image_path: "",
            total_items: 10,
            page: 0,
            options: {},
            filters: {
                user_id: "",
                employee_id: "",
                approval_status: "",
                registered_at: ""
            },
            filter_chips: {
                user_id: "",
                employee_id: "",
                approval_status: "",
                registered_at: ""
            },
            valid: false,
            status_list: [],
            is_filtered: false,
            filter_dialog_box: false,
            registered_employee_list: [],
            headers: [
                { text: "Image", value: "image", align: "left", sortable: false },
                {
                    text: "Emp. ID",
                    value: "employee_id",
                    align: "left",
                    sortable: false
                },
                {
                    text: "Emp. Name",
                    value: "name",
                    align: "left",
                    sortable: false
                },
                { text: "Status", value: "status", align: "left" },
                { text: "Created At", value: "created_at", align: "left" },
                { text: "Updated At", value: "updated_on", align: "left" },
                { text: "Action" }
            ],
            delete_registration_box: false,
            selected_registration_id: "",
            loader: false,
            status: "",
            timeline_box: false,
            registration_confirmation_box: false,
            auth_user: {},
            auth_user_role: "",
            timeline_list: [],
            registration_timeline_last_record: [],
            registration_all_timeline_list: []
        };
    },

    methods: {
        getList() {
            this.loader = true;
            this.options.filters = Object.assign({}, this.filters);
            axios
                .get(`${process.env.VUE_APP_API_URL}/api/registrations`, {
                    params: this.options
                })
                .then(response => {
                    if (response.data.status == 1) {
                        this.registrations = response.data.registrations.data;

                        this.total_items = response.data.registrations.total;
                        this.loader = false;
                        this.displayFilterChips(this.filters);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getTimeline(registration_id) {
            axios
                .get(
                    `${process.env.VUE_APP_API_URL}/api/timelines/getTimelines/` +
                    registration_id
                )
                .then(response => {
                    if (response.data.status == 1) {
                        this.registration_all_timeline_list = response.data.all_records;
                        this.registration_timeline_last_record =
                            response.data.latest_record;
                    }
                    this.timeline_box = true;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getApprovalStatusList() {
            axios
                .get(
                    `${process.env.VUE_APP_API_URL}/api/configurations/getApprovalStatus`
                )
                .then(response => {
                    if (response.data.status == 1) {
                        this.status_list = response.data.approval_status_list;
                        this.status_list.push({
                            type: "approval_status",
                            key: "all",
                            value: "All"
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },

        updateRegistration() {
            axios
                .post(`${process.env.VUE_APP_API_URL}/api/updateRegistration`, {
                    status: this.status,
                    id: this.selected_registration_id
                })
                .then(response => {
                    if (response.data.status == 1) {
                        this.registration_confirmation_box = false;
                        this.getList();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getEmployeeList() {
            this.$axios
                .get(
                    `${process.env.VUE_APP_API_URL}/api/employees/registeredEmployeeList`
                )
                .then(response => {
                    if (response.data.status === 1) {
                        this.registered_employee_list =
                            response.data.registered_employee_list;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        deleteRegistration: function () {
            this.delete_loading = true;
            this.$axios
                .post(
                    `${process.env.VUE_APP_API_URL}/api/registrations/` +
                    this.selected_registration_id +
                    `/delete`
                )
                .then(response => {
                    this.response_message = response.data.message;

                    if (response.data.status == 1) {
                        this.delete_registration_box = false;
                        this.delete_loading = false;
                        this.getList();
                    } else if (response.data.status == 2) {
                        this.delete_registration_box = false;
                        this.delete_loading = false;
                        this.getList();
                    }
                    // this.snackBar.message = response.data.message;
                    // this.snackBar.show = true;
                })
                .catch(error => {
                    this.delete_loading = false;
                    this.delete_registration_box = false;
                });
        },
        fullWidthImage(item_image) {
            this.image_dialog_box = true;
            this.image_path = item_image;
        },
        filterList() {
            let filter_values = Object.values(this.filters);
            if (filter_values.join("").length > 0) {
                this.$router.replace({
                    name: "Registrations",
                    query: {
                        filters: true,
                        employee_id: this.filters.employee_id,
                        user_id: this.filters.user_id,
                        approval_status: this.filters.approval_status,
                        registered_at: this.registered_at
                    }
                });
                this.filter_dialog_box = false;
            } else {
                this.$router.replace({
                    name: "Registrations"
                });
            }
        },
        resetFilters() {
            this.$router.replace({
                name: "Registrations",
                query: {}
            });
            this.resetFiltersParameters();
            this.is_filtered = false;
            this.getList();
            this.filter_dialog_box = false;
        },
        resetFiltersParameters: function () {
            for (var key in this.filters) {
                this.filters[key] = "";
            }
        },
        displayFilterChips: function (filters) {
            if (this.$route.query.filters) {
                this.filter_chips.user_id = this.filters.user_id;
                // this.filter_chips.assigned_to = this.filters.assigned_to;
                this.filter_chips.approval_status = this.filters.approval_status;
                this.filter_chips.employee_id = this.filters.employee_id;
                this.filter_chips.registered_at = this.filters.registered_at;

                this.is_filtered = true;
            }
        },
        clearFilter: function (filter, key) {
            this.filter_chips[key] = this.filters[key] = "";
            this.filterList();
        },
        applyFiltersOnLoad: function () {
            this.filters.user_id = this.$route.query.user_id;
            this.filters.employee_id = this.$route.query.employee_id;
            this.filters.approval_status = this.$route.query.approval_status;
            this.filters.registered_at = this.$route.query.registered_at;

            this.displayFilterChips(this.filters);
        },
        validateBeforeSubmit: function (scope) {
            this.$validator.validateAll(scope).then(result => {
                if (result) {
                    this[scope]();
                }
            });
        }
    },
    mounted() {
        this.getEmployeeList();
        this.getApprovalStatusList();
        this.applyFiltersOnLoad();
        this.auth_user = this.$store.getters.get_user;

        if (this.auth_user) {
            this.auth_user_role = this.auth_user.roles[0].name;
        }
    }
};
</script>
