<template>
<v-container id="regular-tables" fluid tag="section" class="ma-0 pa-0">
    <v-row>
        <v-flex xs12 class="buttons">
            <v-menu bottom left v-if="checkPermission('create-users')">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item class="pa-0">
                        <v-btn text class="ml-3" @click="upload_user_dialog_box = !upload_user_dialog_box">
                            Import Employee
                        </v-btn>
                    </v-list-item>
                    <v-list-item class="pa-0">
                        <v-btn text class="ml-3" @click="upload_dialog_box = !upload_dialog_box">
                            Import Mapping
                        </v-btn>
                    </v-list-item>
                    <v-list-item class="pa-0">
                        <router-link :to="{name: 'View Mapping'}">
                            <v-btn text class="ml-3">
                                View Mapping
                            </v-btn>
                        </router-link>
                    </v-list-item>
                    <v-list-item>
                        <v-btn color="success" style="width:90%" class="ml-3" to="shift" v-if="checkPermission('create-users')">
                            Shift
                        </v-btn>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-btn color="success" class="mx-1" @click="exportData()" :loading="export_loader">
                Export
            </v-btn>

            <v-btn color="success" class="mr-0" to="addEmployee" v-if="checkPermission('create-users')">
                Add Employee
            </v-btn>
            <v-btn color="success" class="ml-3" @click="filter_dialog_box = !filter_dialog_box">
                Filters
            </v-btn>
        </v-flex>
    </v-row>
    <base-material-card icon="mdi-clipboard-text" title="Employees" class="px-5 py-3">
        <v-data-table :headers="headers" :items="employees" item-key="id" :sort-by="['created_at']" :sort-desc="[true]" :options.sync="options" :server-items-length="total_items" :loading="is_loading" loading-text="Loading... Please wait">
            <template v-slot:progress>
                <v-progress-linear color="success" indeterminate></v-progress-linear>
            </template>
            <template v-slot:loading>
                <v-layout row wrap justify-center class="text-xs-center">
                    Loading... Please wait
                </v-layout>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td>
                            <v-avatar size="36" v-if="item.image">
                                <img :src="storage_path() + item.image.image" @click="fullWidthImage(item.image.image)" @error="setAltImg">
                            </v-avatar>
                        </td>
                        <td v-if="item.name">
                            <router-link v-if="item.id" class="ml-1" :to="{ name: 'Employee Detail', params: { id: item.id } }">{{ item.name }}</router-link>
                        </td>
                        <td v-else>-</td>
                        <td>{{ item.employee_id }}</td>

                        <td>
                            <v-chip class="ma-2" :text-color="getStatusColor(item.status, user_status_list)" :color="getStatusColor(item.status, user_status_list)">
                                {{ getStatusValue(item.status, user_status_list) }}
                            </v-chip>
                        </td>
                        <td v-if="item.primary_contact">
                            {{ item.primary_contact.contact }}
                        </td>
                        <td v-else>-</td>
                        <!-- <td v-if="item.primary_email">{{ item.primary_email.email }}</td>
                        <td v-else>-</td> -->
                        <td v-if="item.updated_at != null">
                            <v-btn text color="primary" small @click="getUserTimeline(item.id)">
                                {{ formatDateTime(item.updated_at) }}
                            </v-btn>
                        </td>
                        <td v-else>- </td>
                        <td v-if="!is_loading" style="padding: 0px">
                            <v-tooltip left><template v-slot:activator="{ on, attrs }">

                                    <v-btn :disabled="!checkPermission('update-users')" v-bind="attrs" v-on="on" text icon small color="blue lighten-2" :to="`employees/edit/${item.id}`">
                                        <v-icon large>mdi-pencil</v-icon>
                                    </v-btn>
                                </template><span>Edit Employee Detail</span></v-tooltip>

                            <v-tooltip left><template v-slot:activator="{ on, attrs }">
                                    <v-btn :disabled="!checkPermission('delete-users')" text icon small v-bind="attrs" v-on="on" color="red lighten-2" @click="
                              delete_user_box = !delete_user_box;selected_user_id = item.id;">
                                        <v-icon large>mdi-delete</v-icon>
                                    </v-btn>
                                </template><span>Delete Employee</span></v-tooltip>

                            <v-tooltip left><template v-slot:activator="{ on, attrs }">
                                    <v-btn :disabled="!checkPermission('update-users')" v-bind="attrs" v-on="on" text icon small color="green lighten-2" @click="update_password_box = !update_password_box;user_object.id=item.id">
                                        <v-icon large>mdi-lock-open</v-icon>
                                    </v-btn>
                                </template><span>Update Password</span></v-tooltip>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template slot="no-data-text">
                <v-layout row wrap justify-center class="text-xs-center" v-if="is_loading">
                    Loading... Please wait
                </v-layout>
                <v-layout row wrap justify-center class="text-xs-center" v-else>
                    No data available
                </v-layout>
            </template>
        </v-data-table>

        <!-- Update passwor box -->
        <v-dialog v-model="update_password_box" scrollable :overlay="false" max-width="450px" transition="dialog-transition" hide-overlay>
            <v-card style="height:100%">
                <v-form id="changePassword" @submit.prevent="validateBeforeSubmit('changePassword')" data-vv-scope="changePassword" v-model="valid">
                    <v-card-text>
                        <v-card-title primary-title class="mb-8">
                            <span class="headline">Update Password</span>
                        </v-card-title>
                        <v-container fluid grid-list-md style="padding: 0 16px 16px 16px">
                            <v-layout row wrap class="field_wrapper">
                                <v-col cols="12" class="pt-0 pb-0">
                                    <label>New Password <span style="color:red">*</span></label>
                                    <v-text-field name="New Password" type="password" v-model="user_object.new_password" autocomplete="off"></v-text-field>

                                </v-col>
                                <v-col cols="12">
                                    <label>Confirm Password <span style="color:red">*</span></label>
                                    <v-text-field name="Confirm Password" type="password" v-model="user_object.confirm_password" v-validate="'required'" :error-messages="errors.collect('changePassword.Confirm Password')" :disabled="user_object.new_password == ''" :rules="[() => (user_object.confirm_password != '' && (user_object.new_password == user_object.confirm_password)) || 'Password does not Match']"></v-text-field>
                                </v-col>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small @click="update_password_box = !update_password_box">Cancel</v-btn>
                        <v-btn small color="primary" :loading="password_loader" type="submit">Update
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <!-- filter dialog box -->
        <v-dialog v-model="filter_dialog_box" scrollable :overlay="false" max-width="450px" transition="dialog-transition" hide-overlay>
            <v-card style="height:100%">
                <v-form id="filterList" @submit.prevent="validateBeforeSubmit('filterList')" data-vv-scope="filterList" v-model="valid">
                    <v-card-text>
                        <v-card-title primary-title>
                            <span class="headline">Filters</span>
                        </v-card-title>
                        <v-container fluid grid-list-md style="padding: 0 16px 16px 16px">
                            <v-layout row wrap class="field_wrapper">
                                <v-flex xs12 md12>
                                    <label>Employee Name</label>
                                    <br />
                                    <v-text-field name="username" id="username" type="text" v-model="filters.username" placeholder="Enter Employee Name"></v-text-field>
                                </v-flex>
                                <v-flex xs12 md12>
                                    <label>Emplpyee ID</label>
                                    <br />
                                    <v-text-field name="emplplpyee_id" type="text" v-model="filters.employee_id" placeholder="Enter Employee ID"></v-text-field>
                                </v-flex>

                                <v-flex xs12 md12>
                                    <label>Status</label>
                                    <br />
                                    <v-select :items="user_status_list" item-text="value" item-value="key" v-model="filters.status" placeholder="Select Status"></v-select>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small @click="filter_dialog_box = !filter_dialog_box">Cancel</v-btn>
                        <v-btn small color="error" @click="resetFilters">Reset</v-btn>
                        <v-btn small color="primary" :disabled="!valid" type="submit">Apply
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <!-- Delete User Dialog Box -->
        <v-dialog v-model="delete_user_box" scrollable :overlay="false" max-width="450px" transition="dialog-transition" hide-overlay persistent>
            <v-card>
                <v-card-title primary-title>
                    <span>Are you sure you wish to delete this User ?</span>
                </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="delete_user_box = false">Cancel</v-btn>
                    <v-btn small color="primary" @click="deleteUser" :loading="delete_loading">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </base-material-card>

    <!-- TimeLine Dialog Box -->
    <v-dialog v-model="timeline_box" scrollable :overlay="false" max-width="500px" transition="dialog-transition" hide-overlay>
        <v-card>
            <v-card-text>
                <v-timeline dense>
                    <v-timeline-item fill-dot small color="green">
                        <v-row>
                            <v-col cols="6" v-text="user_timeline_last_record.title" class="py-0"></v-col>
                            <v-col cols="6" class="py-0">{{
                  formatDateTime(user_timeline_last_record.created_at)
                }}</v-col>
                        </v-row>
                    </v-timeline-item>
                    <v-timeline-item v-for="(item, i) in user_all_timeline_list" :key="i" fill-dot small color="grey">
                        <v-row>
                            <v-col cols="6" v-text="item.title" class="py-0"></v-col>
                            <v-col cols="6" class="py-0">{{
                  formatDateTime(item.created_at)
                }}</v-col>
                        </v-row>
                    </v-timeline-item>
                </v-timeline>
            </v-card-text>
        </v-card>
    </v-dialog>

    <!-- Image dialog box -->
    <v-dialog v-model="image_dialog_box" max-width="290" max-height="290" hide-overlay color="white">
        <v-card elevation="0">
            <v-img :src="storage_path() + image_path"></v-img>
        </v-card>
    </v-dialog>

    <!-- upload dialog box -->
    <v-dialog v-model="upload_dialog_box" scrollable :overlay="false" max-width="450px" transition="dialog-transition" hide-overlay>
        <v-card style="height:100%">
            <v-form id="UploadList" @submit.prevent="validateBeforeSubmit('UploadList')" data-vv-scope="UploadList">
                <v-card-text class="pt-9">
                    <v-card-title class="cardtitletext">
                        <span>Upload Approver Mapping</span>
                    </v-card-title>
                    <v-container fluid grid-list-md class="pt-10">
                        <v-layout row wrap class="field_wrapper">
                            <v-file-input accept="*" label="Upload Files" class="inputfield" outlined v-model="uploadFile" required name="file">
                            </v-file-input>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-layout>
                        <a v-bind:href="`${publicPath}/file/ApproverTemplate.csv`" download><u>
                                <b>Download Sample File</b></u></a>
                    </v-layout>
                    <v-btn small @click="upload_dialog_box = !upload_dialog_box">Cancel</v-btn>
                    <v-btn small color="primary" :loading="uploadList" type="submit">Upload</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <!-- upload dialog box -->
    <v-dialog v-model="upload_user_dialog_box" scrollable :overlay="false" max-width="450px" transition="dialog-transition" hide-overlay>
        <v-card style="height:100%" class="pa-9">
            <v-form id="UploadList" @submit.prevent="validateBeforeSubmit('UploadUserList')" data-vv-scope="UploadUserList">
                <v-card-text>
                    <v-card-title class="cardtitletext">
                        <span>Upload Employee</span>
                    </v-card-title>
                    <v-container fluid grid-list-md class="pt-10">
                        <v-layout row wrap class="field_wrapper">
                            <v-file-input accept="*" label="Upload Files" class="inputfield" v-model="uploadUserFile" required outlined name="file">
                            </v-file-input>
                        </v-layout>

                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-layout>
                        <a v-bind:href="`${publicPath}/file/UserTemplate.csv`" download><u>
                                <b>Download Sample File</b></u></a>
                    </v-layout>
                    <v-btn small @click="upload_user_dialog_box = !upload_user_dialog_box;uploadUserList=false">Cancel</v-btn>
                    <v-btn small color="primary" :loading="uploadUserList" type="submit">Upload</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.display" :color="snackbar.color" :top="snackbar.top" :bottom="snackbar.bottom" :left="snackbar.left" :right="snackbar.right">
        {{ snackbar.message }}
    </v-snackbar>
</v-container>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { mapActions } from "vuex";
export default {
    watch: {
        options: {
            handler() {
                this.getList();
            }
        }
    },

    methods: {
        exportData() {
            this.export_loader = true;
            this.options.filters = Object.assign({}, this.filters);
            axios
                .get(`${process.env.VUE_APP_API_URL}/api/employees/export`, {
                    params: this.options
                })
                .then(response => {
                    if (response.status == 0) {
                        this.snackbar.message = response.data.message;
                        this.snackbar.display = true;
                        this.export_loader = false;
                    }
                    else{
                       this.download(response.data);
                    }
                    this.export_loader = false;
                })
                .catch(error => {
                    this.export_loader = false;
                    this.snackbar.message = "Error Occured";
                    this.snackbar.display = true;
                });
        },
        download(data) {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "User-Report" + Date.now() + ".csv");
            document.body.appendChild(link);
            link.click();
        },

        getList() {
            this.is_loading = true;
            this.options.filters = Object.assign({}, this.filters);

            axios
                .get(`${process.env.VUE_APP_API_URL}/api/employees`, {
                    params: this.options
                })
                .then(response => {
                    if (response.data.status == 1) {
                        this.employees = response.data.employees.data;
                        this.page = response.data.employees.page;
                        this.total_items = response.data.employees.total;
                        this.is_loading = false;

                        this.displayFilterChips(this.filters);
                    }
                });
        },
        fullWidthImage(item_image) {
            this.image_dialog_box = true;
            this.image_path = item_image;
        },
        getUserTimeline(user_id) {
            axios
                .get(
                    `${process.env.VUE_APP_API_URL}/api/timelines/getUserTimelines/` +
                    user_id
                )
                .then(response => {
                    if (response.data.status == 1) {
                        this.user_all_timeline_list = response.data.all_records;
                        this.user_timeline_last_record = response.data.latest_record;
                    }
                    this.timeline_box = true;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        filterList() {
            let filter_values = Object.values(this.filters);
            if (filter_values.join("").length > 0) {
                this.getList();
                // this.displayFilterChips(this.filters);
                // this.is_filtered = true;
                this.filter_dialog_box = false;
            } else {
                this.getList();
            }
        },
        resetFilters() {
            this.resetFiltersParameters();
            this.is_filtered = false;
            this.getList();
            this.filter_dialog_box = false;
        },
        resetFiltersParameters: function () {
            for (var key in this.filters) {
                this.filters[key] = "";
            }
        },
        displayFilterChips: function (filters) {
            if (this.$route.query.filters) {
                this.filter_chips.username = this.filters.username;
                this.filter_chips.status = this.filters.status;
                this.filter_chips.employee_id = this.filters.employee_id;
                this.is_filtered = true;
            }
        },

        clearFilter: function (filter, key) {
            this.filter_chips[key] = this.filters[key] = "";
            this.filterList();
        },
        applyFiltersOnLoad: function () {
            this.filters.username = this.$route.query.username;
            this.filters.employee_id = this.$route.query.employee_id;
            this.filters.status = this.$route.query.status;
            this.displayFilterChips(this.filters);
        },
        deleteUser: function () {
            this.delete_loading = true;
            this.$axios
                .post(
                    `${process.env.VUE_APP_API_URL}/api/employees/` +
                    this.selected_user_id +
                    `/delete`
                )
                .then(response => {
                    this.delete_loading = false;
                    if (response.data.status == 1) {
                        this.delete_user_box = false;
                        this.getList();
                    } else if (response.data.status == 2) {
                        this.delete_user_box = false;
                        this.getList();
                    }
                    if (response.status == 0) {
                        this.snackBar.message = response.data.message;
                        this.snackBar.show = true;
                    }
                })
                .catch(error => {
                    this.delete_loading = false;
                    this.snackBar.message = "Error occured";
                    this.snackBar.show = true;
                });
        },
        getUserStatus() {
            axios
                .get(`${process.env.VUE_APP_API_URL}/api/configurations/getUserStatus`)
                .then(response => {
                    if (response.data.status == 1) {
                        this.user_status_list = response.data.user_status_list;
                        this.user_status_list.push({
                            type: "user_status",
                            key: "all",
                            value: "All"
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        validateBeforeSubmit: function (scope) {
            this.$validator.validateAll(scope).then(result => {
                if (result) {
                    this[scope]();
                }
            });
        },

        changePassword: function () {
            this.password_loader = true;

            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/changePassword/admin`, this.user_object)
                .then((response) => {
                    this.snackbar.display = true;
                    this.snackbar.message = response.data.message;
                    this.password_loader = false;
                    this.update_password_box = false;
                }).catch((error) => {
                    this.snackbar.display = true;
                    this.snackbar.message = "Error Occured";
                    this.update_password_box = false;

                }).finally(() => {
                    this.password_loader = false;
                    this.update_password_box = false;
                });
        },

        UploadList() {
            this.uploadList = true;
            let formData = new FormData();
            formData.append("file", this.uploadFile);
            let config = {

                headers: { 'Content-Type': 'multipart/form-data' }
            }
            axios.post(`${process.env.VUE_APP_API_URL}/api/employees/upload-approver`, formData, config)
                .then((response) => {

                    if (response.data.status == 1) {

                        if (window.navigator.msSaveOrOpenBlob) {
                            window.open(process.env.VUE_APP_API_URL + '/' + response.data.path, 'Download');
                        } else {
                            const url = process.env.VUE_APP_API_URL + '/' + response.data.path;
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', response.data.path);
                            document.body.appendChild(link);
                            link.click();
                        }
                    }
                    this.snackbar.message = response.data.message;
                    this.snackbar.display = true;
                    this.uploadList = false;
                    this.upload_dialog_box = false;
                    this.getList();
                }).catch(error => {
                    console.log(error);
                    this.uploadList = false;
                    this.snackbar.message = response.data.message;
                    this.snackbar.display = true;
                    this.uploadList = false;
                    this.upload_dialog_box = false;
                });

        },
        UploadUserList() {
            this.uploadUserList = true;
            let formData = new FormData();
            formData.append("file", this.uploadUserFile);
            let config = {

                headers: { 'Content-Type': 'multipart/form-data' }
            }
            axios.post(`${process.env.VUE_APP_API_URL}/api/employees/importUsers`, formData, config)
                .then((response) => {
                    if (response.data.status == 1) {

                        if (window.navigator.msSaveOrOpenBlob) {
                            window.open(process.env.VUE_APP_API_URL + '/' + response.data.path, 'Download');
                        } else {
                            const url = process.env.VUE_APP_API_URL + '/' + response.data.path;
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', response.data.path);
                            document.body.appendChild(link);
                            link.click();
                        }

                        this.snackbar.message = response.data.message;
                        this.snackbar.display = true;
                        this.uploadUserList = false;
                        this.upload_user_dialog_box = false;
                        this.getList();
                    }

                }).catch(error => {
                    console.log(error);
                    this.uploadList = false;
                    this.snackbar.message = response.data.message;
                    this.snackbar.display = true;
                    this.uploadUserList = false;
                    this.upload_user_dialog_box = false;
                });

        }
    },
    data() {
        return {
            update_password_box: false,
            password_loader: false,
            user_object: {
                id: '',
                new_password: '',
                confirm_password: "",
            },

            delete_loading: false,
            filters: {
                employee_id: "",
                username: "",
                status: ""
            },
            publicPath: process.env.VUE_APP_URL,
            image_dialog_box: false,
            image_path: "",
            user_all_timeline_list: [],
            user_timeline_last_record: {},
            timeline_box: false,
            is_loading: false,
            user_status_list: [],
            is_filtered: false,
            filter_chips: {
                employee_id: "",
                username: "",
                status: ""
            },
            snackbar: {
                message: "",
                display: false,
                color: "",
                top: true,
                right: true,
                bottom: false,
                left: false
            },
            total_items: 0,
            filter_dialog_box: false,
            page: 0,
            options: {},
            valid: false,
            delete_user_box: false,
            selected_user_id: "",
            headers: [
                { text: "Image", align: "left", sortable: false },
                { text: "Employee", value: "name", align: "left", sortable: false },
                { text: "Emp. ID", value: "employee_id", sortable: false },
                { text: "Status", value: "is_active", sortable: false },
                { text: "Contact", value: "contact", sortable: false },
                // { text: "Email", value: "email",sortable: false  },
                { text: "Updated At", value: "updated_at", sortable: false },
                { text: "Action", width: "9%", sortable: false }
            ],

            items: [
                { title: 'Import Approver Mappings' },
                { title: 'Import Employee' },

            ],
            auth_user: {},
            employees: [{ contacts: [] }],

            upload_dialog_box: false,
            uploadList: false,
            uploadFile: [],

            upload_user_dialog_box: false,
            uploadUserList: false,
            uploadUserFile: [],

            auth_user_role: '',
            export_loader: false,
        };
    },
    mounted() {
        this.applyFiltersOnLoad();
        this.getUserStatus();
        let auth_user = this.$store.getters.get_user;
        if (auth_user) {
            this.auth_user_role = auth_user.roles[0].name;
        }

        if (this.$route.params.success_message) {
            this.snackbar.display = true;
            this.snackbar.message = this.$route.params.success_message;
            this.$route.params.success_message = '';
        }
    }
};
</script>
