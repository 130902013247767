<template>
<v-container id="regular-tables" fluid tag="section" class="ma-0 pa-0">
    <v-row>
        <v-flex xs12 class="buttons">
            <v-btn color="success" class="ml-3" @click="$router.push({name: 'Manage Shift'})" >
                Manage Shift
            </v-btn>
            <!-- <v-btn color="success" class="ml-3" @click="shift_dialog_box = !shift_dialog_box">
                Add Shift
            </v-btn> -->
            <v-btn color="success" class="ml-3" @click="filter_dialog_box = !filter_dialog_box">
                Filters
            </v-btn>
        </v-flex>
    </v-row>
    <v-row>
        <v-flex class="xs8">
            <base-material-card icon="mdi-clipboard-text" title="Employees" class="px-5 py-3">
                <v-checkbox v-model="change_shift.all_user" class="mx-2" label="Shift change for all user"></v-checkbox>
                <v-data-table :headers="headers" :items="employees" item-key="id" :sort-by="['created_at']" :sort-desc="[true]" :options.sync="options" :server-items-length="total_items" :loading="is_loading" loading-text="Loading... Please wait">
                    <template v-slot:progress>
                        <v-progress-linear color="success" indeterminate></v-progress-linear>
                    </template>
                    <template v-slot:loading>
                        <v-layout row wrap justify-center class="text-xs-center">
                            Loading... Please wait
                        </v-layout>
                    </template>
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td v-if="item.name">
                                    <v-avatar size="36" v-if="item.image">
                                        <img :src="storage_path() + item.image.image" 
                                        @click="fullWidthImage(item.image.image)" @error="setAltImg" />
                                    </v-avatar>
                                    <router-link v-if="item.id" class="ml-1" :to="{ name: 'Employee Detail', params: { id: item.id } }">{{ item.name }}</router-link>
                                </td>
                                <td v-else>-</td>
                                <td>{{ item.employee_id }}</td>
                                <!-- 
                                <td v-if="item.primary_email">
                                    {{ item.primary_email.email }}
                                </td>
                                <td v-else>-</td> -->
                                <td v-if="item.shift">{{ formatShiftTime(item.shift.start_time) }} - {{ formatShiftTime(item.shift.end_time) }}</td>
                                <td v-else>-</td>

                                <td class="text-xs-right">
                                    <v-btn v-if="contains(selected_list, item)" v-bind:class="{added: contains(selected_list, item)}" color="green lighten-1" rounded small>Added</v-btn>
                                    <v-btn v-else :id="item.id" @click="addList(item)" outlined rounded small>Add</v-btn>

                                </td>
                            </tr>
                        </tbody>
                    </template>
                    <template slot="no-data-text">
                        <v-layout row wrap justify-center class="text-xs-center" v-if="is_loading">
                            Loading... Please wait
                        </v-layout>
                        <v-layout row wrap justify-center class="text-xs-center" v-else>
                            No data available
                        </v-layout>
                    </template>
                </v-data-table>

                <!-- filter dialog box -->
                <v-dialog v-model="filter_dialog_box" scrollable :overlay="false" max-width="450px" transition="dialog-transition" hide-overlay>
                    <v-card style="height:100%">
                        <v-form id="filterList" @submit.prevent="validateBeforeSubmit('filterList')" data-vv-scope="filterList" v-model="valid">
                            <v-card-text>
                                <v-card-title primary-title>
                                    <span class="headline">Filters</span>
                                </v-card-title>
                                <v-container fluid grid-list-md style="padding: 0 16px 16px 16px">
                                    <v-layout row wrap class="field_wrapper">
                                        <v-flex xs12 md12>
                                            <label>Employee Name</label>
                                            <br />
                                            <v-text-field name="username" id="username" type="text" v-model="filters.username" placeholder="Enter Employee Name"></v-text-field>
                                        </v-flex>
                                        <v-flex xs12 md12>
                                            <label>Emplpyee ID</label>
                                            <br />
                                            <v-text-field name="employee_id" type="text" v-model="filters.employee_id" placeholder="Enter Employee ID"></v-text-field>
                                        </v-flex>

                                        <v-flex xs12 md12>
                                            <label>Location</label>
                                            <br />
                                            <v-select :items="user_location_list" item-text="name" item-value="id" v-model="filters.location" name="Location" placeholder="Select Location"></v-select>

                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn small @click="filter_dialog_box = !filter_dialog_box">Cancel</v-btn>
                                <v-btn small color="error" @click="resetFilters">Reset</v-btn>
                                <v-btn small color="primary" :disabled="!valid" type="submit">Apply
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-dialog>
            </base-material-card>
        </v-flex>
        <v-flex class="xs4 pl-4">
            <base-material-card>
                <template v-slot:heading>
                    <v-row class="py-0">
                        <v-col cols="6" class="py-0">
                            <h3 style="text-align:center">Changing Shifts</h3>
                        </v-col>
                    </v-row>
                </template>
                <v-card-text>
                    <v-col cols="12" md="12" v-if="!change_shift.all_user">
                        <v-data-table hide-default-footer hide-default-headers :items="selected_list">
                            <template hide-headers v-slot:no-data>
                                <div style="text-align:center;font-size: 14px;">
                                    <span v-if="change_shift.all_user == 'true'">All Users Selected</span>
                                    <span v-else>Please select User </span>
                                </div>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody>
                                    <tr v-for="item in items" :key="item.id">
                                        <td>{{item.name }}</td>
                                        <td style="padding:0px;" class="text-xs-right">
                                            <v-btn @click="removeList(item)" small icon ripple class="button-cross">
                                                <v-icon color="red lighten-1">mdi-close</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </v-col>
                    <v-col cols="12" md="12" v-else>

                        <span>All Users Selected</span>

                    </v-col>
                    <v-divider></v-divider>
                    <v-row class="mt-3 ml-2">
                        <v-form id="changeshift" @submit.prevent="validateBeforeSubmit('changeShift')" data-vv-scope="changeshift" v-model="valid">
                            <v-row>
                                <v-col cols="6">
                                    <v-select v-model="change_shift.shift_id" :items="shifts" v-validate="'required'" name="Shift" :error-messages="errors.collect('changeshift.Shift')" item-text="name" item-value="id" label="Select Shift">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.start_time }} - {{ data.item.end_time }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                          <small>{{ data.item.name}} :</small>  {{ data.item.start_time }} - {{ data.item.end_time}} 
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn color="success" type="submit" :loading="shift_loading" class="mt-3">
                                        Change
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-row>
                </v-card-text>
            </base-material-card>
        </v-flex>
    </v-row>

    <v-dialog v-model="image_dialog_box" max-width="290" max-height="290" hide-overlay color="white">
        <v-card elevation="0">
            <v-img :src="storage_path() + image_path"></v-img>
        </v-card>
    </v-dialog>

    <v-dialog v-model="shift_dialog_box" max-width="44%" max-height="290" hide-overlay color="white">
        <v-card elevation="0">
            <v-form id="createshift" @submit.prevent="validateBeforeSubmit('createshift')" data-vv-scope="createshift">
                <v-card-text>
                    <v-card-title primary-title class="mb-8">
                        <span class="headline">Create Shift</span>
                    </v-card-title>
                    <v-container fluid grid-list-md style="padding: 0 16px 16px 16px">
                        <v-layout row wrap class="field_wrapper">
                            <v-col cols="12" class="pt-0 pb-0">
                                <label>Shift Name <span style="color:red">*</span></label>
                                <v-text-field name="Shift Name" v-model="shift.name" autocomplete="off"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <label>Check-in Time <span style="color:red">*</span></label>
                                <v-menu ref="menu1" v-model="time_menu" 
                                        :close-on-content-click="false" 
                                        :nudge-right="40" 
                                        :return-value.sync="shift.in_time" 
                                        transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="shift.in_time" readonly v-on="on" name="Start Time"></v-text-field>
                                    </template>
                                    <v-time-picker v-if="time_menu" v-model="shift.in_time" format="24hr" @click:minute="$refs.menu1.save(shift.in_time)"></v-time-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="6">
                                <label>Check-out Time <span style="color:red">*</span></label>
                                <v-menu ref="menu2" v-model="time_menu2" :close-on-content-click="false" :nudge-right="40" 
                                       :return-value.sync="shift.out_time" transition="scale-transition" 
                                        offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="shift.out_time" readonly v-on="on"
                                         name="Start Time"></v-text-field>
                                    </template>
                                    <v-time-picker v-if="time_menu2" format="24hr" :min="shift.in_time" v-model="shift.out_time" @click:minute="$refs.menu2.save(shift.out_time)"></v-time-picker>
                                </v-menu>
                            </v-col>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="shift_dialog_box = !shift_dialog_box">Cancel</v-btn>
                    <v-btn small color="primary" :loading="create_loader" type="submit">Create
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.display" :color="snackbar.color" :top="snackbar.top" :bottom="snackbar.bottom" :left="snackbar.left" :right="snackbar.right">
        {{ snackbar.message }}
    </v-snackbar>
</v-container>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import moment from 'moment';
export default {
    watch: {
        options: {
            handler() {
                this.getList();
            }
        }
    },

    methods: {
        getList() {
            this.is_loading = true;
            this.options.filters = Object.assign({}, this.filters);

            axios
                .get(`${process.env.VUE_APP_API_URL}/api/employees`, {
                    params: this.options
                })
                .then(response => {
                    if (response.data.status == 1) {
                        this.employees = response.data.employees.data;
                        this.all_users_ids = [];
                        this.all_users_ids.length = 0;
                        this.employees.forEach(element => {
                            this.all_users_ids.push(element.id);
                        });

                        this.change_shift.all_user = true;
                        this.selected_list = [];
                        this.page = response.data.employees.page;
                        this.total_items = response.data.employees.total;
                        this.is_loading = false;
                    }
                });
        },
        fullWidthImage(item_image) {
            this.image_dialog_box = true;
            this.image_path = item_image;
        },
        filterList() {
            let filter_values = Object.values(this.filters);
            if (filter_values.join("").length > 0) {
                this.getList();
                this.filter_dialog_box = false;
            } else {
                this.getList();
            }

        },
        resetFilters() {
            this.resetFiltersParameters();
            this.is_filtered = false;
            this.getList();
            this.filter_dialog_box = false;
        },
        resetFiltersParameters: function () {
            for (var key in this.filters) {
                this.filters[key] = "";
            }
        },
        displayFilterChips: function (filters) {
            if (this.$route.query.filters) {
                this.filter_chips.username = this.filters.username;
                this.filter_chips.location = this.filters.location;
                this.filter_chips.employee_id = this.filters.employee_id;
                this.is_filtered = true;
            }
        },

        clearFilter: function (filter, key) {
            this.filter_chips[key] = this.filters[key] = "";
            this.filterList();
        },
        applyFiltersOnLoad: function () {
            this.filters.username = this.$route.query.username;
            this.filters.employee_id = this.$route.query.employee_id;
            this.displayFilterChips(this.filters);
        },
        getLocationList() {
            this.$axios
                .get(`${process.env.VUE_APP_API_URL}/api/locations/locationlist`)
                .then(response => {
                    if (response.data.status == 1) {
                        this.user_location_list = response.data.location_list;
                    }
                })
                .catch(error => {});
        },
        validateBeforeSubmit: function (scope) {
            this.$validator.validateAll(scope).then(result => {
                if (result) {
                    this[scope]();
                }
            });
        },
        removeList: function (data) {
            const index = this.selected_list.indexOf(data);
            if (index >= 0) {
                this.selected_list.splice(index, 1)
                this.change_shift.selected_user_id.splice(index, 1);
            }

            if (this.selected_list.length == 0) {
                this.change_shift.all_user = true;
            }

        },
        addList: function (data) {
            this.change_shift.all_user = false;
            const found = this.selected_list.some(el => el.id === data.id);
            if (!found) {
                this.selected_list.push(data);
                this.change_shift.selected_user_id.push(data.id);
            }
        },
        contains: function (arr, item) {
            return arr.indexOf(item) != -1;
        },

        changeShift() {
            this.shift_loading = true;
            this.change_shift.employee = this.all_users_ids;
            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/shift/update`,
                    this.change_shift)
                .then((response) => {
                    this.shift_loading = false;
                    if (response.data.status === 1) {
                        this.snackbar.message = response.data.message;
                        this.snackbar.display = true;
                        this.getList();
                    } else {
                        this.snackbar.message = response.data.message;
                        this.snackbar.display = true;
                    }
                }).catch((error) => {
                    this.shift_loading = false;
                })
        },
        getShift() {
            this.shift_loading = true;
            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/shift/details`)
                .then((response) => {
                    this.shift_loading = false;
                    if (response.data.status === 1) {
                        this.shifts = response.data.shifts;
                    }
                }).catch((error) => {
                    this.shift_loading = false;
                })
        },
        validateBeforeSubmit: function (scope) {

            this.$validator.validateAll(scope).then(result => {
                if (result) {

                    this[scope]();
                }
            });
        },
        
        createshift() {
            this.create_loader = true;
            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/shift`, this.shift)
                .then((response) => {
                    this.create_loader = false;
                    if (response.data.status == 1) {
                        this.snackbar.message = response.data.message;
                        this.snackbar.display = true;
                        this.shift_dialog_box=false;
                        this.shift={};
                        this.getShift();
                    } else {
                        this.shift_dialog_box=false;
                        this.snackbar.message = response.data.message;
                        this.snackbar.display = true;
                    }
                }).catch((error) => {
                        this.shift_dialog_box=false;
                    this.create_loader = false;
                    this.snackbar.message = "Error Occured";
                        this.snackbar.display = true;
                })
        },
    },
    data() {
        return {
            change_shift: {
                all_user: true,
                selected_user_id: [],
                shift_id: '',
            },
            current_time: moment().format('hh:mm'),
            time_menu: false,
            time_menu2: false,
            date_menu: false,

            selected_list: [],
            selectall: false,
            shifts: [],
            filters: {
                employee_id: "",
                username: "",
                location: ""
            },
            image_dialog_box: false,
            shift_loading: false,
            image_path: "",
            user_all_timeline_list: [],
            user_timeline_last_record: {},
            timeline_box: false,
            is_loading: false,
            user_location_list: [],
            is_filtered: false,
            filter_chips: {
                employee_id: "",
                username: "",
                location: ""
            },
            snackbar: {
                message: "",
                display: false,
                color: "",
                top: true,
                right: true,
                bottom: false,
                left: false
            },
            total_items: 10,
            filter_dialog_box: false,
            page: 0,
            options: {},
            valid: false,
            delete_user_box: false,
            selected_user_id: "",
            all_users_ids: [],
            headers: [
                { text: "Emp. Name", value: "name", align: "left", sortable: false },
                { text: "Emp. ID", value: "employee_id", sortable: false },
                // { text: "Email", value: "email" },
                { text: "Shift", value: "shift" },
                { text: "Action", value: "" }
            ],
            auth_user: {},
            employees: [{ contacts: [] }],

            shift_dialog_box: false,
            shift: {
                name: '',
                in_time: '',
                out_time: '',
            },
            create_loader: false,
        };
    },
    mounted() {
        this.auth_user = this.$store.getters.get_user;
        this.applyFiltersOnLoad();
        this.getShift();
        this.getLocationList();
        
    }
};
</script>
