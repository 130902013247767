<template>
<div>
    <v-snackbar v-model="snackbar.display" :color="snackbar.color" :top="snackbar.top" :bottom="snackbar.bottom" :left="snackbar.left" :right="snackbar.right">
        {{ snackbar.message }}
    </v-snackbar>
    <v-container>
    <v-row class="ma-6">
        <v-flex xs2 class="buttons">
            <v-btn color="success" class="ml-3" @click="upload_dialog_box = !upload_dialog_box">
                Import Mapping
            </v-btn>
        </v-flex>
        <v-flex xs2 class="buttons">
            <v-btn color="success" class="ml-3" @click="upload_user_dialog_box = !upload_user_dialog_box">
                 Import Users
            </v-btn>
        </v-flex>
    </v-row>
    </v-container>
    <!-- upload dialog box -->
    <v-dialog v-model="upload_dialog_box" scrollable :overlay="false" max-width="450px" transition="dialog-transition" hide-overlay>
        <v-card style="height:100%">
            <v-form id="UploadList" @submit.prevent="validateBeforeSubmit('UploadList')" data-vv-scope="UploadList">
                <v-card-text>
                    <v-card-title class="cardtitletext">
                        <span>Upload Approver File</span>
                    </v-card-title>
                    <v-container fluid grid-list-md style="padding: 0 16px 16px 16px">
                        <v-layout row wrap class="field_wrapper">
                             <v-file-input accept="*" 
                                            label="Upload Files" 
                                            class="inputfield"
                                            v-model="uploadFile" 
                                            required
                                            name="file">
                              </v-file-input>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="upload_dialog_box = !upload_dialog_box">Cancel</v-btn>
                    <v-btn small color="primary" :loading="uploadList" type="submit">Apply</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <!-- upload dialog box -->
    <v-dialog v-model="upload_user_dialog_box" scrollable :overlay="false" max-width="450px" 
    transition="dialog-transition" hide-overlay>
        <v-card style="height:100%">
            <v-form id="UploadList" @submit.prevent="validateBeforeSubmit('UploadUserList')"
             data-vv-scope="UploadUserList">
                <v-card-text>
                    <v-card-title class="cardtitletext">
                        <span>Upload Employee File</span>
                    </v-card-title>
                    <v-container fluid grid-list-md style="padding: 0 16px 16px 16px">
                        <v-layout row wrap class="field_wrapper">
                             <v-file-input accept="*" 
                                            label="Upload Files" 
                                            class="inputfield"
                                            v-model="uploadUserFile" 
                                            required
                                            name="file">
                              </v-file-input>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="upload_user_dialog_box = !upload_user_dialog_box">Cancel</v-btn>
                    <v-btn small color="primary" :loading="uploadUserList" type="submit">Apply</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            snackbar: {
                message: "",
                display: false,
                color: "",
                top: true,
                right: true,
                bottom: false,
                left: false
            },

            upload_dialog_box: false,
            uploadList:false,
            uploadFile:[],

            upload_user_dialog_box: false,
            uploadUserList:false,
            uploadUserFile:[],

            //  headers: [
            //     { text: "Emp. ID", value: "employee_id", sortable: false },
            //     { text: "Approver 1", value: "approver1" },
            //     { text: "Approver 2", value: "approver2" },
            // ],
           
            
        }
    },

    methods:{
        validateBeforeSubmit: function (scope) {
            this.$validator.validateAll(scope).then(result => {
                if (result) {
                    this[scope]();
                }
            });
        },

        UploadList(){
            this.uploadList = true;
            let formData = new FormData();
            formData.append("file", this.uploadFile);
            let config = {

                headers: { 'Content-Type': 'multipart/form-data' }
            }
            axios.post(`${process.env.VUE_APP_API_URL}/api/employees/upload-approver`, formData, config)
                .then((response) => {
                    this.snackbar = true;
                    this.text = response.data.message;
                    this.uploadList = false;
                   
                }).catch(error => {
                    console.log(error);
                    this.uploadList = false;
                    this.snackbar = true;
                    this.text = "Error occured while upuploadList";
                    this.uploadList = false;
                });
            
        },
        UploadUserList(){
            this.uploadUserList = true;
            let formData = new FormData();
            formData.append("file", this.uploadUserFile);
            let config = {

                headers: { 'Content-Type': 'multipart/form-data' }
            }
            axios.post(`${process.env.VUE_APP_API_URL}/api/employees/importUsers`, formData, config)
                .then((response) => {
                   
                    this.snackbar = true;
                    this.text = response.data.message;
                    this.uploadUserList = false;
                   
                }).catch(error => {
                    console.log(error);
                    this.uploadList = false;
                    this.snackbar = true;
                    this.text = "Error occured while upuploadList";
                    this.uploadUserList = false;
                });
            
        }
    },
    mounted(){
      
    }
}
</script>
