<template>
<v-container fluid tag="section" class="ma-0 pa-0">
    <v-snackbar v-model="snackbar.display" :color="snackbar.color" :top="snackbar.top" :bottom="snackbar.bottom" :left="snackbar.left" :right="snackbar.right">
        {{ snackbar.message }}
    </v-snackbar>
    <v-row>
        <v-col cols="12" md="12">
            <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                        Add Organization
                    </div>
                </template>

                <v-form id="addOrganization" @submit.prevent="validateBeforeSubmit('addOrganization')" data-vv-scope="addOrganization" ref="addOrganization" enctype="multipart/form-data" v-model="valid" class="pt-8">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="4">
                                <label>Organization Logo </label>
                                <v-file-input
                                accept="image/*" 
                                v-model="organization.image"
                                label="Select organization logo"></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4">
                                <label>Organization ID </label>
                                <v-text-field v-model="organization.id" class="purple-input" name="ID" :error-messages="errors.collect('addOrganization.ID')" />
                                <span class="red--text" label="Organization ID" v-text="formErrors.name" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <label>Organization Name <span style="color:red">*</span></label>
                                <v-text-field v-model="organization.name" class="purple-input" name="Name" v-validate="'required'" :error-messages="errors.collect('addOrganization.Name')" />
                                <span class="red--text" label="Organization Name" v-text="formErrors.name" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <label>Organization Code<span style="color:red">*</span></label>
                                <v-text-field v-model="organization.code" class="purple-input" name="Code" label="Organization Code" v-validate="'required'" :error-messages="errors.collect('addOrganization.Code')" />
                                <span class="red--text" v-text="formErrors.name" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4">
                                <label>Frontend URL<span style="color:red">*</span></label>
                                <v-text-field v-model="organization.frontend_url" class="purple-input" name="Organization Frontend Url" label="Organization Frontend Url" v-validate="'required'" :error-messages="errors.collect('addOrganization.Organization Frontend Url')" />
                                <span class="red--text" v-text="formErrors.name" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <label>Backend URL<span style="color:red">*</span></label>
                                <v-text-field v-model="organization.backend_url" class="purple-input" name="Organization Backend Url" label="Organization Backend Url" v-validate="'required'" :error-messages="errors.collect('addOrganization.Organization Backend Url')" />
                                <span class="red--text" v-text="formErrors.name" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <label>license User Count<span style="color:red">*</span></label>
                                <v-text-field v-model="organization.license_user_count" class="purple-input" name="license user count" label="license User Count" v-validate="'required'" :error-messages="errors.collect('addOrganization.license user count')" />
                                <span class="red--text" v-text="formErrors.name" />
                            </v-col>

                        </v-row>
                        <v-row class="pb-5">
                            <v-col cols="12" md="2">
                            <v-checkbox
                             v-model="organization.createconfig" 
                             label="Create config"
                             >
                            </v-checkbox>
                            </v-col>
                            <v-col cols="12" md="4">
                            <v-btn color="success" class="mr-0" :loading="loading" type="submit">
                                Submit
                            </v-btn>
                            </v-col>
                             
                        </v-row>
                    </v-container>
                </v-form>
            </base-material-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    name: "AddEmployee",
    data() {
        return {
            organization: {
                id: "",
                name: "",
                code: "",
                image: [],
                backend_url: '',
                frontend_url: '',
                license_user_count: '',
                organization_domain: '',
                createconfig:true,
            },
            valid: false,
            loading: false,
            formErrors: {},

            snackbar: {
                message: "",
                display: false,
                color: "",
                top: true,
                right: true,
                bottom: false,
                left: false
            },
        };
    },
    methods: {
        addOrganization() {
            this.loading = true;

            let formData = new FormData();
            formData.append("id", this.organization.id);
            formData.append("name", this.organization.name);
            formData.append("code", this.organization.code);
            formData.append("organization_domain", this.organization.organization_domain);
            formData.append("frontend_url", this.organization.frontend_url);
            formData.append("backend_url", this.organization.backend_url);
            formData.append("license_user_count", this.organization.license_user_count);
            formData.append("image", this.organization.image);
            formData.append("config", this.organization.createconfig);

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/organization/create`, formData, config)
                .then(response => {
                    this.loading = false;
                    if (response.data.status === 1) {
                        this.snackbar.message = response.data.message;
                        this.snackbar.display = true;
                        this.$router.push({ name: "Organization" });
                    } else if (response.data.status === 0) {
                        this.snackbar.message = response.data.message;
                        this.snackbar.display = true;
                    }
                })
                .catch(error => {
                    this.snackbar.message = "Error Occured";
                    this.snackbar.display = true;
                    this.loading = false;
                });
        },
        validateBeforeSubmit: function (scope) {
            this.$validator.validateAll(scope).then(result => {
                if (result) {
                    this[scope]();
                }
            });
        }
    },
    mounted() {

    }
};
</script>
