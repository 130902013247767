<template>
<v-container id="user-profile" fluid tag="section" class="pa-1 full-width-div">
    <v-row justify="center">
        <v-col cols="12" md="8">
            <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                        Add Location
                    </div>
                </template>
                <v-form id="addLocation" @submit.prevent="validateBeforeSubmit('addLocation')" data-vv-scope="addLocation" v-model="valid">
                    <v-container class="py-0">
                        <v-row class="mt-3">
                            <v-col>
                                <label>Name <span style="color:red">*</span></label>
                                <v-text-field v-model="location.name" class="purple-input" name="Name" v-validate="'required'" :error-messages="errors.collect('addLocation.Name')" />
                                <label>Radius <span style="color:red">*</span></label>
                                <v-text-field v-model="location.radius" id="radius" class="purple-input" name="Radius" v-validate="'required'" :error-messages="errors.collect('addLocation.Radius')" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <gmap-autocomplete id="styled-input" @place_changed="setPlace" />

                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <gmap-map :center="center" :zoom="15" style="width:100%;  height: 400px;">

                                    <gmap-circle :center="center" :radius="100" :visible="true" :clickable="true" :options="{fillColor:'red',fillOpacity:0.22}" />
                                    <gmap-marker ref="myMarker" :position="marker.position" :draggable="true" @click="center=marker.position" @drag="updateCoordinates" />
                                </gmap-map>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <label>Address <span style="color:red">*</span></label>
                                <v-text-field v-model="location.address" readonly class="purple-input" name="Address" v-validate="'required'" :error-messages="errors.collect('addLocation.Address')" />
                                <span class="red--text" v-text="formErrors.address" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <input v-model="location.lat" type="hidden">
                                <span class="red--text" v-text="formErrors.lat" />
                            </v-col>
                            <v-col cols="12" md="4">
                                <input v-model="location.long" type="hidden" class="purple-input">
                                <span class="red--text" v-text="formErrors.long" />
                            </v-col>
                            <v-col cols="12" md="12" v-if="role=='superadmin'">
                                    <label> Organization</label>
                                    <v-select :items="organization_list" v-model="location.organization_id" item-text="name" item-value="id" class="purple-input" name="Organization"></v-select>
                                    <span class="red--text" v-text="formErrors.organization_id" />
                                </v-col>
                            <v-col cols="12" class="text-right">
                                
                                <v-btn to="/locations" color="#FB8C00">
                                    Back
                                </v-btn>
                                <v-btn color="primary" :disabled="!valid" type="submit">
                                    Add
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </base-material-card>
        </v-col>
    </v-row>
</v-container>
</template>

<style>
#styled-input {
    height: 40px;
    width: 850px;
    font-size: 15pt;
}
</style>

<script>
/*global google*/

import { mapActions } from 'vuex'
import { mapState } from 'vuex';

export default {
    name: 'GoogleMap',

    data() {
        return {
            location: {
                name: '',
                lat: '',
                long: '',
                address: '',
                radius: '',
                organization_id: '',
            },
            organization_list: [],
            center: { lat: 45.508, lng: -73.587 },
            marker: {},
            places: [],
            currentPlace: null,
            formErrors: {},
            markedRadius: null,
            valid: false,
            role:'',
        }
    },
    mounted() {

        this.getConfigurations();

        this.getOrganizationList();
         let auth_user = this.$store.getters.get_user;
        this.role = auth_user.roles[0].name;
    },
    methods: {

        // receives a place object via the autocomplete component
        setPlace(place) {
            const marker = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            }
            this.marker.position = marker
            this.radius = location.radius;
            this.center = marker
            this.mapZoom = 15;
            this.$emit('input', this.getAddressComponents(place))
        },
        getOrganizationList() {
            this.$axios
                .get(`${process.env.VUE_APP_API_URL}/api/organization`)
                .then(response => {
                    if (response.data.status == 1) {
                        this.organization_list = response.data.organization.data;
                    }
                })
                .catch(error => {});
        },
        getAddressComponents(place) {
            let postal_code = place.address_components.find(addressComponent => addressComponent.types.includes('postal_code'))
            let city = place.address_components.find(addressComponent => addressComponent.types.includes('locality'))
            let state = place.address_components.find(addressComponent => addressComponent.types.includes('administrative_area_level_1'))
            let country = place.address_components.find(addressComponent => addressComponent.types.includes('country'))
            let address = place.formatted_address

            // postal_code: postal_code ? postal_code.long_name : null,
            // city: city ? city.long_name : null,
            // state: state ? state.long_name : null,
            // country: country ? country.long_name : null,
            this.location.address = address ? address : null;
            this.location.lat = place.geometry.location.lat();
            this.location.long = place.geometry.location.lng();
            // }
            // this.location.address = returnObject.address
        },
        updateCoordinates(location) {
            const marker = {
                lat: location.latLng.lat(),
                lng: location.latLng.lng(),
            }
            this.marker.position = marker

            const geocoder = new google.maps.Geocoder()
            geocoder.geocode({ 'latLng': location.latLng }, (result, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    this.$emit('input', this.getAddressComponents(result[0]))
                }
            })

        },
        geolocate: function () {

            navigator.geolocation.getCurrentPosition(position => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                }
            })
        },
        validateBeforeSubmit: function (scope) {

            this.$validator.validateAll(scope).then(result => {
                if (result) {

                    this[scope]();
                }
            });
        },
        addLocation() {

            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/locations`, this.location).then((response) => {

                if (response.data.status === 1) {
                    // this.location = response.data.location
                    this.$router.push({ name: 'Locations' })
                }
            }).catch((error) => {

                this.formErrors = error.response.data.error
            })
        },
        getConfigurations: function () {
            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/configurations`).then((response) => {

                if (response.data.status === 1) {

                    this.configuration = response.data.configuration;
                    this.location.radius = this.configuration.value;
                    this.geolocate();
                }
            }).catch((error) => {

            })
        },
    },

};
</script>
