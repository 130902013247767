import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'

Vue.use(Vuetify)

const theme = {
  primary: '#039252',
  secondary: '#9C27b0',
  accent: '#9C27b0',
  info: '#039252',
  success:'#039252' ,
}

if(localStorage.getItem("primary")){
  theme.primary = localStorage.getItem("primary");
  theme.success = localStorage.getItem("primary");
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
