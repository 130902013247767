<template>
<v-container id="user-profile" fluid tag="section">
    <v-row></v-row>
    <v-row justify="center">
        <v-col cols="6">
            <base-material-card color="#039252">
                <template v-slot:heading>
                    <!-- <img src="/images/cdp_logo_big.png" width="250" > -->
                    <v-row justify="center">
                        <v-col cols="3"> </v-col>
                        <v-col cols="3">
                            <v-img :src="require('../assets/logo.png')" width="95" height="53"  style="margin-left: 9%;"/>
                        </v-col>
                        <v-col cols="3"> </v-col>

                    </v-row>
                </template>
                <v-card-text class="mt-5">
                    <v-row justify="center" class=" mb-6">
                        <h2>LOGIN TO EAS</h2>
                    </v-row>
                    <v-form @submit.prevent="validateBeforeSubmit" width="100%" class="login_form">
                        <v-col cols="12" md="12">
                            <v-text-field v-model="employee_id" label="Employee Id" name="Employee Id" v-validate="'required'" :error-messages="errors.collect('login.Employee Id')" />
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-text-field v-model="password" label="Password" name="Password" type="password" v-validate="'required'" :error-messages="errors.collect('login.Password')" />
                        </v-col>
                        <v-col cols="12" md="12" class="text-center">
                            <v-btn color="#039252" @click="login" :loading="loading" :disabled="loading" class="text-center">
                                Login
                            </v-btn>
                        </v-col>
                    </v-form>
                </v-card-text>

                <v-alert dense outlined type="error" v-if="error">
                    {{ error }}
                </v-alert>
            </base-material-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
// import axios from 'axios'
export default {
    name: "Login",
    data() {
        return {
            employee_id: "",
            password: "",
            userData: "",
            is_loading: true,
            error: this.$route.query.error,
            loading: false
        };
    },
    methods: {
        login() {
            this.loading = true;
            this.$axios
                .post(`${process.env.VUE_APP_API_URL}/api/login`, {
                    employee_id: this.employee_id,
                    password: this.password
                })
                .then(response => {
                    if (
                        response.data.status === 1 &&
                        response.data.employee.registration.approval_status != "pending"
                    ) {
                        this.$store.dispatch("storeUser", response.data.employee);
                        this.$store.dispatch("storeAccessToken", response.data.access_token);
                        this.$store.dispatch("storeOrganization", response.data.employee.organization) 
                        .then(() => {

                            if(response.data.employee.organization && response.data.employee.organization.primary_color!=null){                                localStorage.setItem("primary",response.data.employee.organization.primary_color);
                                localStorage.setItem("primary",response.data.employee.organization.primary_color);
                                this.$vuetify.theme.themes.light.primary = response.data.employee.organization.primary_color;
                                this.$vuetify.theme.themes.light.success = response.data.employee.organization.primary_color;
                            } else{
                                localStorage.setItem("primary",'#273580');
                                this.$vuetify.theme.themes.light.primary = '#273580';
                                this.$vuetify.theme.themes.light.success = '#273580';
                            }
                                
                        });

                        this.$store.dispatch("storePermissions", response.data.permissions)
                            .then(() => {
                                localStorage.setItem(
                                    "eas_access_token",
                                    response.data.access_token
                                );
                                localStorage.setItem(
                                    "eas_expiration",
                                    response.data.expiration
                                );
                                localStorage.setItem(
                                    "eas_user_permissions",
                                    JSON.stringify(response.data.permissions)
                                );
                                localStorage.setItem(
                                    "eas_auth_user",
                                    JSON.stringify(response.data.employee)
                                );

                                this.$axios.defaults.headers.common["Authorization"] =
                                    "Bearer " + this.$store.getters.get_access_token;

                                if (response.data.employee.roles[0].name == "employee") {
                                    this.$router.push({ name: "Activities" });
                                } else {
                                    this.$router.push({ name: "Dashboard" });
                                }
                            });
                    } else {
                        this.error = response.data.message;
                        this.loading = false;
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.loading = false;
                })
                .finally(() => {
                    this.is_loading = false;
                });
        },
        subscribeTokenToTopic(token, topic) {
            fetch(
                    "https://iid.googleapis.com/iid/v1/" + token + "/rel/topics/" + topic, {
                        method: "POST",
                        headers: new Headers({
                            "Content-Type": "application/json",
                            Authorization: "key=AIzaSyBfbjDMo--EEqY7EtWuu5NuODTeR2V3T58"
                        })
                    }
                )
                .then(response => {
                    if (response.status < 200 || response.status >= 400) {
                        throw "Error subscribing to topic: " +
                            response.status +
                            " - " +
                            JSON.stringify(response.text());
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        validateBeforeSubmit() {
            this.$validator.validateAll().then(result => {
                if (result) {
                    this.login();
                }
                return false;
            });
        }
    }
};
</script>

<style scoped>
h2 {
    color: #9e9e9e;
    font-weight: normal;
    font-size: 25px;
}
</style>
