<template>
<v-container id="user-profile" fluid tag="section" class="pa-1 full-width-div">
    <v-row justify="center">
        <v-col cols="12" md="8">
            <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                        Add Holiday
                    </div>
                </template>

                <v-form id="addHoliday" @submit.prevent="validateBeforeSubmit('addHoliday')" data-vv-scope="addHoliday" v-model="valid">
                    <v-container class="py-0 mt-6">
                        <v-row>
                            <v-col cols="12" md="12">
                                <label>Name <span style="color:red">*</span></label>
                                <v-text-field v-model="holiday.name" class="purple-input" name="Name" v-validate="'required'" :error-messages="errors.collect('addHoliday.Name')" />
                                <span class="red--text" v-text="formErrors.day" />
                            </v-col>
                            <v-col cols="12">
                                <label>Location<span style="color:red">*</span></label>
                                <v-autocomplete v-model="holiday.location" :items="location_list" 
                                dense chips small-chips item-text="name" 
                                item-value="id" multiple ></v-autocomplete>
                                <span class="red--text" v-text="formErrors.day" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <label>Date <span style="color:red">*</span></label>
                                <v-menu v-model="date_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :value="formatDate" readonly v-bind="attrs" v-on="on" v-validate="'required'" :error-messages="errors.collect('addHoliday.Date')"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="holiday.date" @input="date_menu = false">

                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="6">
                                <label>Type <span style="color:red">*</span></label>
                                <v-autocomplete v-model="holiday.type" :items="holiday_type_list" item-text="value" item-value="key" class="purple-input" name="Type" v-validate="'required'" :error-messages="errors.collect('addHoliday.Type')">
                                </v-autocomplete>
                                <span class="red--text" v-text="formErrors.type" />
                            </v-col>

                            <v-col cols="12" class="text-right">
                                <v-btn to="/holidays" color="#FB8C00">
                                    Back
                                </v-btn>
                                <v-btn color="success" type="submit" :loading="loading" :disabled="loading">
                                    Add
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </base-material-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
import moment from 'moment'
export default {
    name: 'AddLocation',
    computed: {

        formatDate() {
            return this.holiday.date ? moment(this.holiday.date).format('DD-MMM-YY') : ''
        },

    },
    data() {
        return {
            date_menu: false,
            holiday: {
                date: '',
                name: '',
                type: '',
                location:[],
            },
            loading: false,
            formErrors: {},
            valid: false,
            holiday_type_list: [
                { key: 'optional', value: 'Optional' },
                { key: 'public', value: 'Public' }
            ],

            location_list: [],
            value: null,
        }
    },
    methods: {

        validateBeforeSubmit: function (scope) {

            this.$validator.validateAll(scope).then(result => {
                if (result) {

                    this[scope]();
                }
            });
        },
        addHoliday() {

            this.loading = true;
            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/holidays`, this.holiday).then((response) => {

                if (response.data.status === 1) {
                         this.loading = false;
                    // this.location = response.data.location
                    this.$router.push({ name: 'Holidays' })
                }
            }).catch((error) => {
                       this.loading = false;
                // this.formErrors = error.response.data.error
            })
        },
        getLocationList() {

            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/locations/locationListForGroups`).then((response) => {
                if (response.data.status == 1) {
                    this.location_list = response.data.location_list;
                }
            }).catch((error) => {
                console.log(error)
            })
        },
    },
    mounted() {
        this.getLocationList();
    }
};
</script>
