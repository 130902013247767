<template>
<v-container fluid tag="section" class="ma-0 pa-0">
    <v-snackBar v-model="snackBar.display" :color="snackBar.color" :top="snackBar.top" :bottom="snackBar.bottom" :left="snackBar.left" :right="snackBar.right">
        {{ snackBar.message }}
    </v-snackBar>
    <v-row>
        <v-col cols="6" md="6">
            <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                        Organization Detail
                    </div>
                </template>
                <v-card flat>
                    <v-row class="ml-3">
                        <v-col cols="4" class="text-left">
                            <h5 class="font-weight-light grey--text">Organization Name</h5>
                        </v-col>
                        <v-col cols="8" class="text-left">
                            <h5 v-if="organization.name">{{ organization.name}}</h5>
                            <h5 v-else>-</h5>
                        </v-col>
                    </v-row>
                    <v-row class="ml-3">
                        <v-col cols="4" class="text-left">
                            <h5 class="font-weight-light grey--text">Organization Domain</h5>
                        </v-col>
                        <v-col cols="8" class="text-left">
                            <h5 v-if="organization.organization_domain">{{ organization.organization_domain}}</h5>
                            <h5 v-else>-</h5>
                        </v-col>
                    </v-row>
                    <v-row class="ml-3">
                        <v-col cols="4" class="text-left">
                            <h5 class="font-weight-light grey--text">Organization Code</h5>
                        </v-col>
                        <v-col cols="8" class="text-left">
                            <h5 v-if="organization.code">{{ organization.code}}</h5>
                            <h5 v-else>-</h5>
                        </v-col>
                    </v-row>
                    <v-row class="ml-3">
                        <v-col cols="4" class="text-left">
                            <h5 class="font-weight-light grey--text">Organization URL</h5>
                        </v-col>
                        <v-col cols="8" class="text-left">
                            <h5 v-if="organization.frontend_url">{{ organization.frontend_url}}</h5>
                            <h5 v-else>-</h5>
                        </v-col>
                    </v-row>

                </v-card>
            </base-material-card>
        </v-col>
        <v-col cols="6" md="6">
            <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                        Shifts
                    </div>
                </template>
                <v-card flat>

                    <v-data-table class="mt-5" :headers="headers" :items="shifts" item-key="id" :sort-by="['date']" :sort-desc="[false]" :hide-default-footer="true" :loading="is_loading" no-results-text="Loading... Please wait">
                        <template v-slot:progress>
                            <v-progress-linear color="success" indeterminate></v-progress-linear>
                        </template>
                        <template v-slot:body="{ items }">
                            <tbody>
                                <tr v-for="item in items" :key="item.id">
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.start_time }}</td>
                                    <td>{{ item.end_time }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </v-card>
            </base-material-card>
        </v-col>

    </v-row>
    <v-row>
        <v-col cols="12" md="12">
            <base-material-card>
                <template v-slot:heading>
                    <v-row>
                        <v-col cols="11">
                            <div class="display-2 font-weight-light">
                                Configuration Details
                            </div>
                        </v-col>
                        <v-col cols="1">
                            <div class="display-2 font-weight-light">
                                <v-btn small color="white" @click="create_box = !create_box;create_loader=false">Add</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </template>
                <v-card flat>
                    <v-data-table class="mt-5" :headers="headers2" :items="configuration" item-key="id" :sort-by="['date']" :sort-desc="[false]" :hide-default-footer="true" :loading="is_loading" no-results-text="Loading... Please wait">
                        <template v-slot:progress>
                            <v-progress-linear color="success" indeterminate></v-progress-linear>
                        </template>
                        <template v-slot:body="{ items }">
                            <tbody>
                                <tr v-for="item in items" :key="item.id">
                                    <td>{{ item.type }}</td>
                                    <td>{{ item.key }}</td>
                                    <td>{{ item.value }}</td>
                                    <td>
                                        <v-icon small class="mr-2" @click="editItem(item)">
                                            mdi-pencil
                                        </v-icon>
                                         <v-icon small @click="deleteItem(item)">
                                            mdi-delete
                                        </v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </v-card>
            </base-material-card>
        </v-col>
    </v-row>
       <!-- Edit config box -->
        <v-dialog v-model="edit_box" scrollable :overlay="false" 
                  max-width="450px" transition="dialog-transition" hide-overlay>
            <v-card style="height:100%">
                <v-form id="editConfig" @submit.prevent="validateBeforeSubmit('editConfig')" data-vv-scope="editConfig" v-model="valid">
                    <v-card-text>
                        <v-card-title primary-title class="mb-8">
                            <span class="headline">Update Configuration</span>
                        </v-card-title>
                        <v-container fluid grid-list-md style="padding: 0 16px 16px 16px">
                            <v-layout row wrap class="field_wrapper">
                                <v-col cols="12" class="pt-0 pb-0">
                                    <label>Type <span style="color:red">*</span></label>
                                    <v-text-field 
                                     name="Config Type"
                                     lable="Type"
                                     v-model="config_edit.type" 
                                     autocomplete="off"
                                     v-validate="'required'"
                                     :error-messages="errors.collect('editConfig.Config Type')">
                                     </v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0 pb-0">
                                    <label>Key <span style="color:red">*</span></label>
                                    <v-text-field 
                                     name="Config Key"
                                     lable="Key"
                                     v-model="config_edit.key" 
                                     autocomplete="off"
                                     v-validate="'required'"
                                     :error-messages="errors.collect('editConfig.Config Key')">
                                     </v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0 pb-0">
                                    <label>Value <span style="color:red">*</span></label>
                                    <v-text-field 
                                     name="Config Value"
                                     lable="Value"
                                     v-model="config_edit.value" 
                                     autocomplete="off"
                                     v-validate="'required'"
                                     :error-messages="errors.collect('editConfig.Config Value')">
                                     </v-text-field>
                                </v-col>
                                
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small @click="edit_box = !edit_box;edit_loader=false">Cancel</v-btn>
                        <v-btn small color="primary" :loading="edit_loader" type="submit">Update
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

         <v-dialog v-model="delete_config_box" scrollable :overlay="false" max-width="480px" transition="dialog-transition" hide-overlay persistent>
            <v-card>
                <v-card-title primary-title>
                    <span>Are you sure you wish to delete this Configuration ?</span>
                </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="delete_config_box = false; delete_config_loader=false">Cancel</v-btn>
                    <v-btn small color="primary" @click="deleteConfig" :loading="delete_config_loader">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Create config box -->
          <v-dialog v-model="create_box" scrollable :overlay="false" 
                  max-width="450px" transition="dialog-transition" hide-overlay>
            <v-card style="height:100%">
                <v-form id="createConfig" @submit.prevent="validateBeforeSubmit('createConfig')" data-vv-scope="createConfig" v-model="valid">
                    <v-card-text>
                        <v-card-title primary-title class="mb-8">
                            <span class="headline">Create New Configuration</span>
                        </v-card-title>
                        <v-container fluid grid-list-md style="padding: 0 16px 16px 16px">
                            <v-layout row wrap class="field_wrapper">
                                <v-col cols="12" class="pt-0 pb-0">
                                    <label>Type <span style="color:red">*</span></label>
                                    <v-text-field 
                                     name="Config Type"
                                     lable="Type"
                                     v-model="config_create.type" 
                                     autocomplete="off"
                                     v-validate="'required'"
                                     :error-messages="errors.collect('createConfig.Config Type')">
                                     </v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0 pb-0">
                                    <label>Key <span style="color:red">*</span></label>
                                    <v-text-field 
                                     name="Config Key"
                                     lable="Key"
                                     v-model="config_create.key" 
                                     autocomplete="off"
                                     v-validate="'required'"
                                     :error-messages="errors.collect('createConfig.Config Key')">
                                     </v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0 pb-0">
                                    <label>Value <span style="color:red">*</span></label>
                                    <v-text-field 
                                     name="Config Value"
                                     lable="Value"
                                     v-model="config_create.value" 
                                     autocomplete="off"
                                     v-validate="'required'"
                                     :error-messages="errors.collect('createConfig.Config Value')">
                                     </v-text-field>
                                </v-col>
                                
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small @click="create_box = !create_box;create_loader=fasle">Cancel</v-btn>
                        <v-btn small color="primary" :loading="create_loader" type="submit">Create
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

    <v-snackbar v-model="snackBar.display" :color="snackBar.color"
     :top="snackBar.top" :bottom="snackBar.bottom" :left="snackBar.left" 
     :right="snackBar.right">
        {{ snackBar.message }}
    </v-snackbar>
</v-container>
</template>

<script>
export default {
    name: "AddEmployee",
    data() {
        return {
            organization: {
                name: "",
                code: "",
                backend_url: '',
                frontend_url: '',
                license_user_count: '',
                organization_domain: '',

            },
            config_loading: false,
            config: {},
            valid: false,
            loading: false,
            formErrors: {},

            shifts: [],
            configuration: [],
            is_loading: false,
            headers: [
                { text: "Name", value: "name", align: "left", sortable: false },
                { text: "Start time", value: "start_time", align: "left", sortable: false },
                { text: "End time", value: "end_time", align: "left", sortable: false },
            ],
            headers2: [
                { text: "Type", value: "type", align: "left", sortable: false },
                { text: "Key", value: "key", align: "left", sortable: false },
                { text: "Value", value: "value", align: "left", sortable: false },
                { text: "Action", value: "value", align: "left", sortable: false },

            ],
            total_items: 0,

            snackBar: {
                message: "",
                display: false,
                color: "balck",
                top: true,
                right: true,
                bottom: false,
                left: false
            },

            config_edit:{
                key:'',
                value:'',
                type:''
            },
            edit_box:false,
            edit_loader:false,

            config_create:{
                key:'',
                value:'',
                type:'',
                organization_id:'',
            },
            create_box:false,
            create_loader:false,

            delete_config:{ id:""},
            delete_config_loader:false,
            delete_config_box:false,

        };
    },
    methods: {
        editItem(item){
            this.config_edit.key = item.key;
            this.config_edit.value = item.value;
            this.config_edit.type = item.type;
            this.config_edit.id = item.id;    
            this.edit_box = true;
        },
        deleteItem(item){
           this.delete_config_box = true;
           this.delete_config.id = item.id;
        },
        getdetail() {
            this.$axios.get(`${process.env.VUE_APP_API_URL}/api/organization/` + this.$route.params.id)
                .then(response => {
                    if (response.data.status == 1) {
                        this.organization = response.data.data;
                        this.shifts = response.data.data.shifts;
                        this.configuration = response.data.data.configuration;
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        editConfig(){
           this.edit_loader=true;
           this.$axios.post(`${process.env.VUE_APP_API_URL}/api/configurations/edit/` + this.config_edit.id, 
           this.config_edit)
                .then(response => {
                    if(response.data.status==1){
                        this.snackBar.message = response.data.message;
                        this.snackBar.display = true;
                        this.edit_box=false;
                    }
                    
                    if(response.data.status==0){
                        this.snackBar.message = response.data.message;
                        this.snackBar.display = true;
                    }
                        this.edit_loader=false;
                        this.edit_box=false;
                        this.getdetail();

                })
                .catch(error => {
                    this.snackBar.message = "Error Occured";
                        this.snackBar.display = true;
                        this.edit_box=false;
                        this.config_edit={
                                key:'',
                                value:'',
                                type:'',
                                id:'',
                        };
                         this.edit_loader=false;
                });
        },
        createConfig(){
           this.create_loader=true;
           this.config_create.organization_id = this.$route.params.id;
           this.$axios.post(`${process.env.VUE_APP_API_URL}/api/configurations/create`, this.config_create)
                .then(response => {
                    if(response.data.status==1){
                        this.snackBar.message = response.data.message;
                        this.snackBar.display = true;
                        this.create_box=false;
                    }
                    
                    if(response.data.status==0){
                        this.snackBar.message = response.data.message;
                        this.snackBar.display = true;
                    }
                        this.create_loader=false;
                        this.create_box=false;
                        this.getdetail();

                })
                .catch(error => {
                    this.snackBar.message = "Error Occured";
                        this.snackBar.display = true;
                        this.create_box=false;
                         this.create_loader=false;
                });
        },
        deleteConfig(){
            this.delete_config_loader=true;
            this.$axios.post(`${process.env.VUE_APP_API_URL}/api/configurations/delete/` + this.delete_config.id)
                .then(response => {
                        this.delete_config_box=false;
                        this.snackBar.message = response.data.message;
                        this.snackBar.display = true;
                        this.delete_config.id ="";
                        this.delete_config_loader=false;
                        this.getdetail();

                })
                .catch(error => {
                    this.snackBar.message = "Error Occured";
                    this.snackBar.display = true;
                        this.delete_config_box=false;
                    this.delete_config_loader=false;
                    this.delete_config.id ="";

                });
        },
        
        validateBeforeSubmit: function (scope) {
            this.$validator.validateAll(scope).then(result => {
                if (result) {
                    this[scope]();
                }
            });
        },
    },
    mounted() {
        this.getdetail();
    }
};
</script>

<style scoped>
h5 {
    color: black;
}
</style>
